import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { ThemeProvider } from 'styled-components';
import AvastarsTheme from '../../theme/theme.json';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useLocalStorage, useMedia } from '../../hooks';
import theme from '../../theme/index';
import { LIGHT, OVERRIDES } from '../../theme/overrides';
import { PURCHASE_STATUS } from '../../constants/marketplace';
import { MEDIA, MEDIA_QUERIES, MEDIA_SIZES } from '../../constants/media';
import * as ROUTES from '../../constants/routes';
import * as SECTIONS from '../../constants/sections';
import BladeRunner from '../BladeRunner/';
import Marketplace from '../Marketplace';
import Navigation from '../Navigation/';
import Trait from '../../domain/entity/Trait';
import Series from '../../domain/enum/Series';
import Generation from '../../domain/enum/Generation';
import ListedAvastar from '../../domain/entity/ListedAvastar';
import { KitGlobal, KitAppContainer } from '../../theme/kit';

export default function App(props) {
  // State from props
  const { firebase } = props;

  // Local state
  const [user, setUser] = useState(null);
  const [web3, setWeb3] = useState();
  const [account, setAccount] = useState();
  const [network, setNetwork] = useState('');
  const [desiredNetwork, setDesiredNetwork] = useState();
  const [desiredNetName, setDesiredNetName] = useState('');
  const [prices, setPrices] = useState();
  const [fees, setFees] = useState();
  const [traits, setTraits] = useState([]);
  const [teleporter, setTeleporter] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [consigned, setConsigned] = useState([]);
  const [queueLength, setQueueLength] = useState(0);
  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(true);
  const [section, setSection] = useState(SECTIONS.INTRO);
  const [series, setSeries] = useState(Series.ONE);
  const [generation, setGeneration] = useState(Generation.ONE);
  const [themeStyle, setThemeStyle] = useLocalStorage('theme', LIGHT);
  const [currentTheme, setCurrentTheme] = useState(AvastarsTheme);
  const [purchaseStatus, setPurchaseStatus] = useState(PURCHASE_STATUS.nascent);
  const [robotDetected, setRobotDetected] = useState(false);
  const [browserSupported, setBrowserSupported] = useState(
    !!window.PointerEvent
  );
  const mediaSize = useMedia(MEDIA_QUERIES, MEDIA_SIZES, MEDIA.MEDIUM.SIZE);
  const [smallDisplay, setSmallDisplay] = useState(false);
  const [allowGenerate, setAllowGenerate] = useState(true);
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  const bladeRunnerRef = useRef();

  // Get memoized theme override
  const getOverriddenTheme = useCallback(() => {
    let overrides = OVERRIDES[themeStyle];
    return deepMerge(AvastarsTheme, overrides);
  }, [themeStyle]);

  // Apply theme overrides when themeStyle changes
  useEffect(() => {
    let overriddenTheme = getOverriddenTheme();
    setCurrentTheme(overriddenTheme);
  }, [themeStyle, getOverriddenTheme]);

  // Get memoized queue length, combining teleporter, wish list, and consigned
  useEffect(
    () =>
      setQueueLength(
        teleporter.length +
          wishlist.length +
          getConsignedTypeCount(ListedAvastar.STATUS.CONSIGNED)
      ),

    // eslint-disable-next-line
    [teleporter, wishlist, consigned]
  );

  // Set the printable desired network name when desiredNetwork changes
  useEffect(() => {
    if (desiredNetwork)
      setDesiredNetName(
        `${desiredNetwork.network[0].toUpperCase()}${desiredNetwork.network.substring(
          1
        )}`
      );
  }, [desiredNetwork]);

  // Set smallDisplay flag when mediaSize changes
  useEffect(() => {
    if (!!mediaSize)
      setSmallDisplay(
        mediaSize === MEDIA.MOBILE.SIZE || mediaSize === MEDIA.SMALL.SIZE
      );
  }, [mediaSize, setSmallDisplay]);

  // Can we even?
  const evaluateBrowserSupport = (userAgent) => {
    const notSupported = /(Firefox|MSIE|Trident)/.test(userAgent);
    if (notSupported) {
      setBrowserSupported(false);
      setInitializing(false);
      return false;
    }

    return true;
  };

  // Load traits and prices
  if (initializing) {
    // prevent further calls if the browser is not supported
    if (!evaluateBrowserSupport(navigator.userAgent)) {
      return;
    }

    setInitializing(false);

    const handleMaintenanceMode = async (isMaintenanceModeActive) => {
      setLoading(true);
      setMaintenanceMode(isMaintenanceModeActive);

      try {
        setLoading(true);
        const [{ data: network }, { data: control }] = await Promise.all([
          firebase.getNetwork(),
          firebase.fetchControlInfo(),
        ]);

        const serverTraits = await firebase.fetchStoredTraits(
          control.generation
        );

        const generatedTraits = [];
        serverTraits.forEach((gene) => {
          gene.forEach((obj) => {
            if (obj) {
              generatedTraits.push(Trait.fromObject(obj));
            }
          });
        });

        setSeries(control.series);
        setGeneration(control.generation);
        setPrices(control.prices);
        setFees(control.fees);
        setTraits(generatedTraits || []);
        setDesiredNetwork(network);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    firebase.onAuthChange(setUser);

    firebase.monitorMaintenanceMode(handleMaintenanceMode);
  }

  // Notify Blade Runner when critical events happen
  // (e.g, clicking an Avastar, closing the modal)
  const onCriticalEvent = () => {
    if (!window.PointerEvent) {
      setRobotDetected(true);
    } else {
      bladeRunnerRef.current['handleCriticalEvent']();
    }
  };

  // Allow Blade Runner to report detection of bots
  const handleRobotDetected = () => {
    setRobotDetected(true);
  };

  //
  function getConsignedTypeCount(filter) {
    return consigned.reduce(
      (total, listed) => (listed.status === filter ? total + 1 : total),
      0
    );
  }

  // Render the app
  const renderApp = () => {
    return (
      <Grommet theme={currentTheme}>
        <KitAppContainer themeStyle={themeStyle}>
          <ThemeProvider theme={theme}>
            <Router>
              <>
                <KitGlobal {...props} />

                <BladeRunner
                  ref={bladeRunnerRef}
                  onRobotDetected={handleRobotDetected}
                />

                <Navigation
                  {...props}
                  maintenanceMode={maintenanceMode}
                  loading={loading}
                  section={section}
                  setSection={setSection}
                  queueLength={queueLength}
                  themeStyle={themeStyle}
                  setThemeStyle={setThemeStyle}
                  purchaseStatus={purchaseStatus}
                  mediaSize={mediaSize}
                  smallDisplay={smallDisplay}
                />

                <Route
                  exact
                  path={ROUTES.MARKETPLACE.route}
                  render={(props) => (
                    <Marketplace
                      {...props}
                      loading={loading}
                      firebase={firebase}
                      section={section}
                      series={series}
                      generation={generation}
                      prices={prices}
                      fees={fees}
                      traits={traits}
                      teleporter={teleporter}
                      setTeleporter={setTeleporter}
                      web3={web3}
                      setWeb3={setWeb3}
                      account={account}
                      setAccount={setAccount}
                      wishlist={wishlist}
                      setWishlist={setWishlist}
                      consigned={consigned}
                      setConsigned={setConsigned}
                      getConsignedTypeCount={getConsignedTypeCount}
                      network={network}
                      setNetwork={setNetwork}
                      desiredNetwork={desiredNetwork}
                      desiredNetName={desiredNetName}
                      purchaseStatus={purchaseStatus}
                      setPurchaseStatus={setPurchaseStatus}
                      onCriticalEvent={onCriticalEvent}
                      user={user}
                      mediaSize={mediaSize}
                      smallDisplay={smallDisplay}
                      setSection={setSection}
                      allowGenerate={allowGenerate}
                      setAllowGenerate={setAllowGenerate}
                      maintenanceMode={maintenanceMode}
                    />
                  )}
                />
              </>
            </Router>
          </ThemeProvider>
        </KitAppContainer>
      </Grommet>
    );
  };

  // Render the robot detection screen
  const renderRobotDetected = () => {
    return (
      <Grommet theme={AvastarsTheme}>
        <KitAppContainer themeStyle={themeStyle}>
          <img alt="I AM ROBOT" src="/images/i-am-robot.gif" />
        </KitAppContainer>
      </Grommet>
    );
  };

  const renderBrowserNotSupported = () => {
    return (
      <Grommet theme={AvastarsTheme}>
        <KitAppContainer themeStyle={themeStyle}>
          <h1>
            Browser not supported{' '}
            <span role="img" aria-label="Frowny">
              🙁
            </span>
          </h1>
          Try Chrome or Brave!
        </KitAppContainer>
      </Grommet>
    );
  };

  return robotDetected
    ? renderRobotDetected()
    : browserSupported
    ? renderApp()
    : renderBrowserNotSupported();
}
