import React from "react";
import { Component } from "react";
import styled from "styled-components";

import { Container, Col } from "react-bootstrap";
import { Box } from "grommet";

export class KitContainer extends Component {
  render() {
    const { ...props } = this.props;
    return <Container {...props} />;
  }
}

export const KitRow = (props) => {
  const {
    className,
    displayMode = "ui",
    align = "center",
    hasBackground = false,
    background = null,
    ...otherProps
  } = props;

  return (
    <Box
      {...otherProps}
      direction="row"
      fill="horizontal"
      justify={"stretch"}
      align={align}
      background={(hasBackground || !!background) ? background ? background : "background-back" : null}
      pad={displayMode === "ui" ? { vertical: "small" } : "null"}
      border={
        displayMode === "header" ? { side: "bottom", width: "medium" } : null
      }
      className={className}
      style={displayMode === "header" ? { fontWeight: 700, padding: "10px 0"} : null}
    />
  );
};

/**
 * MODAL TRAIT
 * This is the trait row, mostly here for re-use of grommet Box utilities
 * TODO: Refactor out of here into more generic "Row".
 */

const TraitRowStyles = `
  padding: 0px;
  width: 100%;
  height: inherit;
  margin: 0;
  padding-right:.5rem;
  padding-left:.5rem;
  background-color: inherit;
  min-height: 1.5rem;
  line-height: 0.75rem;
  font-size: .75rem;
  flex-basis: 2rem;
  flex-grow: 1;
  &:last-child{
    border-bottom: 0px solid #ccc;
  }
`;
const TraitRow = (props) => {
  const { className, children, ...otherProps } = props;
  return (
    <Box
      className={className}
      fill="horizontal"
      align="center"
      justify="start"
      direction="row"
      border={{ side: "bottom", color: "highlight" }}
      {...otherProps}
    >
      {children}
    </Box>
  );
};

export const KitTraitRow = styled(TraitRow)`
  ${TraitRowStyles}
`;

export const KitColumn = (props) => {
  const {
    className,
    basis = "100%",
    justify,
    displayMode,
    ...otherProps
  } = props;
  return (
    <Box
      {...otherProps}
      direction="row"
      basis={basis}
      justify={justify}
      className={className}
    />
  );
};

export const KitList = (props) => {
  const { className, displayMode, ...otherProps } = props;

  const gridStyles = {
    flexWrap: "wrap",
    flexFlow: "row wrap",
    placeContent: "flex-start space-between",
    alignItems: "flex-start",
  };
  return (
    <Box
      {...otherProps}
      direction={displayMode === "grid" ? "row" : "column"}
      gap={displayMode === "grid" ? "medium" : null}
      fill="horizontal"
      align="start"
      justify="start"
      className={displayMode}
      style={displayMode === "grid" ? gridStyles : null}
    />
  );
};

// POSSIBLY REDUNDANT:
export const KitCol = Col;
