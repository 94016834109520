import React from 'react';
import withQueryParams from 'react-router-query-params';
import { withRouter } from 'react-router-dom';
import Header from '../Header';
import * as ROUTES from '../../constants/routes';
import {
  MARKETPLACE_SECTIONS,
  MAINTENANCE_SECTIONS,
  QUEUE,
} from '../../constants/sections';
import { PURCHASE_STATUS } from '../../constants/marketplace';
import { QUERY_CONFIG } from '../../constants/query';
import { MEDIA } from '../../constants/media';
import { navigation as styles } from '../../theme/styles';
import Stochastic from '../../domain/Stochastic';

import {
  KitNavbar,
  KitNavPills,
  KitNavItem,
  KitNavLink,
  KitSeparator,
  KitSpinner,
} from '../../theme/kit/index';

const Navigation = (props) => {
  const {
    location,
    section,
    setSection,
    loading,
    queueLength,
    themeStyle,
    setThemeStyle,
    purchaseStatus,
    mediaSize,
    queryParams,
    setQueryParams,
    maintenanceMode,
  } = props;

  const renderSep = () => {
    let key = `sep-${String(Stochastic.aim(0, 10000))}`;
    return <KitSeparator key={key} />;
  };

  function selectSection(newSection) {
    if (queryParams.finder) setQueryParams({ finder: undefined });
    setSection(newSection);
  }

  const renderSectionItem = (item, activeSection) => {
    let name =
      item.name !== QUEUE.name || mediaSize === MEDIA.MOBILE.SIZE
        ? item.name
        : `${item.name}  (${queueLength})`;
    return (
      <KitNavItem
        key={item.section}
        isHighlighted={activeSection === item.section}
      >
        <KitNavLink
          disabled={purchaseStatus !== PURCHASE_STATUS.nascent}
          style={styles.barColor}
          eventKey={item.section}
          name={name}
          item={item}
          activeSection={activeSection}
          onSelect={() => selectSection(item)}
          setSection={selectSection}
        />
      </KitNavItem>
    );
  };

  const renderDashboardControls = (selected) => {
    return (
      <KitNavPills activeKey={selected.section}>
        {(maintenanceMode ? MAINTENANCE_SECTIONS : MARKETPLACE_SECTIONS)
          .map((item) => renderSectionItem(item, selected.section))
          .reduce((prev, curr) => [prev, renderSep(), curr])}
      </KitNavPills>
    );
  };

  const renderSpinner = () => (
    <div style={styles.spinnerContainer}>
      <KitSpinner />
    </div>
  );

  const renderNav = () => {
    if (loading) {
      return renderSpinner();
    } else if (location.pathname === ROUTES.MARKETPLACE.route) {
      return renderDashboardControls(section);
    }
  };

  return (
    <KitNavbar themeStyle={themeStyle}>
      <Header setThemeStyle={setThemeStyle} mediaSize={mediaSize}>
        {renderNav()}
      </Header>
    </KitNavbar>
  );
};

export default withQueryParams(QUERY_CONFIG)(withRouter(Navigation));
