import React, { Component } from "react";
import styled from "styled-components";
import { Nav,} from "react-bootstrap";
import Logo from "./Logo.js";
import {DARK} from "../overrides"
import {
  ResponsiveContext,
  Box,
  Anchor,
  Layer,
  Text,
} from "grommet";

/**
 * KITNAVBAR
 * Master wrap for the nav
 * Sets up the positioning and spacing.
 */
const NavBar = (props) => {
  const { themeStyle } = props;

  return (
    <Layer
      full="horizontal"
      modal={false}
      //plain={true}
      position="top"
      responsive={false}
      zIndex="1000"
      animate={false}
      height="80px"
    >
      <Box
        fill="horizontal"
        background={themeStyle}
        height="80px"
        direction="row"
        justify="between"
        align="center"
        pad={{ horizontal: "small" }}
        border={{
          side: "bottom",
          color: themeStyle === DARK ? "dark-2" : "light-2",
          size: "small",
          style: "solid",
        }}
        {...props}
      />
    </Layer>
  );
};

export class KitNavbar extends Component {
  render() {
    const { ...props } = this.props;
    return <NavBar {...props} />;
  }
}

/**
 * KitNavbarBrand
 * Handles the logo section of the navigation
 */
const NavBarBrandStyles = `
  padding-left: 6px;
  padding-right: 6px;
`;
const NavBarBrand = (props) => {
  return (
    <Box {...props} direction="row" align="center" styles={{ flexShrink: 0 }} />
  );
};
export const KitNavbarBrand = styled(NavBarBrand)`
  ${NavBarBrandStyles}
`;

/**
 * KITLOGO
 * shows the logo
 */
const kitLogoStyles = ``;
const LogoWrap = (props) => {
  const screenSize = React.useContext(ResponsiveContext);
  return (
    <Box
      {...props}
      direction="row"
      justify="start"
      align="center"
      color="primary"
      width={screenSize === "small" ? "40px" : "200px"}
    >
      <Logo displayMode={screenSize} />
    </Box>
  );
};
export const KitLogo = styled(LogoWrap)`
  ${kitLogoStyles}
`;


const StyledBootstrapNav = styled(Nav)`
  align-items: center;
`;

export class KitNavPills extends Component {
  render() {
    const { ...props } = this.props;
    return <StyledBootstrapNav variant="pills" {...props} />;
  }
}

/**
 * NAV ITEM: wraps the anchor and adds a highlight bar when active.
 * TODO: maybe animate the highlight?
 * @param {*} props
 */
const NavItem = (props) => {
  return (
    <Box {...props} pad={{horizontal: "xsmall"}}>
      {props.children}
      {props.isHighlighted && (
        <Box
          background={ {"light": "#150437", "dark": "#F7F8FC"} }
          animation="slideLeft"
          style={{
            position: "relative",
            bottom: "-4px",
            width: "1rem",
            height: "2px",
            margin: "0px"
          }}
        />
      )}
    </Box>
  );
};
export const KitNavItem = styled(NavItem)``;

/**
 * NAVANCHOR
 * a link that controls the routing navigation.
 */
const navAnchorStyles = `
&.isCurrent{
  background: transparent;
  text-decoration: none;
  cursor: default;
}
&:focus {
  outline: none;
  box-shadow: none;
}
`;
const NavAnchor = (props) => {
  const {
    disabled,
    name,
    item = {},
    setSection,
  } = props;
  return (
    <Anchor
      disabled={disabled}
      href="#"
      size="small"
      focusIndicator={false}
      label={name}
      onSelect={() => setSection(item)}
      onClick={() => setSection(item)}
      eventKey={item.section}
      style={{
        // TESTING OUT A FIX
        outline: "none !important",
        boxShadow: "none !important",
      }}
    />
  );
};

export const KitNavLink = styled(NavAnchor)`
  ${navAnchorStyles}
`;

/**
 * KitSeparator
 * The separator between things
 * NOTE: media query reduces padding so that the nav elements will fit into one row.
 * This may not play nicely if user have more than 9 items in their Queue
 */
const separatorStyles = `
  padding: 0 6px;
  @media(max-width: 768px){
    margin: 0 !important;
    padding: 0 !important;
  }
`;
const SeparatorItem = (props) => {
  return (
    <Text {...props} color="highlight" pad="xsmall" margin="xsmall">
      |
    </Text>
  );
};


export const Separator = styled(SeparatorItem)`
  ${separatorStyles}
`;

/**
 * KitNavActions
 *
 */

export const KitNavActions = (props) => {
  return (
    <Box
      {...props}
      direction="row"
      gap="xxsmall"
      basis="15%"
      justify="end"
      align="center"
      pad="small"
      style={{ flexShrink: "0" }}
    />
  );
};

export const KitSeparator = styled(Separator)`
  ${separatorStyles}
`;
