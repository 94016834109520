/**
 * Schema Entity: Trait
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */

import Gene from "../enum/Gene";
import Rarity from "../enum/Rarity";
import Gender from "../enum/Gender";

class Trait { // TODO: add generation to Trait entity

    constructor (gene, variation, name, rarity, gender, series, svg) {
        this.gene = gene;
        this.variation = variation;
        this.name = name;
        this.rarity = rarity;
        this.gender = gender;
        this.series = series || [];
        this.svg = svg || "";
    }

    /**
     * Get a new Trait instance from an array returned from TraitFactory.getTrait()
     * @param a
     * @returns {Trait}
     */
    static fromArray(a) {
        return new Trait(a[0], a[1], a[2], a[3], a[4], a[5], a[6], a[7]);
    }

    /**
     * Get a new Trait instance from a database representation
     * @param o
     * @returns {Trait}
     */
    static fromObject(o) {
        return new Trait(o.gene, o.variation, o.name, o.rarity, o.gender, o.series, o.svg);
    }

    /**
     * Get a database representation of this Trait instance
     * @returns {object}
     */
    toObject() {
        return JSON.parse(JSON.stringify(this));
    }

    /**
     * Get a string representation of this Trait instance
     * @returns {string}
     */
    toString() {
        return [
            this.gene,
            this.variation,
            this.name,
            this.rarity,
            this.gender,
            this.series.reduce((acc, num) => acc + ", " + num, ""),
            this.svg
        ].join(', ');
    }

    /**
     * Is this Trait instance's gene field valid?
     * @returns {boolean}
     */
    geneIsValid() {
        let valid = false;
        try {
            valid = Gene.TYPES.includes(this.gene);
        } catch (e) {}
        return valid;
    };

    /**
     * Is this Trait instance's variation field valid?
     * @returns {boolean}
     */
    variationIsValid() {
        let valid = false;
        try {
            valid = (
                typeof this.variation === 'number' &&
                this.variation >= 0 &&
                this.variation < 256
            );
        } catch (e) {
        }
        return valid;
    }

    /**
     * Is this Trait instance's name field valid?
     * @returns {boolean}
     */
    nameIsValid() {
        let valid = false;
        try {
            valid = (
                typeof this.name === 'string' &&
                this.name.length > 0
            );
        } catch (e) {
        }
        return valid;
    };

    /**
     * Is this Trait instance's rarity field valid?
     * @returns {boolean}
     */
    rarityIsValid() {
        let valid = false;
        try {
            valid = Rarity.LEVELS.includes(this.rarity);
        } catch (e) {}
        return valid;
    };

    /**
     * Is this Trait instance's gender field valid?
     * @returns {boolean}
     */
    genderIsValid() {
        let valid = false;
        try {
            valid = (
                Gender.TYPES.includes(this.gender) ||
                this.gender === Gender.ANY
            );
        } catch (e) {}
        return valid;
    };

    /**
     * Is this Trait instance's series field valid?
     * @returns {boolean}
     */
    seriesIsValid() {
        let valid = false;
        try {
            valid = Array.isArray(this.series) && this.series.length > 0;
        } catch (e) {}
        return valid;
    };

    /**
     * Is this Trait instance's svg field valid?
     * @returns {boolean}
     */
    svgIsValid() {
        let valid = false;
        let regex = /svg/ig;
        try {
            valid = (
                typeof this.svg === 'string' &&
                this.svg.length > 1 &&
                !this.svg.match(regex)
            ) || (
                typeof this.svg === "string" &&
                this.svg === ""

            );
        } catch (e) {
        }
        return valid;
    };

    /**
     * Is this Trait instance valid?
     * @returns {boolean}
     */
    isValid() {
        return (
            this.geneIsValid() &&
            this.variationIsValid() &&
            this.nameIsValid() &&
            this.rarityIsValid() &&
            this.genderIsValid() &&
            this.seriesIsValid() &&
            this.svgIsValid()
        );
    };

    isSameGeneAndVariation(trait) {
        return this.gene === trait.gene && this.variation === trait.variation;
    }
}

export default Trait;