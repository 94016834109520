import React, {useState, useContext} from "react";
import {avastar as styles} from "../../../theme/styles";
import {ResponsiveContext} from "grommet";

import {
  KitCard,
  KitContentBorder,
  KitCardDivider,
  KitCardHeader,
  KitCardBody,
  KitCardTitle,
  KitCardImageWrap,
  KitModal,
  KitModalBody,
  KitModalHeader,
  KitSmallButton,
  KitLargeButton,
  KitTraitRow,
  KitBox,
  KitSeriesIcon,
} from "../../../theme/kit";
import RarityIcon from "./RarityIcon";
import AvastarImage from "./AvastarImage";
import ListedAvastar from "../../../domain/entity/ListedAvastar";
import Rarity from "../../../domain/enum/Rarity";

export default function AvastarModal(props) {

  // State from props
  const {
    show,
    listed,
    account,
    truncatedAccount,
    truncateAccount,
    smallDisplay,
    onClose,
    onCriticalEvent,
    setSelectedApplicant
  } = props;

  // Local state
  const [avastar] = useState(listed.avastar.clone());

  const rank = Rarity.LEVELS[avastar.level - 1];
  const responsiveSize = useContext(ResponsiveContext);

  const verticalSizes = ["small", "xsmall"];

  const DividerIcon = (props) => {
    const { highlighted, rarity } = props;

    // custom styles for the minor positioning errors...
    const customStyleMap = {
      Common: styles.commonIcon,
      Uncommon: {},
      Rare: styles.rareIcon,
      Epic: styles.epicIcon,
      Legendary: styles.legendaryIcon
    };

    return (
      <KitBox
        style={{
          ...styles.modal.dividerCircle,
          borderColor: highlighted && styles.avastarTraitColor[rarity].color,
        }}
        border={{ color: "secondary" }} // current 'highlight' color depending on active theme
        background={highlighted ? "highlight" : "background-back"} // 'inverts color depending on whether highlighted
      >
        <RarityIcon
          rarity={rarity}
          active={highlighted}
          size={highlighted ? "medium" : "small"}
          displayMode={highlighted ? "icon" : "ui"}
          style={customStyleMap[rarity] || {}}
        />
      </KitBox>
    );
  };

  const ModalDivider = (props) => {
    const { rarity, direction = "column" } = props;
    return (
      <KitBox
        style={styles.modal.divider}
        height={direction === "column" ? "100%" : "32px"}
        width={direction === "column" ? "32px" : "100%"}
        basis="32px"
      >
        <KitCardDivider direction={direction} />
        <KitBox
          direction={direction}
          gap="xsmall"
          style={styles.modal.dividerStack}
        >
          <DividerIcon rarity={"Common"} highlighted={rarity === "Common"} />
          <DividerIcon
            rarity={"Uncommon"}
            highlighted={rarity === "Uncommon"}
          />
          <DividerIcon rarity="Rare" highlighted={rarity === "Rare"} />
          <DividerIcon rarity="Epic" highlighted={rarity === "Epic"} />
          <DividerIcon
            rarity="Legendary"
            highlighted={rarity === "Legendary"}
          />
        </KitBox>
      </KitBox>
    );
  };

  // Render the header of the trait section
  const renderTraitHeader = (avastar) => {
    return (
      <KitCardHeader size="small">
        <KitCardTitle>Traits</KitCardTitle>
        <span
          style={{ ...styles.modal.score, ...styles.avastarTraitColor[rank] }}
        >
          SCORE {avastar && Math.round(avastar.score)}
        </span>
      </KitCardHeader>
    );
  };

  // Render each trait row.
  // On it's own here for the moment.
  const renderTraitRow = (trait, key) => {
    return (
      <KitTraitRow key={key}>
        <div
          style={{ ...styles.modal.traitItem, ...styles.modal.traitRarityIcon }}
        >
          <RarityIcon rarity={trait.rarity} size="small" active={false} />
        </div>
        <span style={{ ...styles.modal.traitItem, ...styles.modal.traitGenes }}>
          <span style={{ ...styles.modal.traitGeneType }}>
            {trait ? trait.gene : null} |{" "}
          </span>
          <span
            style={{
              ...styles.avastarTraitColor[trait.rarity],
              ...styles.modal.traitGeneName,
            }}
          >
            {trait ? trait.name : null}
          </span>
        </span>
        <div style={{ ...styles.modal.traitItem, ...styles.modal.traitSeries }}>
          {trait
            ? trait.series.map((series) => (
                <KitSeriesIcon key={`series-${series}`} number={series} />
              ))
            : null}
        </div>
        <div
          style={{
            ...styles.modal.traitItem,
            ...styles.modal.traitRarity,
            ...styles.avastarTraitColor[trait.rarity],
          }}
        >
          {trait ? trait.rarity : null}
        </div>
      </KitTraitRow>
    );
  };

  // Render the header of the modal

  const renderModalHeader = () => {
    const series = `/ Series ${avastar.series}`;
    const seriesOrPromo = avastar.isPromo
      ? avastar.serial < 100
        ? `/ Founder #${avastar.serial}`
        : `/ Exclusive #${avastar.serial}`
      : series;
    return (
      <span>
        {avastar.gender} / Gen {avastar.generation} {seriesOrPromo}
      </span>
    );
  };

  const cardBasis = responsiveSize ==="small" ? "100%" : "38%";
  const cardMargin = responsiveSize ==="small" ? "8px" : "20px";
  const cardStyle = responsiveSize === "small" ? styles.modal.responsiveModalCardStyle : styles.modal.normalModalCardStyle;
  // Return the Avastar modal component
  return (
    <KitModal
      show={show}
      pad="none"
      onEsc={() => onClose()}
      onClickOutside={() => onClose()}
    >
      <KitCard
        customStyle={cardStyle}
        fill={responsiveSize === 'small' ? 'horizontal' : true}
        displayMode="ui"
      >
        <KitModalHeader
          style={
            responsiveSize === 'small'
              ? styles.modal.smallHeader
              : styles.modal.header
          }
        >
          <span>
            {/* Empty modal title.. moved down to image column as per mockups. This span enables the buttons to flow to the right with flex*/}
          </span>
          <span style={styles.modal.buttonBar}>
            <KitSmallButton
              style={styles.modal.button}
              onClick={() => {
                onCriticalEvent();
                onClose();
              }}
            >
              x
            </KitSmallButton>
          </span>
        </KitModalHeader>
        <KitContentBorder angled={true} inset={false}>
          <KitModalBody
            direction={
              verticalSizes.includes(responsiveSize) ? 'column' : 'row'
            }
          >
            <KitCard basis={cardBasis} margin={cardMargin}>
              <KitCardHeader size="small">
                <KitCardTitle>{renderModalHeader()}</KitCardTitle>
              </KitCardHeader>
              <KitCardImageWrap>
                <AvastarImage avastar={avastar} size={600} backdrop={true} />
              </KitCardImageWrap>
              <br />
              <KitCardHeader size="small">
                <KitCardTitle>Finder</KitCardTitle>
              </KitCardHeader>
              <div style={styles.modal.finder}>
                {listed.finder === ListedAvastar.GENERATED
                  ? !!account
                    ? smallDisplay
                      ? truncatedAccount
                      : account
                    : ListedAvastar.GENERATED
                  : smallDisplay
                  ? truncateAccount(listed.finder)
                  : listed.finder}
              </div>
              <br />
              <KitCardHeader size="small">
                <KitCardTitle>Found on</KitCardTitle>
              </KitCardHeader>
              <div style={styles.modal.finder}>
                {new Date(listed.timestamp).toDateString()}
              </div>
              <KitLargeButton
                style={styles.modal.addToQueue}
                onClick={() => {
                  onCriticalEvent();
                  setSelectedApplicant(listed);
                  onClose();
                }}
              >
                Add to Queue
              </KitLargeButton>
            </KitCard>
            <ModalDivider
              rarity={rank}
              direction={
                verticalSizes.includes(responsiveSize) ? 'row' : 'column'
              }
            />
            <KitCard inset basis="55%" margin={cardMargin} overflow="auto">
              {renderTraitHeader(avastar)}
              <KitCardBody
                basis="100%"
                background="background-front"
                displayMode="traits"
                style={styles.modal.traitsBody}
              >
                {avastar.traits.map((trait) => {
                  const key = `${trait.gene}-${trait.variation}`;
                  return renderTraitRow(trait, key);
                })}
              </KitCardBody>
            </KitCard>
          </KitModalBody>
        </KitContentBorder>
      </KitCard>
    </KitModal>
  );
}
