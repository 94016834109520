/**
 * Schema Enum: Gene
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
class Gene {}

Gene.ANY             = "Any";

Gene.SKIN_TONE       = "Skin Tone";
Gene.HAIR_COLOR      = "Hair Color";
Gene.EYE_COLOR       = "Eye Color";
Gene.BG_COLOR        = "Bg Color";
Gene.BACKDROP        = "Backdrop";
Gene.EARS            = "Ears";
Gene.FACE            = "Face";
Gene.NOSE            = "Nose";
Gene.MOUTH           = "Mouth";
Gene.FACIAL_FEATURE  = "Facial Feature";
Gene.EYES            = "Eyes";
Gene.HAIR_STYLE      = "Hair Style";

Gene.TYPES = [
    Gene.SKIN_TONE,
    Gene.HAIR_COLOR,
    Gene.EYE_COLOR,
    Gene.BG_COLOR,
    Gene.BACKDROP,
    Gene.EARS,
    Gene.FACE,
    Gene.NOSE,
    Gene.MOUTH,
    Gene.FACIAL_FEATURE,
    Gene.EYES,
    Gene.HAIR_STYLE
];

Gene.VARIATIONS_PER_RARITY_LEVEL = {
    [Gene.SKIN_TONE]: 3,
    [Gene.HAIR_COLOR]: 3,
    [Gene.EYE_COLOR]: 4,
    [Gene.BG_COLOR]: 4,
    [Gene.BACKDROP]: 4,
    [Gene.EARS]: 4,
    [Gene.FACE]: 2,
    [Gene.NOSE]: 4,
    [Gene.MOUTH]: 6,
    [Gene.FACIAL_FEATURE]: 3,
    [Gene.EYES]: 6,
    [Gene.HAIR_STYLE]: 6
};

export default Gene;