import React from "react";
import {LoopingRhombusesSpinner} from "react-epic-spinners";

import { ThemeContext } from "grommet";

export const KitSpinner = (props) => {
  return (
    <ThemeContext.Consumer>
      {(theme) => {
        // CONDITIONAL OVERRIDE WHERE GROMMET DOESNT PICK UP CORRECT COLOR
        const spinnerColor =
          theme.dark === true
            ? theme.global.colors["secondary"].dark
            : theme.global.colors["secondary"].light;
        return (
            <LoopingRhombusesSpinner color={spinnerColor} />
        );
      }}
    </ThemeContext.Consumer>
  );
};