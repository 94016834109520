import React from "react";
import styled from "styled-components";
import { Box, Heading, Layer, ThemeContext, ResponsiveContext } from "grommet";

/**
 * KIT Modal
 * Full screen modal layer
 * TODO: unify the zINdex issue comparing to the app header
 */
const KitModalStyles = `
  @media(max-width: 768px){
    padding: 0 !important;
  }
`;

const ModalLayer = (props) => {
  const {
    className,
    border,
    onEsc,
    onClickOutside,
    children,
    // xbackground,
    // customStyle,
    ...otherProps
  } = props;
  const responsiveSize = React.useContext(ResponsiveContext);
  
  return (
    <ThemeContext.Extend
      value={{
        layer: { zIndex: 9999, padding: "none" },
        overlay: {
          zIndex: 999999,
        },
      }}
    >
      <ThemeContext.Consumer>
        {(theme) => {
          // console.log("theme", theme);
          // console.log(theme.dark ? "dark" : "light");
          const modalBgColor =
            theme.dark === true
              ? theme.global.colors["background-back"].dark
              : theme.global.colors["background-back"].light;
              
              const layerStyle = { background: modalBgColor}
              const responsiveLayerStyle = {overflow: "scroll", background: modalBgColor, minHeight: "-webkit-fill-available"}
          return (
            <Layer
              modal
              full={true}
              onEsc={onEsc}
              onClickOutside={onClickOutside}
              zIndex="1002"
              margin={props.margin || "none"}
              pad="none"
              background="background-back"
              className={className}
              style={{background: modalBgColor}} 
              {...otherProps}
            >
              <Box
                fill={true}
                background={modalBgColor }
                style={responsiveSize === "small" ? responsiveLayerStyle : layerStyle}
                >
                {children}
              </Box>
            </Layer>
          );
        }}
      </ThemeContext.Consumer>
    </ThemeContext.Extend>
  );
};

export const KitModal = styled(ModalLayer)`
${KitModalStyles}
`;


/**
 * MODAL HEADER
 * wraps the title, and allows room for buttons
 */
const ModalHeader = (props) => {
  const { className, children, ...otherProps } = props;
  return (
    <Box
      fill="horizontal"
      direction="row"
      height="75px"
      justify="between"
      align="center"
      pad={{ horizontal: "small" }}
      {...otherProps}
      className={className}
    >
      {children}
    </Box>
  );
};
export const KitModalHeader = styled(ModalHeader)`
  flex-shrink: 0;
`;

/**
 * MODAL TITLE
 * This is the text heading inside the header.
 * TODO: Currently unused but should keep in case other modals need a header?
 * - existing avastar modal puts the title inside the image column.
 */
export const KitModalTitle = (props) => {
  const { className, children, ...otherProps } = props;
  return (
    <Heading
      className={className}
      level={3}
      {...otherProps}
    >
      {children}
    </Heading>
  );
};

/**
 * MODAL BODY
 * Renders modal body, flex direction dependant on responsive context
 * TODO: theme is currently disabled in the modal, but will return once other work is done.
 */
const modalBodyStyles = `
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;
const ModalBody = (props) => {
  const { className, direction = "row", ...otherProps } = props;
  return (
    <Box
      {...otherProps}
      className={className}
      direction={direction}
      align="stretch"
      justify="stretch"
      pad="none"
      background="background-back"
      fill={true}
      overflow={direction === "column" ? "auto" : null}
      style={{
        height: "100%",
      }}
    />
  );
};
export const KitModalBody = styled(ModalBody)`
  ${modalBodyStyles}
`;
