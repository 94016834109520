import React from "react";
import {Menu} from "grommet";

export function KitMenu(props) {
  const {label, items, size="medium", dropAlign={ top: 'bottom', right: 'right' }} = props;
  return <Menu
      label={label}
      items={items}
      size={size}
      dropAlign={dropAlign}
      dropProps={{elevation: "none"}}
  />;
}