import React, { useEffect, useCallback, useState } from 'react';
import { Animated } from 'react-animated-css';
import { marketplace as styles } from '../../../theme/styles';
import { getBlockchainConfig } from '../../../environment/config';
import { WALLET_STATUS } from '../../../constants/wallet';
import { MEDIA } from '../../../constants/media';
import {
  PURCHASE_STATUS,
  MINTING_STATUS,
  QUEUE_LISTS,
} from '../../../constants/marketplace';
import ListedAvastar from '../../../domain/entity/ListedAvastar';
import Gender from '../../../domain/enum/Gender';
import Wallet from '../Wallet';
import AvastarRow from '../Avastar/AvastarRow';
import {
  KitHeaderField,
  KitLargeButton,
  KitList,
  KitRow,
  KitColumn,
  KitHeader,
  KitHeading,
  KitLoading,
} from '../../../theme/kit';

const config = getBlockchainConfig();

export default function Teleporter(props) {
  // State from props
  const {
    firebase,
    web3,
    user,
    prices,
    account,
    profile,
    network,
    desiredNetName,
    walletStatus,
    teleporter,
    setTeleporter,
    purchaseStatus,
    setPurchaseStatus,
    getWalletBalance,
    truncatedAccount,
    smallDisplay,
    getPrice,
    calculateTotal,
    transition,
    mediaSize,
    gasPrice,
    setGasPrice,
    setGasPriceEth,
  } = props;

  // local state
  const [queueStatus, setQueueStatus] = useState(null);

  useEffect(() => {
    const filtered = teleporter.filter((listed) => {
      return (
        listed.status === ListedAvastar.STATUS.BUYING ||
        (listed.status === ListedAvastar.STATUS.CONSIGNED &&
          listed.finder !== account)
      );
    });
    setTeleporter(filtered);
    // eslint-disable-next-line
  }, [account, setTeleporter]);

  // Memoized call to queue the next avastar for teleportation
  const queueNextAvastarMemoized = useCallback(() => {
    queueNextAvastar(queueStatus);
    // eslint-disable-next-line
  }, [queueStatus]);

  // Queue the next Avastar when queueStatus changes
  useEffect(() => {
    if (!!queueStatus) queueNextAvastarMemoized();
  }, [queueStatus, queueNextAvastarMemoized]);

  // Render the teleporter list
  function renderList() {
    return (
      <KitList>
        <KitRow displayMode="header">
          <KitColumn justify="center">
            <KitHeading level="3" color="secondary">
              {QUEUE_LISTS.TELEPORTER}
            </KitHeading>
          </KitColumn>
        </KitRow>
        <KitRow displayMode="header" background="background-front">
          <KitColumn>
            <span style={smallDisplay ? styles.columnHeader : null}>
              &nbsp;Avastar
            </span>
          </KitColumn>
          {smallDisplay ? null : (
            <>
              <KitColumn justify="center">
                <span style={smallDisplay ? styles.columnHeader : null}>
                  Gen/Series
                </span>
              </KitColumn>
              <KitColumn justify="center">
                <span style={smallDisplay ? styles.columnHeader : null}>
                  Rarity
                </span>
              </KitColumn>
            </>
          )}
          <KitColumn justify="center">
            <span style={smallDisplay ? styles.columnHeader : null}>Price</span>
          </KitColumn>
          {gasPrice === 0 ? null : (
            <KitColumn justify="center">
              <span style={smallDisplay ? styles.columnHeader : null}>
                Gas Cost
              </span>
            </KitColumn>
          )}
          <KitColumn justify="end" pad={{ right: 'medium' }}>
            <span style={smallDisplay ? styles.columnHeader : null}>
              {purchaseStatus === PURCHASE_STATUS.nascent ? (
                <span>Actions</span>
              ) : (
                <span>Status</span>
              )}
            </span>
          </KitColumn>
        </KitRow>
        {renderAvastars()}
      </KitList>
    );
  }

  // Render the Avastars in the teleporter as cards or rows
  function renderAvastars() {
    return teleporter.map((listed) => (
      <AvastarRow
        {...props}
        key={listed.id}
        listed={listed}
        prices={prices}
        gasPrice={gasPrice}
        inTeleporter={true}
        showActions={purchaseStatus === PURCHASE_STATUS.nascent}
        minted={isMinted(listed.avastar)}
        failed={isFailed(listed.avastar)}
        retries={getRetries(listed.avastar)}
      />
    ));
  }

  // Render the user's network and optionally wallet connection status
  function renderConnectionStatus() {
    return !!account ? (
      teleporter.length ? (
        <span>
          {network !== '' ? (
            <span>
              {network} {smallDisplay ? <br /> : <span>/</span>}{' '}
            </span>
          ) : null}
          <span style={styles.checkoutNumeric}>{truncatedAccount}</span>
        </span>
      ) : (
        <span>{network !== '' ? <span>{network}</span> : null}</span>
      )
    ) : (
      walletStatus
    );
  }

  // Render a purchase button or a message asking to connect to the desired network
  function renderActionButton() {
    let component;
    switch (purchaseStatus) {
      case PURCHASE_STATUS.nascent:
        component =
          walletStatus === WALLET_STATUS.wrong ? (
            <KitHeaderField>
              <span>Please connect to {desiredNetName}&nbsp;&nbsp;</span>
            </KitHeaderField>
          ) : !!account && !!profile ? (
            <KitLargeButton onClick={async () => makePurchase()}>
              Teleport
            </KitLargeButton>
          ) : (
            <KitLoading text={''} pad="none" margin="none" size={50} key={0} />
          );
        break;

      case PURCHASE_STATUS.insufficient:
      case PURCHASE_STATUS.rejected:
        component = (
          <span>
            <KitLargeButton
              onClick={() => setPurchaseStatus(PURCHASE_STATUS.nascent)}
            >
              Cancel
            </KitLargeButton>
            &nbsp;|&nbsp;
            <KitLargeButton onClick={() => makePurchase()}>
              Continue
            </KitLargeButton>
          </span>
        );
        break;

      case PURCHASE_STATUS.empty:
      case PURCHASE_STATUS.complete:
        component = (
          <KitLargeButton onClick={() => purchaseComplete()}>
            Finish
          </KitLargeButton>
        );
        break;

      case PURCHASE_STATUS.partial:
        component = (
          <KitLargeButton onClick={() => purchasePartiallyComplete()}>
            Continue
          </KitLargeButton>
        );
        break;

      default:
        component = (
          <KitLoading text={''} pad="none" margin="none" size={50} key={0} />
        );
    }

    return component;
  }

  // Coordinate steps of making a purchase
  async function makePurchase() {
    const total = calculateTotal(teleporter);
    const hasFunds = await checkFundsOnDeposit(total);

    // If deposit balance is sufficient, put the pedal to the metal
    if (hasFunds) {
      setPurchaseStatus(PURCHASE_STATUS.queueing);
      processQueue();

      // If deposit balance is not sufficient, check the wallet balance and try to deposit the difference
    } else {
      const amount = web3.utils.fromWei(
        web3.utils
          .toBN(web3.utils.toWei(total, 'ether'))
          .sub(
            web3.utils.toBN(web3.utils.toWei(profile.depositBalance, 'ether'))
          ),
        'ether'
      );
      const availableFunds = await checkWalletBalance(amount);
      if (availableFunds) {
        // Deposit funds to Minter contract
        setPurchaseStatus(PURCHASE_STATUS.depositing);

        let deposited = false;
        const contract = new web3.eth.Contract(
          config.primeMinter.abi,
          config.primeMinter.address
        );
        const difference = web3.utils.toWei(amount, 'ether');
        try {
          await contract.methods
            .deposit()
            .send({ from: account, value: difference });
          deposited = true;
        } catch (e) {
          setPurchaseStatus(PURCHASE_STATUS.rejected);
        }

        // After funds are deposited, begin processing
        if (deposited) {
          setPurchaseStatus(PURCHASE_STATUS.queueing);
          processQueue();
        }
      } else {
        setPurchaseStatus(PURCHASE_STATUS.insufficient);
      }
    }
  }

  // Check the user's funds on deposit
  async function checkFundsOnDeposit(total) {
    return profile.depositBalance >= total;
  }

  // Check the user's wallet balances to be sure they can purchase
  async function checkWalletBalance(total) {
    let walletBalance = await getWalletBalance();
    return walletBalance >= total;
  }

  // Process the teleporter and queue the first Avastar for minting
  function processQueue() {
    let dto = { avastars: [] };

    // Reduce array of Avastars to data transfer object
    teleporter.reduce((dto, listed) => {
      let q = {
        uid: user.uid,
        purchaser: account,
        price: convertToEth(
          web3.utils
            .toBN(web3.utils.toWei(String(getPrice(listed)), 'ether'))
            .add(web3.utils.toBN(gasPrice))
            .toString()
        ),
        gender: listed.avastar.gender === Gender.MALE ? 1 : 2,
        traits: listed.avastar.getHashString(),
        ranking: listed.avastar.score,
        status: MINTING_STATUS.pending,
        retries: 0,
      };

      q.id = getId(q);
      dto.avastars.push(q);

      return dto;
    }, dto);

    // Set the teleporter status
    setQueueStatus(dto);
  }

  function convertToEth(price) {
    return web3.utils.fromWei(String(price), 'ether');
  }

  // Unique id is trait hash + number of retries
  function getId(queued) {
    return `${queued.traits}-${queued.retries}`;
  }

  // Receive teleporter status updates
  async function queueStatusUpdate(queued) {
    // Ignore initial update after write
    if (queued.status === MINTING_STATUS.pending) return;

    // Remove currently queued avastar
    const id = queued.id;
    await firebase.removeQueue(id);

    // Retry a failed attempt three times
    if (queued.status === MINTING_STATUS.failed) {
      queued.retries++;
      if (queued.retries < 4) {
        queued.status = MINTING_STATUS.pending;
        queued.id = getId(queued);
        queued.txErr = null;
        queued.txEnd = null;
        queued.txStart = null;
        queued.txHash = null;
      }
    }

    // Remove minted or failed Avastar from teleporter and update teleporter status
    let queueDto = { ...queueStatus };
    const index = queueDto.avastars.findIndex(
      (q) => q.traits === queued.traits
    );
    queueDto.avastars[index] = queued;
    setQueueStatus(queueDto);
  }

  // Queue the next Avastar for minting
  async function queueNextAvastar(dto) {
    // Bail if refunding or teleporter empty
    if (
      purchaseStatus === PURCHASE_STATUS.refunding ||
      purchaseStatus === PURCHASE_STATUS.empty
    )
      return;

    // Get the total minted so far
    const minted = dto.avastars.filter(
      (q) => q.status === MINTING_STATUS.minted
    );

    // Find the index of the first Avastar that hasn't yet been minted
    let index = dto.avastars.findIndex(
      (q) => q.status === MINTING_STATUS.pending
    );
    if (index >= 0) {
      // Unique id is trait hash + number of retries so far
      const id = getId(dto.avastars[index]);

      // Queue the Avastar to the database, then monitor for updates
      await firebase
        .queueAvastar(id, dto.avastars[index])
        .then(() => firebase.monitorQueue(id, queueStatusUpdate));

      setPurchaseStatus(
        `${minted.length} of ${teleporter.length} teleported...`
      );
    } else {
      index = dto.avastars.findIndex((q) => q.status === MINTING_STATUS.failed);
      if (index < 0) {
        setPurchaseStatus(PURCHASE_STATUS.complete);
      } else {
        setPurchaseStatus(PURCHASE_STATUS.partial);
      }
    }
  }

  // Is the given Avastar in the teleporter minted?
  function isMinted(avastar) {
    let index = !!queueStatus
      ? queueStatus.avastars.findIndex(
          (q) => avastar.getHashString() === q.traits
        )
      : false;
    return (
      !!queueStatus &&
      queueStatus.avastars[index].status === MINTING_STATUS.minted
    );
  }

  // Is the given avastar in the teleporter failed?
  function isFailed(avastar) {
    let index = !!queueStatus
      ? queueStatus.avastars.findIndex(
          (q) => avastar.getHashString() === q.traits
        )
      : false;
    return (
      !!queueStatus &&
      (queueStatus.avastars[index].status === MINTING_STATUS.failed ||
        (queueStatus.avastars[index].status === MINTING_STATUS.pending &&
          queueStatus.avastars[index].retries > 0))
    );
  }

  // Get the number of retries elapsed for a queued Avastar
  function getRetries(avastar) {
    let index = !!queueStatus
      ? queueStatus.avastars.findIndex(
          (q) => avastar.getHashString() === q.traits
        )
      : false;
    return !!queueStatus ? queueStatus.avastars[index].retries : 0;
  }

  // Final cleanup after user acknowledges purchase completion
  function purchaseComplete() {
    setPurchaseStatus(PURCHASE_STATUS.nascent);
    setQueueStatus(null);
    setTeleporter([]);
  }

  // Final cleanup after user a partially complete purchase
  function purchasePartiallyComplete() {
    // Find the failed attempts
    const failed = queueStatus.avastars.filter(
      (q) => q.status === MINTING_STATUS.failed
    );

    // Find the corresponded ListedAvastars in the teleporter queue
    const queue = teleporter.filter(
      (listed) =>
        failed.findIndex((q) => q.traits === listed.avastar.getHashString()) !==
        -1
    );

    // Cleanup
    setPurchaseStatus(PURCHASE_STATUS.nascent);
    setQueueStatus(null);
    setTeleporter(queue);
  }

  // Render teleporter list
  return (
    <Animated
      animationIn={transition}
      animateOnMount={true}
      animationInDuration={350}
      animationInDelay={0}
    >
      <KitHeader
        withTabs={true}
        smallTabs={mediaSize !== MEDIA.MOBILE.SIZE}
        displayMode={smallDisplay ? 'mobile' : 'desktop'}
      >
        <KitHeaderField>
          Total:
          <span style={styles.checkoutNumeric}>
            Ξ&nbsp;{Number.parseFloat(calculateTotal(teleporter)).toFixed(2)}
          </span>
        </KitHeaderField>

        {purchaseStatus === PURCHASE_STATUS.nascent ? (
          renderConnectionStatus()
        ) : (
          <span>{purchaseStatus}</span>
        )}

        {!!web3 && teleporter.length ? (
          renderActionButton()
        ) : walletStatus === WALLET_STATUS.connected ? (
          truncatedAccount
        ) : (
          <Wallet
            setGasPrice={setGasPrice}
            setGasPriceEth={setGasPriceEth}
            {...props}
          />
        )}
      </KitHeader>
      {renderList()}
    </Animated>
  );
}
