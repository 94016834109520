import React from 'react';
import { Box, ThemeContext } from 'grommet';

function Logo(props) {
  return (
    <Box align="center" justify="start" direction="row" gap="small">
      <ThemeContext.Consumer>
        {(theme) => {
          const fillColor =
            theme.dark === true
              ? theme.global.colors['secondary'].dark
              : theme.global.colors['secondary'].light;
          return (
            <svg
              version="1.1"
              id="LogoSVG"
              x="0px"
              y="0px"
              width={props.displayMode === 'small' ? '320px' : '1523.6px'}
              height="220px"
              viewBox={
                props.displayMode === 'small' ? '0 0 320 220' : '0 0 1523.6 220'
              }
            >
              <g>
                {props.displayMode !== 'small' && (
                  <>
                    <path
                      fill={fillColor}
                      d="M870.2,105.1c-8.1-3.7-7.2-17.9,6-20.2c19.8-3.6,16.6,12.8,24.6,11.6c5.7-0.9,6.3-9.1-4.5-16.3
		c-17.4-11.8-43-0.9-40.9,19.5c2.4,24.3,44.5,12.6,42.3,32.1c-1.7,14.8-25.7,16.8-32.8,1.4c-3-6.4-14.3-3.8-7.2,7.5
		c13,20.7,53.6,13.6,49.7-14.3C905,109.8,885,111.7,870.2,105.1z"
                    />
                    <path
                      fill={fillColor}
                      d="M1044.1,77c-29.7,0-34-1.5-34,4.4c0,5.8,7.2,4.4,14.3,4.4c0,63.5-1.8,65.1,4.9,65.1c6.7,0,4.9-1.2,4.9-65.2
		c7.2,0,14.2,1.4,14.2-4.3C1048.5,78.9,1046.5,77,1044.1,77z"
                    />
                    <path
                      fill={fillColor}
                      d="M590.6,77c-4,0-2.2-1.6-31.4,63.4C530.1,75.6,531.9,77,527.9,77c-3.2,0-5.4,3.3-4,6.3l28.6,63.5
		c2.7,5.9,11.1,5.9,13.7,0l28.6-63.5C596,80.3,593.8,77,590.6,77z"
                    />
                    <path
                      fill={fillColor}
                      d="M402.7,81.4c-2.7-5.9-11.1-5.9-13.7,0l-28.6,63.5c-1.3,2.9,0.8,6.3,4,6.3c3.8,0,3.6-1.6,12.6-21.6h37.6
		l8.6,19c2.4,5.3,10.5,1.7,8.1-3.6L402.7,81.4z M375,120.8c10.6-5.7,5.8,0.5,20.9-33.1l14.9,33.1H375z"
                    />
                    <path
                      fill={fillColor}
                      d="M729.5,81.4c-2.7-5.9-11.1-5.9-13.7,0l-28.6,63.5c-1.3,2.9,0.8,6.3,4,6.3c3.8,0,3.6-1.6,12.6-21.6h37.6
		l8.6,19c2.4,5.3,10.5,1.7,8.1-3.6L729.5,81.4z M701.8,120.8c10.6-5.7,5.8,0.5,20.9-33.1l14.9,33.1H701.8z"
                    />
                    <path
                      fill={fillColor}
                      d="M1190.8,81.4c-2.7-5.9-11.1-5.9-13.7,0l-28.6,63.5c-1.3,2.9,0.8,6.3,4,6.3c3.8,0,3.6-1.6,12.6-21.6h37.6
		l8.6,19c2.4,5.3,10.5,1.7,8.1-3.6L1190.8,81.4z M1163,120.8c10.6-5.7,5.8,0.5,20.9-33.1l14.9,33.1H1163z"
                    />
                    <path
                      fill={fillColor}
                      d="M1349.4,77.1c-21.9-0.6-25.4-1-25.4,3.5c0,66.8-1.9,70.3,4.7,70.3c7.1,0,5.1-7.9,5.1-21.4l15,0
		c15.8,21.4,15.1,21.6,18.3,21.6c3.9,0,6.1-4.5,3.7-7.6l-12.3-15.6C1384.1,118.8,1381.7,78,1349.4,77.1z M1354.7,120.8
		c-32.9,0-29.6,1.8-21-4.3l0.1-30.9h18.9C1370.6,85.6,1371.9,120.8,1354.7,120.8z"
                    />
                    <path
                      fill={fillColor}
                      d="M1486.2,105.1c-8.1-3.7-7.2-17.9,6-20.2c19.8-3.6,16.6,12.8,24.6,11.6c5.7-0.9,6.3-9.1-4.5-16.3
		c-17.4-11.8-43-0.9-40.9,19.5c2.4,24.3,44.5,12.6,42.3,32.1c-1.7,14.8-25.7,16.8-32.8,1.4c-3-6.4-14.3-3.8-7.2,7.5
		c13,20.7,53.6,13.6,49.7-14.3C1521,109.8,1501,111.7,1486.2,105.1z"
                    />
                  </>
                )}
                <g>
                  <path
                    fill={fillColor}
                    d="M251.8,101.5c-23.9,0-24.9,0.8-25.9-2.4l-7-21.7c-1-3.2-5.6-3.2-6.6,0c-7.1,22.2-6.8,24.1-10.3,24.1h-22.6
			c-3.4,0-4.8,4.3-2.1,6.3c19.5,14.3,20.6,14.1,19.6,17.3l-7,21.8c-1,3.2,2.6,5.8,5.4,3.9c19.3-14.2,19.6-15.4,22.3-13.4l18.2,13.4
			c2.7,2,6.4-0.7,5.4-3.9c-7.4-23.2-8.4-23.7-5.8-25.6l18.4-13.5C256.6,105.8,255.2,101.5,251.8,101.5z"
                  />
                  <path
                    fill={fillColor}
                    d="M183.1,174.5l-11.9,22.2l18.4-71.1c1.2-4.5-35.8-18-59.8-71.5l-22-49c-3.1-6.9-12.8-6.8-15.9,0.1
			c-20.9,48-24.6,34.6,41.7,85.1l-56-30.9c-4.4-2.4-10-0.6-12.1,4l-31.7,70.4c-2.7,6,1.9,12.6,8.5,12.2l89-5.8l-68.1,14.6
			C15.1,165.1-15.7,220,8.4,220h54.8c17.9,0,17.3-3.5,102.6-81l-54.6,66.8c-4.6,5.7-0.6,14.2,6.7,14.2h72.8c6.4,0,10.5-6.6,7.8-12.4
			L183.1,174.5z"
                  />
                </g>
              </g>
            </svg>
          );
        }}
      </ThemeContext.Consumer>
    </Box>
  );
}

export default Logo;
