import React from 'react';
import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';
import DarkBackgroundImage from './assets/DarkBackground.png';
import LightBackgroundImage from './assets/LightBackground.png';
import { Box, ThemeContext } from 'grommet';
export const KitGlobal = createGlobalStyle`
  html, body {
    overflow-x: hidden;
    height: 100%;
    user-select: none;

  }
  body{

  }
  #app{
    height: 100%;
    width: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    min-height: 100vh;
  }

  @media (min-width: 2048px) {
    .container {
      max-width: 1800px;
    }
  }

`;

const AppContainer = (props) => {
  const { className, themeStyle, ...otherProps } = props;
  return (
    <ThemeContext.Consumer>
      {(theme) => {
        const styleColor = theme.global.colors[themeStyle];
        return (
          <>
            <Box
              className={`${className} AppContainer ${
                themeStyle === 'themeDark' ? 'darkBg' : 'lightBg'
              }`}
              // fill="vertical"
              align="center"
              background={styleColor || '#eee'}
              // pad={{ top: "80px" }} // TEMPORARY POSITIONING UNTIL THEMES ARE RECONCILED
              {...otherProps}
            />
            <div
              className={`BgContainer ${
                themeStyle === 'themeDark' ? 'darkBg' : 'lightBg'
              }`}
            />
          </>
        );
      }}
    </ThemeContext.Consumer>
  );
};
// background-position: top left;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-attachment: fixed;
// background-attachment: fixed;

export const KitAppContainer = styled(AppContainer)`
  height: 100%;
  min-height: 100vh;
  padding-top: 80px;
  position: relative;
  background: transparent !important;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0px);
  transform: translate3d(0, 0, 0px);
  + .BgContainer {
    height: 100vh;
    position: absolute;
    background-position: top left;
    background-repeat: repeat-y;
    background-size: 100% auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px);
    &.darkBg {
      background-image: url(${DarkBackgroundImage});
    }
    &.lightBg {
      background-image: url(${LightBackgroundImage});
    }
  }
`;
