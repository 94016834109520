import React from "react";
import styled from "styled-components";
import { Box } from "grommet";

/**
 * Uses child first/last selectors to handle overriding borders ... allows for any amount of buttons inside group.
 */
const ButtonGroupStyles = `
    > button[type=button] {
      border-radius: 0px;
      outline: none;
      &:first-child{
        border-top-left-radius: 2rem;   
        border-bottom-left-radius: 2rem;   
      }
      &:last-child{
        border-top-right-radius: 2rem;   
        border-bottom-right-radius: 2rem;   
      }
    }
  `;

const VerticalButtonGroupStyles = `
    > button[type=button] {
      border-radius: 0px;
      outline: none;
      &:first-child{
        border-top-left-radius: 2rem;   
        border-top-right-radius: 2rem;   
      }
      &:last-child{
        border-bottom-left-radius: 2rem;   
        border-bottom-right-radius: 2rem;   
      }
    }
  `;

const ButtonGroup = props => {
  const {
    children,
  } = props;
  return (
      <Box
          {...props}
          justify="stretch"
          direction="row"
      >
        {children}
      </Box>
  );
};

const VerticalButtonGroup = props => {
  const {
    children,
  } = props;
  return (
      <Box
          {...props}
          justify="stretch"
          direction="column"
      >
        {children}
      </Box>
  );
};

export const KitButtonGroup = styled(ButtonGroup)`
  ${ButtonGroupStyles}
`;

export const VerticalKitButtonGroup = styled(VerticalButtonGroup)`
  ${VerticalButtonGroupStyles}
`;
