import React from "react";
import { ThemeContext } from "grommet";
import { KitBox } from "../../../theme/kit";

const LegendarySvg = (props) => {
  const {
    borderColor = "#eee",
    fillColor = "#222",
    borderSize = "1",
    // size = 20,
  } = props;
  return (
    // <svg width="20px" height="20px" viewBox="0 0 20 20">
    <svg
      // width={`${size}px`}
      // height={`${size}px`}
      viewBox={`0 0 20 20`}
    >
      <g
        stroke="none"
        strokeWidth={borderSize || "1"}
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(1.000000, 2.000000)">
          <polygon
            id="border"
            stroke={borderColor || "#EC5663"}
            points="0 5 9 17 18 5 15 0 3 0"
          />
          <polygon
            id="fill"
            fill={fillColor || "#ED606C"}
            points="4 4 5 3 13 3 14 4 9 11"
          />
        </g>
      </g>
    </svg>
  );
};

const EpicSvg = (props) => {
  const { borderColor = "#eee", fillColor = "#222", borderSize = "1" } = props;
  return (
    <svg
      // width="20px"
      // height="20px"
      viewBox="0 0 20 20"
    >
      <g
        stroke="none"
        strokeWidth={borderSize || "1"}
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(1.00, 4.00)">
          <rect
            id="border"
            stroke={borderColor || "#8446E7"}
            x="0.5"
            y="0.5"
            width="17"
            height="10"
            rx="5"
          />
          <rect
            id="fill"
            fill={fillColor || "#8446E7"}
            x="3"
            y="3"
            width="12"
            height="5"
            rx="2.5"
          />
        </g>
      </g>
    </svg>
  );
};

const RareSvg = (props) => {
  const { borderColor = "#eee", fillColor = "#222", borderSize = "1" } = props;
  return (
    <svg
      // width="20px"
      // height="20px"
      viewBox="0 0 20 20"
    >
      <g
        stroke="none"
        strokeWidth={borderSize || "1"}
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(2.000000, 2.000000)">
          <polygon
            id="border"
            stroke={borderColor || "#F39C1F"}
            points="-2.84217094e-14 16 16 16 8 0"
          />
          <polygon
            id="fill"
            fill={fillColor || "#F39C1F"}
            points="3 14 13 14 8 4"
          />
        </g>
      </g>
    </svg>
  );
};
const UncommonSvg = (props) => {
  const { borderColor = "#eee", fillColor = "#222", borderSize = "1" } = props;
  return (
    <svg
      // width="20px"
      // height="20px"
      viewBox="0 0 20 20"
    >
      <g
        stroke="none"
        strokeWidth={borderSize || "1"}
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(2.000000, 2.000000)">
          <circle
            id="border"
            stroke={borderColor || "#5EB481"}
            cx="8"
            cy="8"
            r="8"
          />
          <circle id="fill" fill={fillColor || "#5EB481"} cx="8" cy="8" r="5" />
        </g>
      </g>
    </svg>
  );
};
const CommonSvg = (props) => {
  const {
    borderColor = "#eee",
    fillColor = "#222",
    borderSize = "1",
    // size = 20,
  } = props;
  return (
    <svg
      // width="20px"
      // height="20px"
      viewBox="0 0 20 20"
    >
      <g
        stroke="none"
        strokeWidth={borderSize || "1"}
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(2.000000, 2.000000)">
          <rect
            id="border"
            stroke={borderColor || "#3B90F3"}
            x="0.5"
            y="0.5"
            width="15"
            height="15"
          />
          <rect
            id="fill"
            fill={fillColor || "#3B90F3"}
            x="3"
            y="3"
            width="10"
            height="10"
          />
        </g>
      </g>
    </svg>
  );
};

const MaleSvg = (props) => {
  const {
    borderColor = "#5B19E5",
    fillColor = "#5B19E5",
    borderSize = "1",
  } = props;
  return (
    <svg width="100%" height="100%" viewBox="0 0 20 20">
      <g
        id="iconMale"
        stroke="none"
        strokeWidth={borderSize || "1"}
        fill="none"
        fillRule="evenodd"
      >
        <g id="Male" transform="translate(4.000000, 2.000000)">
          <path
            d="M2.5,14.5 L9.5,14.5"
            id="border"
            stroke={borderColor || "#3B90F3"}
            strokeLinecap="square"
          />
          <circle
            id="border"
            stroke={borderColor || "#3B90F3"}
            cx="6"
            cy="6"
            r="6"
          />
          <circle id="fill" fill={fillColor || "#3B90F3"} cx="6" cy="6" r="4" />
        </g>
        <path
          d="M10,14 L10,18.5"
          id="border"
          stroke={borderColor || "#3B90F3"}
          strokeLinecap="square"
        />
      </g>
    </svg>
  );
};

const FemaleSvg = (props) => {
  const {
    borderColor = "#5B19E5",
    fillColor = "#5B19E5",
    borderSize = "1",
  } = props;
  return (
    <svg width="100%" height="100%" viewBox="0 0 20 20">
      <g
        id="iconFemale"
        stroke="none"
        strokeWidth={borderSize || "1"}
        fill="none"
        fillRule="evenodd"
      >
        <g id="Female" transform="translate(3.000000, 2.000000)">
          <path
            d="M10.4878326,4.51216736 L14,1"
            id="border"
            stroke={borderColor || "#3B90F3"}
          />
          <path
            d="M14,1 L14,5"
            id="border"
            stroke={borderColor || "#3B90F3"}
            strokeLinecap="square"
          />
          <path
            d="M14,1 L10,1"
            id="border"
            stroke={borderColor || "#3B90F3"}
            strokeLinecap="square"
          />
          <circle
            id="border"
            stroke={borderColor || "#3B90F3"}
            cx="6"
            cy="9"
            r="6"
          />
          <circle id="fill" fill={fillColor || "#3B90F3"} cx="6" cy="9" r="4" />
        </g>
      </g>
    </svg>
  );
};

export default function RarityIcon(props) {
  const {
    displayMode = "colored",
    rarity,
    active = false,
    size = "medium",
    className,
    inline = false,
    ...otherProps
  } = props;
  

  return (
    <ThemeContext.Consumer>
      {(theme) => {
        /**
         * If it is ui mode:  color it like the current theme line color,
         * otherwise: color it the color of the rarity
         */
        const uiModeColor = theme.dark
          ? theme.global.colors["secondary"].dark
          : theme.global.colors["secondary"].light;
        const activeColor =
          displayMode === "ui" ? uiModeColor : theme.global.colors[rarity];
        const sizeMap = {
          small: 12,
          medium: 20,
          large: 32,
          hero: 96,
        };
        const borderSize = size === "small" && active === false ? 3 : 1; // improves resolution at small sizes
        const iconMap = {
          Legendary: (
            <LegendarySvg
              borderColor={activeColor}
              borderSize={borderSize}
              fillColor={active ? activeColor : "transparent"}
              size={sizeMap[size]}
            />
          ),
          Epic: (
            <EpicSvg
              borderColor={activeColor}
              borderSize={borderSize}
              fillColor={active ? activeColor : "transparent"}
            />
          ),
          Rare: (
            <RareSvg
              borderColor={activeColor}
              borderSize={borderSize}
              fillColor={active ? activeColor : "transparent"}
            />
          ),
          Uncommon: (
            <UncommonSvg
              borderColor={activeColor}
              borderSize={borderSize}
              fillColor={active ? activeColor : "transparent"}
            />
          ),
          Common: (
            <CommonSvg
              borderColor={activeColor}
              borderSize={borderSize}
              fillColor={active ? activeColor : "transparent"}
            />
          ),
          Male: (
            <MaleSvg
              borderColor={activeColor}
              borderSize={borderSize}
              fillColor={active ? activeColor : "transparent"}
            />
          ),
          Female: (
            <FemaleSvg
              borderColor={activeColor}
              borderSize={borderSize}
              fillColor={active ? activeColor : "transparent"}
            />
          ),
        };

        return (
          <KitBox
            width={`${sizeMap[size]}px`}
            height={`${sizeMap[size]}px`}
            align="center"
            justify="center"
            style={inline ? { display: "inline-flex" } : props.style}
            {...otherProps}
          >
            {iconMap[rarity]}
          </KitBox>
        );
      }}
    </ThemeContext.Consumer>
  );
}
