import React from "react";
import styled from "styled-components";
import { FingerprintSpinner } from "react-epic-spinners";
import { Box, Text, ThemeContext, Stack } from "grommet";

/**
 * Loading Spinner
 */
export const KitLoading = props => {
  const { text, animated = true, size, pad, margin } = props;
  return (
    <ThemeContext.Consumer>
      {theme => {
        const iconColor = theme.dark
          ? theme.global.colors["control"].dark
          : theme.global.colors["control"].light;
        return (
          <Box
            background={"background-back"}
            align="center"
            justify="center"
            pad={pad||"small"}
            margin={margin||"small"}
            round="xsmall"
          >
            {animated && <FingerprintSpinner color={iconColor} size={size||60} />}
            <Text color={iconColor}>{text}</Text>
          </Box>
        );
      }}
    </ThemeContext.Consumer>
  );
};



/**
 * SERIES ICON
 * shows what series the avastar belongs to
 * changes color depending apon active theme
 * TODO: consider the positioning styles for smaller screens.
 */

const SeriesIconSvg = props => {
  const { borderColor = "#eee", fillColor = "#222" } = props;
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" fill="none">
        <polygon
          id="fill"
          fill={fillColor || "#979797"}
          fillRule="nonzero"
          points="12 0 24 12 12 24 0 12"
        />
        <path
          d="M12,1.41421356 L1.41421356,12 L12,22.5857864 L22.5857864,12 L12,1.41421356 Z M12,0 L24,12 L12,24 L0,12 L12,0 Z"
          fill={borderColor || "#5F5F5F"}
          stroke={borderColor || "#5F5F5F"}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

const SeriesIcon = props => {
  const { number, className, ...otherProps } = props; // offset = 6, 
  return (
    <ThemeContext.Consumer>
      {theme => {
        const backgroundColor =
          theme.dark === true
            ? theme.global.colors["highlight"].dark
            : theme.global.colors["highlight"].light;
        const borderColor =
          theme.dark === true
            ? theme.global.colors["background-front"].dark
            : theme.global.colors["background-front"].light;
        return (
          <Box
            className={className}
            {...otherProps}
          >
            <Stack anchor="center">
              <SeriesIconSvg
                borderColor={borderColor}
                fillColor={backgroundColor}
              />
              <Box align="center" justify="center" width="24px" height="24px">
                <Text size="small" color="text">
                  <strong>{number}</strong>
                </Text>
              </Box>
            </Stack>
          </Box>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export const KitSeriesIcon = styled(SeriesIcon)`
  width: 22px;
  height: 24px;
  margin-left: -6px;
`;

