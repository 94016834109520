/**
 * Schema Enum: Gender
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
class Gender {}

Gender.ANY     = "Any";

Gender.MALE    = 'Male';
Gender.FEMALE  = "Female";

Gender.TYPES   = [Gender.MALE, Gender.FEMALE];

export default Gender;