import React from 'react';
import AvastarImage from './AvastarImage';
import { AvastarBorder } from './AvastarBorder';
import { avastar as styles } from '../../../theme/styles';
import { THUMB_SIZE } from '../../../constants/media';
import { WALLET_STATUS } from '../../../constants/wallet';
import {
  QUEUE_LISTS,
  QUEUE_REMOVE,
  MAX_CONSIGNED,
} from '../../../constants/marketplace';
import Rarity from '../../../domain/enum/Rarity';
import {
  ProgressIcon,
  CheckIcon,
  OneRetryIcon,
  TwoRetryIcon,
  ThreeRetryIcon,
  ErrorIcon,
  KitRow,
  KitColumn,
  KitSmallButton,
  KitIconButton,
  KitMenu,
  KitLargeButton,
  EllipsisIcon,
  KitHeading,
} from '../../../theme/kit';
import ListedAvastar from '../../../domain/entity/ListedAvastar';
import * as web3 from 'web3';

export default function AvastarRow(props) {
  // State from props
  const {
    account,
    listed,
    viewedAvastar,
    setViewedAvastar,
    profile,
    unsoldCount,
    inTeleporter = false,
    inWishlist = false,
    inConsigned = false,
    showActions = true,
    minted = false,
    failed = false,
    retries = 0,
    mediaSize,
    smallDisplay,
    walletStatus,
    removeFromQueue,
    moveToTeleporter,
    moveToConsigned,
    moveToWishlist,
    getPrice,
    getFee,
    gasPrice,
  } = props;

  // Card label details
  const rarity = Rarity.LEVELS[listed.avastar.level - 1];
  const price = getPrice(listed);

  const fee = inConsigned ? getFee(listed) : 0;

  // Opens the modal when a user clicks the Avastar image
  const handleAvastarClick = (listed) => {
    if (!viewedAvastar) setViewedAvastar(listed);
  };

  // ACTION RULES:
  //   1 - You can only remove Avastars completely from the teleporter
  //   2 - You can move Avastars you generated from teleporter to wishlist or consigned
  //   3 - You can move Avastars from wishlist to consigned or teleporter
  //   4 - You can move Avastars from consigned to wishlist or teleporter
  //   5 - You cannot move Avastars that were found by others to consigned from teleporter or wishlist
  //   6 - You cannot move Avastars to consigned until you have at least 1 Avastar in your profile
  //   7 - You cannot have more than 10 Avastars consigned at once
  const renderActions = () => {
    // If we show an Actions menu, these actions are available
    let items;

    if (inTeleporter && profile) {
      items =
        (listed.isGenerated() || listed.compareFinder(account)) &&
        profile.total &&
        unsoldCount < MAX_CONSIGNED
          ? [
              { label: QUEUE_REMOVE, onClick: () => removeFromQueue(listed) },
              {
                label: QUEUE_LISTS.WISH_LIST,
                onClick: () => moveToWishlist(listed),
              },
              {
                label: QUEUE_LISTS.CONSIGNED,
                onClick: () => moveToConsigned(listed),
              },
            ]
          : [
              { label: QUEUE_REMOVE, onClick: () => removeFromQueue(listed) },
              {
                label: QUEUE_LISTS.WISH_LIST,
                onClick: () => moveToWishlist(listed),
              },
            ];
    } else if (inWishlist && profile) {
      items =
        listed.compareFinder(account) &&
        profile.total &&
        unsoldCount < MAX_CONSIGNED
          ? [
              {
                label: QUEUE_LISTS.TELEPORTER,
                onClick: () => moveToTeleporter(listed),
              },
              {
                label: QUEUE_LISTS.CONSIGNED,
                onClick: () => moveToConsigned(listed),
              },
            ]
          : [
              {
                label: QUEUE_LISTS.TELEPORTER,
                onClick: () => moveToTeleporter(listed),
              },
            ];
    } else if (inConsigned && profile) {
      items = [
        {
          label: QUEUE_LISTS.TELEPORTER,
          onClick: () => moveToTeleporter(listed),
        },
        { label: QUEUE_LISTS.WISH_LIST, onClick: () => moveToWishlist(listed) },
      ];
    }

    function getRemoveButton() {
      return smallDisplay ? (
        <KitSmallButton onClick={() => removeFromQueue(listed)}>
          Remove
        </KitSmallButton>
      ) : (
        <KitLargeButton onClick={() => removeFromQueue(listed)}>
          Remove
        </KitLargeButton>
      );
    }
    // Special case in teleporter, show remove button instead of actions if wallet not connected
    return inTeleporter && listed ? (
      <span style={styles.clickable}>
        {walletStatus === WALLET_STATUS.connected && profile ? (
          listed.status === ListedAvastar.STATUS.BUYING ? (
            <KitHeading level="3" color="secondary">
              <KitMenu label="Actions" items={items} />
            </KitHeading>
          ) : (
            getRemoveButton()
          )
        ) : (
          getRemoveButton()
        )}
      </span>
    ) : listed && profile ? (
      inConsigned &&
      (listed.status === ListedAvastar.STATUS.CONSIGNED ||
        listed.status === ListedAvastar.STATUS.EXPIRED) ? (
        <span style={styles.clickable}>
          <KitHeading level="3" color="secondary">
            <KitMenu label="Actions" items={items} />
          </KitHeading>
        </span>
      ) : inConsigned ? (
        <span>
          <KitHeading level="4" color="secondary">
            {listed.status}
            {smallDisplay ? null : <>&nbsp;&nbsp;</>}
          </KitHeading>
        </span>
      ) : (
        <span style={styles.clickable}>
          <KitHeading level="3" color="secondary">
            <KitMenu label="Actions" items={items} />
          </KitHeading>
        </span>
      )
    ) : (
      <span>
        <KitIconButton
          color="secondary"
          icon={<EllipsisIcon style={styles.avastarQueue} />}
        />
        {smallDisplay ? null : <>&nbsp;&nbsp;</>}
      </span>
    );
  };

  // If retrying, show retry count or error icons
  const renderRetryStatus = () => {
    let icon;
    switch (retries) {
      case 1:
        icon = <OneRetryIcon style={styles.avastarQueue} />;
        break;

      case 2:
        icon = <TwoRetryIcon style={styles.avastarQueue} />;
        break;

      case 3:
        icon = <ThreeRetryIcon style={styles.avastarQueue} />;
        break;

      default:
        icon = <ErrorIcon style={styles.avastarQueue} />;
        break;
    }
    return icon;
  };

  // During teleportation, status icons are shown instead of actions
  const renderStatus = () => {
    let icon = minted ? (
      <KitIconButton
        color="secondary"
        icon={<CheckIcon style={styles.avastarQueue} />}
      />
    ) : failed ? (
      <KitIconButton color="secondary" icon={renderRetryStatus()} />
    ) : (
      <KitIconButton
        color="secondary"
        icon={<ProgressIcon style={styles.avastarQueue} />}
      />
    );

    return smallDisplay ? icon : <>{icon}&nbsp;&nbsp;</>;
  };

  // Card style based on rarity level
  const cardStyle = () => {
    return {
      ...styles.avastarCard,
      ...styles.avastarCardColor[listed.avastar.level - 1],
    };
  };

  // Avastar Image
  const renderAvastar = (listed) => {
    let size = THUMB_SIZE[mediaSize];
    return (
      <span style={styles.clickable} onClick={() => handleAvastarClick(listed)}>
        <AvastarImage avastar={listed.avastar} size={size} backdrop={true} />
      </span>
    );
  };

  // Render the row
  return (
    <KitRow displayMode="ui" hasBackground>
      &nbsp;
      <KitColumn>
        <AvastarBorder
          rarity={rarity}
          inset={true}
          angled={false}
          cardStyle={cardStyle()}
        >
          {renderAvastar(listed)}
        </AvastarBorder>
      </KitColumn>
      {smallDisplay ? null : (
        <KitColumn justify="center" pad={{ right: 'medium' }}>
          <span>
            {listed.avastar.generation} / {listed.avastar.series}
          </span>
        </KitColumn>
      )}
      {smallDisplay ? null : (
        <KitColumn justify="center" pad={{ right: 'medium' }}>
          <span style={styles.avastarCardColor[listed.avastar.level - 1]}>
            {rarity}
          </span>
        </KitColumn>
      )}
      <KitColumn justify="center" pad={{ right: 'medium' }}>
        <span>
          <span style={styles.currencySymbol}>Ξ</span>{' '}
          {inConsigned ? fee : price}
        </span>
      </KitColumn>
      {gasPrice === 0 ? null : (
        <KitColumn justify="center" pad={{ right: 'medium' }}>
          <span>
            <span style={styles.currencySymbol}>Ξ</span>{' '}
            {web3.utils.fromWei(String(gasPrice), 'ether')}
          </span>
        </KitColumn>
      )}
      <KitColumn justify="end" pad={{ right: 'small' }}>
        {showActions ? renderActions() : renderStatus()}
      </KitColumn>
    </KitRow>
  );
}
