// validate the traits of an avastar
export const validateAvastar = (item, series) => {
  const traits = item.avastar.traits;

  let valid = true;

  traits.every((trait) => {
    valid = trait.series.includes(series);
    return valid;
  });

  return valid;
};
