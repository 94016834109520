import React from "react";
import { KitBox } from "../../../theme/kit";
import { avastar as styles } from "../../../theme/styles";

/**
 * BORDER
 * Handles various iterations of card borders, including the inset effect.
 * NOTE: Styles are handled here in order to combine with conditional theme variations (dark/light).
 */

export const AvastarBorder = (props) => {
  const {
    children,
    rarity,
    inset = false,
    highlighted = false,
    // ...otherProps
  } = props;

  const borderStyles = {
    display: "inline-block",
    boxShadow: "0 0 0 2px",
    border: "none",
    position: "relative",
    transition: "border-color .2s ease-in-out, box-shadow .2s ease-in-out",
    ...styles.avastarTraitColor[rarity],
    ...(highlighted ? styles.highlightedBorderStyle : null),
  };

  return (
    <KitBox style={borderStyles} pad={inset ? "2px" : "0px"}>
        {children}
    </KitBox>
  );
};
