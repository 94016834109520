import theme from './index';

/**
 * Navigation bar styles
 */
export const navigation = {
  separator: {
    color: theme.secondary.text,
  },

  barColor: {
    backgroundColor: '#0d0d16',
  },

  spinnerContainer: {
    height: '100%',
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.primary.dark,
    marginTop: 'auto',
    marginBottom: 'auto',
  },

  spinner: {
    color: theme.secondary.main,
  },
};

/**
 * Marketplace page styles
 */
export const marketplace = {
  introHeader: {
    color: theme.secondary.main,
  },

  queueContainer: {
    paddingTop: '90px',
    paddingLeft: '10px',
    paddingRight: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  page: {
    paddingTop: '0',
    maxWidth: '1140px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  tabBar: {
    position: 'sticky',
    top: '80px',
    zIndex: 5,
    flexDirection: 'row',
    display: 'flex',
    width: 'auto',
    justifyContent: 'space-between',
    paddingTop: '2px',
  },

  tabBarGroup: {
    flexDirection: 'row',
    display: 'flex',
  },

  tabs: {
    fontWeight: 'normal',
    borderBottom: 'none',
    margin: 0,
  },

  tabLeft: {
    borderRadius: '5px 0 0 0',
  },

  tabMiddle: {
    marginLeft: '1px',
    marginRight: '1px',
  },

  tabRight: {
    borderRadius: '0 5px 0 0',
  },

  tabSelected: {
    fontWeight: 'bold',
    borderBottom: 'solid 2px',
    cursor: 'auto',
    boxShadow: 'unset',
  },

  checkoutNumeric: {
    fontFamily: 'sans-serif',
    fontSize: '16px',
  },

  columnHeader: {
    fontSize: '12px',
  },

  depositInput: {
    input: {
      width: '7rem',
      height: '2rem',
    },
  },

  container: {
    width: '100%',
    paddingTop: '1.5rem',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },

  queueCardContainer: {
    paddingLeft: '10px',
    paddingRight: '10px',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },

  cardWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 0 21%',
    margin: '15px 5px',
    alignItems: 'center',
    justifyContent: 'center',
  },

  scrollerContainer: {
    height: '80%',
    width: '100%',
    margin: '0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },

  loader: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    background: theme.secondary.main,
    fontSize: '24px',
    padding: '15px',
    marginBottom: '10px',
    marginTop: '10px',
  },

  scrollerPage: {
    width: '100%',
    paddingLeft: '0',
    paddingRight: '0',
    margin: '0px',
    borderStyle: 'none',
    borderRadius: '5px',
    borderWidth: '1px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },

  queueList: {
    width: '100%',
    margin: '0px',
    borderStyle: 'none',
    borderRadius: '5px',
    borderWidth: '1px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    maxWidth: '2048px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  queueListCards: {
    width: '100%',
    margin: '0px',
    paddingLeft: '75px',
    paddingRight: '75px',
    borderStyle: 'none',
    borderRadius: '5px',
    borderWidth: '1px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },

  smallAnnotation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '.5rem',
  },
};

/**
 * Static page styles
 */
export const staticPages = {
  page: {
    paddingTop: '1.5rem',
    maxWidth: '1140px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  heroTitle: {
    fontWeight: '300',
    marginBottom: '3rem',
  },
  heroTrait: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },

  heroImage: {
    marginTop: 0,
    marginBottom: 0,
  },
  heroParagraph: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    borderTop: '1px solid',
    borderBottom: '1px solid',
  },
  cardDivider: {
    marginLeft: '-2px',
  },
  subtitleBody: {
    margin: 0,
    padding: '0 0.5rem',
  },
  subtitle: {
    fontSize: '12px',
    marginTop: 0,
    marginLeft: 0,
    marginBottom: 0,
    textTransform: 'uppercase',
  },
  offsetCard: {
    marginTop: '4rem',
  },
  generationNumber: {
    minWidth: '1.25rem',
    textAlign: 'center',
    fontWeight: 800,
    fontSize: '18px',
  },
  faqSection: {
    marginTop: '1rem',
  },
};

/**
 * Avastar Card styles
 */
const angleWidth = 12;
const outerAngleWidth = angleWidth + 1;
const childAngleWidth = angleWidth - 1;

export const avastar = {
  avastarCard: {
    display: 'inline-block',
    border: '2px solid',
    backgroundColor: theme.background.light,
  },
  avastarBorder: {
    Common: {
      backgroundColor: theme.background.Common,
      color: theme.background.Common,
    },
    Uncommon: {
      backgroundColor: theme.background.Uncommon,
      color: theme.background.Uncommon,
    },
    Rare: {
      backgroundColor: theme.background.Rare,
      color: theme.background.Rare,
    },
    Epic: {
      backgroundColor: theme.background.Epic,
      color: theme.background.Epic,
    },
    Legendary: {
      backgroundColor: theme.background.Legendary,
      color: theme.background.Legendary,
    },
  },
  highlightedBorderStyle: {
    boxShadow: '0 0 0 4px',
  },
  borderOuter: {},
  borderInner: {
    position: 'relative',
    height: '100%',
    width: '100%',
    left: '0px',
    top: '0px',
    right: '0px',
    bottom: '0px',
    zIndex: '1',
    transition: 'background .2s ease-in-out',
  },
  avastarTraitColor: {
    Common: {
      borderColor: theme.background.Common,
      color: theme.background.Common,
    },
    Uncommon: {
      borderColor: theme.background.Uncommon,
      color: theme.background.Uncommon,
    },
    Rare: {
      borderColor: theme.background.Rare,
      color: theme.background.Rare,
    },
    Epic: {
      borderColor: theme.background.Epic,
      color: theme.background.Epic,
    },
    Legendary: {
      borderColor: theme.background.Legendary,
      color: theme.background.Legendary,
    },
  },
  avastarCardColor: [
    {
      borderColor: theme.background.Common,
      color: theme.background.Common,
    },
    {
      borderColor: theme.background.Uncommon,
      color: theme.background.Uncommon,
    },
    {
      borderColor: theme.background.Rare,
      color: theme.background.Rare,
    },
    {
      borderColor: theme.background.Epic,
      color: theme.background.Epic,
    },
    {
      borderColor: theme.background.Legendary,
      color: theme.background.Legendary,
    },
  ],
  angleWidth: angleWidth,
  outerAngleWidth: outerAngleWidth,
  childAngleWidth: childAngleWidth,

  avastarCardLabel: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '5px',
    paddingRight: '5px',
    fontSize: '16px',
  },

  currencySymbol: {
    fontFamily: 'sans-serif',
    fontWeight: 700,
  },

  footerSection: {
    padding: '2px',
  },
  footerPrice: {
    padding: '2px',
    paddingLeft: '10px',
    flexBasis: '20%',
    whiteSpace: 'nowrap',
  },
  footerRarity: {
    padding: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // svg {
    //   position: relative;
    //   left: -0.25rem;
    // }
  },
  footerIcon: {
    padding: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexBasis: '20%',
    marginLeft: '0',
  },
  footerActions: {
    padding: 0,
    flexBasis: '20%',
    marginLeft: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
  },

  avastarQueue: {
    position: 'relative',
    top: '-1',
  },

  popoverTrigger: {
    position: 'relative',
  },

  clickable: {
    cursor: 'pointer',
  },
  commonIcon: {
    transform: 'translateY(0px)',
  },
  epicIcon: {
    transform: 'translateY(1px)',
  },
  rareIcon: {
    transform: 'translateY(-1px)',
  },
  legendaryIcon: {
    transform: 'translateY(0px) translateX(0px)',
  },
  modal: {
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: '75px',
      fontSize: '24px',
    },
    smallHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: '38px',
      fontSize: '18px',
      padding: 0,
    },
    normalModalCardStyle: {
      fontSize: '30px',
      height: '100%',
      paddingRight: '8px',
      paddingBottom: '8px',
      paddingTop: '0px',
    },
    responsiveModalCardStyle: {
      fontSize: '30px',
      height: 'auto',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingBottom: '8px',
      marginBottom: '4rem',
      flexShrink: 0,
    },
    score: {
      fontWeight: 700,
      paddingRight: '0.5rem',
    },
    body: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      height: 'auto',
      fontSize: '20px',
    },
    divider: {
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
    },
    dividerStack: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dividerCircle: {
      borderWidth: '2px',
      borderRadius: '32px',
      borderStyle: 'solid',
      width: '32px',
      height: '32px',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      flexGrow: 0,
    },
    traits: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      width: '100%',
      height: 'auto',
      flexWrap: 'wrap',
      marginLeft: '20px',
      marginRight: '20px',
      marginTop: '0',
    },
    traitsBody: {
      padding: '.5rem',
      marginTop: '2px',
      marginBottom: '.5rem',
    },
    traitButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      width: 'auto',
      height: 'auto',
      flexWrap: 'wrap',
      marginLeft: '10px',
      marginRight: '0px',
    },

    smallAnnotation: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontSize: '.75rem',
    },

    traitItem: {
      fontSize: '.75rem',
      flexBasis: '25%' /*default flex width, overridden below where necc.*/,
    },
    traitRarityIcon: {
      textAlign: 'left',
      flexBasis: '18px',
      flexShrink: '0',
    },
    traitGenes: {
      fontSize: '.75rem',
      flexBasis: '70%',
      fontWeight: 700,
    },
    traitGeneName: {
      textTransform: 'capitalize',
    },
    traitGeneType: {
      textTransform: 'uppercase',
    },
    traitSeries: {
      fontSize: '.675rem',
      display: 'flex',
      justifyContent: 'flex-start',
      flexBasis: '30%',
    },
    traitRarity: {
      textTransform: 'uppercase',
      textAlign: 'right',
      fontWeight: 700,
    },

    buttonBar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginRight: '0',
    },

    button: {
      margin: '5px',
    },

    finder: {
      fontFamily: 'monospace',
      fontSize: '1rem',
      padding: '.5rem 0 .5rem 0',
    },

    addToQueue: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textTransform: 'uppercase',
      textAlign: 'center',
      fontWeight: 700,
      marginTop: 'auto',
      color: '#fff',
    },
  },

  /**
   * Angled Corners
   */
  angled: {
    clipPath: `polygon(100% 0, 100% calc(100% - ${outerAngleWidth}px), calc(100% - ${outerAngleWidth}px) 100%, 0 100%, 0 ${outerAngleWidth}px, ${outerAngleWidth}px 0)`,
    transition: `background .2s ease-in-out, border-color .2s ease-in-out, opacity .2s ease-in-out`,
    boxShadow: '0 0 0 0px',
    position: 'relative',
    padding: '2px',
    border: 'none !important',
  },
  angledInner: {
    clipPath: `polygon(100% 0, 100% calc(100% - ${angleWidth}px), calc(100% - ${angleWidth}px) 100%, 0 100%, 0 ${angleWidth}px, ${angleWidth}px 0)`,
  },
  angledCardHeader: {
    clipPath: `polygon(100% 0, 100% calc(100% - 0px), calc(100% - 0px) 100%, 0 100%, 0 ${childAngleWidth}px, ${childAngleWidth}px 0)`,
  },
  angledCardFooter: {
    clipPath: `polygon(100% 0, 100% calc(100% - ${childAngleWidth}px), calc(100% - ${childAngleWidth}px) 100%, 0 100%, 0 0px, 0px 0)`,
  },
  angledImageWrap: {
    clipPath: `polygon(100% 0, 100% calc(100% - 0px), calc(100% - 0px) 100%, 0 100%, 0 ${childAngleWidth}px, ${childAngleWidth}px 0)`,
    padding: '2px',
    margin: '-2px',
    background: 'transparent',
  },
  angledImage: {
    clipPath: `polygon(100% 0, 100% calc(100% - 0px), calc(100% - 0px) 100%, 0 100%, 0 ${childAngleWidth}px, ${childAngleWidth}px 0)`,
  },
};
