import styled from 'styled-components';
import { Queue } from '@styled-icons/material-outlined/Queue';
import { Queue as QueueSelected } from '@styled-icons/material-rounded/Queue';
import { CheckCircle } from '@styled-icons/material-rounded/CheckCircle';
import { LooksOne as OneRetry } from '@styled-icons/material-rounded/LooksOne';
import { LooksTwo as TwoRetry } from '@styled-icons/material-rounded/LooksTwo';
import { Looks3 as ThreeRetry } from '@styled-icons/material-rounded/Looks3';
import { Error as ErrorCircle } from '@styled-icons/material-rounded/Error';
import { FilterCircle as FilterHide } from '@styled-icons/bootstrap/FilterCircle';
import { FilterCircleFill as FilterShow } from '@styled-icons/bootstrap/FilterCircleFill';
import { StarRate as Star } from '@styled-icons/material-rounded/StarRate';
import { SettingsEthernet } from '@styled-icons/material-outlined/SettingsEthernet';
import { MoreHoriz as Ellipsis } from '@styled-icons/material-rounded/MoreHoriz';
import { Moon } from '@styled-icons/boxicons-solid';
import { Sun } from '@styled-icons/boxicons-solid';
import { Twitter } from '@styled-icons/bootstrap/Twitter';
import { Discord } from '@styled-icons/bootstrap/Discord';

// arrow_drop_down
export const QueueIcon = styled(Queue)`
  width: 20px;
  height: 20px;
`;

export const QueueSelectedIcon = styled(QueueSelected)`
  width: 20px;
  height: 20px;
`;

export const StarIcon = styled(Star)`
  width: 25px;
  height: 25px;
`;

export const ShowFilterIcon = styled(FilterShow)`
  width: 25px;
  height: 25px;
  cursor: pointer;
`;

export const HideFilterIcon = styled(FilterHide)`
  width: 25px;
  height: 25px;
  cursor: pointer;
`;

export const ProgressIcon = styled(SettingsEthernet)`
  width: 35px;
  height: 35px;
`;

export const CheckIcon = styled(CheckCircle)`
  width: 35px;
  height: 35px;
`;

export const ErrorIcon = styled(ErrorCircle)`
  width: 35px;
  height: 35px;
`;

export const OneRetryIcon = styled(OneRetry)`
  width: 35px;
  height: 35px;
`;

export const TwoRetryIcon = styled(TwoRetry)`
  width: 35px;
  height: 35px;
`;

export const ThreeRetryIcon = styled(ThreeRetry)`
  width: 35px;
  height: 35px;
`;

export const EllipsisIcon = styled(Ellipsis)`
  width: 35px;
  height: 35px;
`;

export const MoonIcon = styled(Moon)`
  width: 18px;
  height: 18px;
  border-radius: 2rem;
`;

export const SunIcon = styled(Sun)`
  width: 18px;
  height: 18px;
  border-radius: 2rem;
`;

export const TwitterIcon = styled(Twitter)`
  width: 18px;
  height: 18px;
  color: #1da1f2;
`;

export const DiscordIcon = styled(Discord)`
  width: 22px;
  height: 22px;
`;
