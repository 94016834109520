import React from "react";
import { Box } from "grommet";

/**
 * KitHeader
 */
export const KitHeader = props => {
  const { displayMode = "desktop", withTabs=false, shortTabs=false, ...otherProps } = props;
  const top = (withTabs && displayMode === "mobile") ? shortTabs ? "115px" : "130px" : withTabs ? "110px" : "80px";
  return (
    <Box
      {...otherProps}
      fill="horizontal"
      direction={displayMode === "mobile" ?  "column" : "row"}
      justify="between"
      align={displayMode === "mobile" ?  "start" : "center"}
      pad="small"
      margin={{ bottom: "small" }}
      gap={displayMode === "mobile" ?  "small" : "none"}
      background="background-back"
      border={{
        side: "bottom",
        color: "highlight",
        size: "small",
        style: "solid"
      }}
      style={{
        position: "sticky",
        top: top,
        minHeight: "5rem",
        zIndex: 10
      }}
    />
  );
};

/**
 * KitHeaderField
 */
export const KitHeaderField = props => {
    return (
        <Box
            {...props}
            direction="row"
            justify="start"
            align="start"
            gap="small"
            style={{ fontWeight: 700, fontFamily: "sans-serif" }}
        />
    );
};
