// Media breakpoints
export const MEDIA = {
    MOBILE:  { SIZE: "mobile", QUERY: "(max-width: 411px)" },
    SMALL:   { SIZE: "small",  QUERY: "(min-width: 412px) and (max-width: 768px)" },
    TABLET:  { SIZE: "tablet", QUERY: "(min-width: 769px) and (max-width: 1024px)" },
    MEDIUM:  { SIZE: "medium", QUERY: "(min-width: 1025px) and (max-width: 1120px)" },
    LARGE:   { SIZE: "large",  QUERY: "(min-width: 1121px) and (max-width: 1439px)" },
    X_LARGE: { SIZE: "xlarge", QUERY: "(min-width: 1440px) and (max-width: 2039px)" },
    HUGE:    { SIZE: "huge",   QUERY: "(min-width: 2040px)" }
};

export const MEDIA_QUERIES = [
    MEDIA.MOBILE.QUERY,
    MEDIA.SMALL.QUERY,
    MEDIA.TABLET.QUERY,
    MEDIA.MEDIUM.QUERY,
    MEDIA.LARGE.QUERY,
    MEDIA.X_LARGE.QUERY,
    MEDIA.HUGE.QUERY
];

export const MEDIA_SIZES = [
    MEDIA.MOBILE.SIZE,
    MEDIA.SMALL.SIZE,
    MEDIA.TABLET.SIZE,
    MEDIA.MEDIUM.SIZE,
    MEDIA.LARGE.SIZE,
    MEDIA.X_LARGE.SIZE,
    MEDIA.HUGE.SIZE
];

export const AVASTAR_SIZE = {
    [MEDIA.MOBILE.SIZE]:  250, // less than 412, 1 column
    [MEDIA.SMALL.SIZE]:   300, // 412 to 768, 2 columns
    [MEDIA.TABLET.SIZE]:  150, // from 769 to 1024, 4 columns...
    [MEDIA.MEDIUM.SIZE]:  175, // from 1025 to 1120
    [MEDIA.LARGE.SIZE]:   210, // from 1125 to 1439
    [MEDIA.X_LARGE.SIZE]: 230, // from 1440 to 2039
    [MEDIA.HUGE.SIZE]:    400  // greater than 2040
};

export const THUMB_SIZE = {
    [MEDIA.MOBILE.SIZE]:  40,
    [MEDIA.SMALL.SIZE]:   40,
    [MEDIA.TABLET.SIZE]:  80,
    [MEDIA.MEDIUM.SIZE]:  80,
    [MEDIA.LARGE.SIZE]:   80,
    [MEDIA.X_LARGE.SIZE]: 120,
    [MEDIA.HUGE.SIZE]:    120
};