import React, { Component } from "react";
import styled from "styled-components";
import { Box, Button } from "grommet";

const ButtonStyles = `
    border-radius: 0;
    font-weight: 700;
    &&&&:focus {
        outline: 0;
        box-shadow: none;
    }
`;

/** In the context of styled-components, 'className' is the generated class name passed as a prop */
const TheButton = (props) => {
  const {  className, children, border, pad, plain = false } = props;
  return (
    <Button
      {...props}
      className={className}
      border={border}
      primary
      plain={plain}
      pad={pad}
    >
      <Box direction="row" pad={pad || "4px"} >
        {children}
      </Box>
    </Button>
  );
};

export const KitButton = styled(TheButton)`
  ${ButtonStyles}
`;



/**
 * SMALL BUTTON
 */
export class KitSmallButton extends Component {
  render() {
    const { displayMode = "default", ...otherProps } = this.props;
    return (
      <KitButton
        size="small"
        {...otherProps}
        displayMode={displayMode}
        border={this.props.border || "none"}
        round={false}
        pad={this.props.pad || {horizontal: "xsmall"}}
      />
    )
  }
}

/**
 * TINY BUTTON
 */
export class KitTinyButton extends Component {
  render() {
      return <KitButton
        {...this.props}
        plain
        border={this.props.border || "none"}
        round={false}
        pad="small"
        size="small"
      />
  }
}

export class KitLargeButton extends Component {
  render() {
    const { displayMode = "default", ...props } = this.props;
    return (
        <KitButton size="medium" displayMode={displayMode} {...props} border="none" />
    )
  }
}

/**
 * Icon button
 * This is a plain styled button that passes the icons, but gives space and hover effects
 * WORKAROUND NOTE: default button styles are not used, 
 * so we rebuild most of them using styled components. This allows the use of 
 * enabled props to customise presentation. Ideally these styles would be merged into
 * the styles.js but I'm unsure hoe pseudo styles (ie :hover) works in that context'
 */

const IconButtonStyles = `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 0;
  font-weight: 700;
  background: transparent;
  &&&&:focus {
      outline: 0;
      box-shadow: none;
  }

  /* 
    This query stops tablets from the annoying double tap problem. 
    background overridden in the active/enabled styles 
  */
  @media (hover: hover) {
    &:hover{
      outline: 0;
      box-shadow: none;
      background: rgba(0,0,0,.1);
    }
  }
  
  /* Unused for reference, should we need to override svg colors for theming */
  /*
  svg {
    path{
      fill: red;
      stroke: blue;
    }
  }
  */
`;
// CONDITIONAL STYLES (applied via props). 
// const activeStyles = ``  // unused at the moment - uses Button defaults 
// const inactiveStyles = `` // unused at the moment - uses Button defaults
const enabledStyles = `
  cursor: pointer;
  @media (hover: hover) {
    &:hover{
      background: rgba(0,0,0,.1);
    }
  }
`
const disabledStyles = `
  cursor: default !important; 
  background: transparent;
  @media (hover: hover) {
    &:hover{
      background: transparent;
    }
  }
`

const IconButton = (props) => {
  /** In the context of styled-components, 'className' is the generated class name passed as a prop */
  const {className, ...otherProps} = props;
  return (
    <Button
      className={className}
      plain
      {...otherProps}
    />
  );
};

export const KitIconButton = styled(IconButton)`
  ${IconButtonStyles}
  /* CONDITIONAL based on props */
  ${props => props.enabled ? enabledStyles : disabledStyles}
`;
