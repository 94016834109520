import React from "react";
import { Component } from "react";
// import styled from "styled-components";

import { Box, Heading, Paragraph } from "grommet";

/**
 * NOTE: these components pass through the grommet components for static pages,
 * in order to keep them separated the app components
 */

export class KitBox extends Component {
  render() {
    const { ...props } = this.props;
    return <Box {...props} />;
  }
}

export class KitHeading extends Component {
  render() {
    const { ...props } = this.props;
    return <Heading {...props} />;
  }
}

export class KitParagraph extends Component {
  render() {
    const { ...props } = this.props;
    return <Paragraph {...props} />;
  }
}
