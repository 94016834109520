import React from 'react';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';

import eip55 from 'eip55';

import { KitLargeButton } from '../../../theme/kit';
import { WALLET_STATUS, GAS_MULTIPLIER } from '../../../constants/wallet';
import { getInfuraIds } from '../../../environment/config';

export default function Wallet(props) {
  const {
    firebase,
    setWeb3,
    setAccount,
    setNetwork,
    desiredNetwork,
    setWalletStatus,
    setGasPrice,
  } = props;

  // Connect your wallet
  async function connectWallet() {
    let provider, net;

    try {
      // Request account access
      let accountId;

      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            infuraId: getInfuraIds(), // required
          },
        },
      };

      const web3Modal = new Web3Modal({
        cacheProvider: false,
        providerOptions,
      });

      provider = await web3Modal.connect();

      const web3 = new Web3(provider);

      const accounts = await web3.eth.getAccounts();

      accountId = setEncodedAccount(accounts[0]);

      // do custom Auth
      const token = await firebase.getCustomToken(`${accountId}`);
      await firebase.signInCustom(token.data);

      const gasPrice = await web3.eth.getGasPrice();
      setGasPrice(Number(gasPrice) * GAS_MULTIPLIER);

      // Have user sign a message to prove they own the account
      const message =
        'For your security, signing this message proves you own the address.';
      const signature = await web3.eth.personal.sign(
        message,
        accountId,
        new Date().toDateString()
      );

      const signer = await web3.eth.personal.ecRecover(message, signature);

      if (signer.toLowerCase() !== accountId.toLowerCase())
        throw new Error('Invalid message signature.');

      // Accept the provider and account
      setWeb3(web3);

      // Metamask, deprecated but still important
      if (provider.hasOwnProperty('autoRefreshOnNetworkChange')) {
        provider.autoRefreshOnNetworkChange = false;
      }

      // Get network
      // Dapper only connects to one network, hope it's the right one
      net = provider.hasOwnProperty('networkVersion')
        ? provider['networkVersion']
        : desiredNetwork.id;
      setConnectedNetwork(net);

      // EIP-1193 events
      // Not supported by Dapper
      if (!!provider.on) {
        // Listen for change of account
        provider.on('accountsChanged', (accts) => {
          console.log('accountsChanged');
          setEncodedAccount(accts[0]);
        });

        // Listen for change of network
        // deprecated but still works on Metamask
        provider.on('networkChanged', (net) => {
          console.log('networkChanged');
          setConnectedNetwork(net);
        });

        // Listen for change of chain   // new standard
        // new standard but doesn't yet work on Metamask
        // https://eips.ethereum.org/EIPS/eip-1193#chainchanged
        provider.on('chainChanged', (net) => {
          console.log('chainChanged');
          setConnectedNetwork(net);
        });
      }
    } catch (error) {
      console.log(error);

      // User denied account access...
      setWalletStatus(WALLET_STATUS.denied);
    }
  }

  function setEncodedAccount(acct) {
    const accountId = eip55.encode(acct);
    setAccount(accountId);

    return accountId;
  }

  // Set the connected network
  function setConnectedNetwork(net) {
    const connectedNetwork = Number(net);

    setWalletStatus(
      connectedNetwork !== desiredNetwork.id
        ? WALLET_STATUS.wrong
        : WALLET_STATUS.connected
    );

    switch (connectedNetwork) {
      case 1:
        setNetwork('Ethereum Main Network');
        break;
      case 2:
        setNetwork('Morden Test Network');
        break;
      case 3:
        setNetwork('Ropsten Test Network');
        break;
      case 4:
        setNetwork('Rinkeby Test Network');
        break;
      case 5:
        setNetwork('Goerli Test Network');
        break;
      case 42:
        setNetwork('Kovan Test Network');
        break;
      default:
        setNetwork('Private network');
    }
  }

  // Render the button
  return (
    <KitLargeButton onClick={connectWallet}>Connect Wallet</KitLargeButton>
  );
}
