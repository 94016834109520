import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/functions';

import { SETTLEMENT_STATUS } from '../constants/marketplace';
import ListedAvastar from '../domain/entity/ListedAvastar';
import { getFirebaseConfig } from './config';
const config = getFirebaseConfig();

class Firebase {
  constructor() {
    this.app = firebase.initializeApp(config);

    // if you are using emulators (firebase emulators:start) for the functions,
    // this will make sure we hit the local functions,
    // and not try to hit the remote functions.
    // if (window.location.host.split('.')[0] === 'localhost:3000') {
    //   firebase.functions().useEmulator('localhost', 5001);
    // }
  }

  /**
   * Listen for authentication changes
   * @param {Function} callback
   * @returns {firebase.Unsubscribe}
   */
  onAuthChange = (callback) => firebase.auth().onAuthStateChanged(callback);

  /**
   * Sign in with custom token
   * @param {String} token
   * @returns {Promise<firebase.auth.UserCredential>}
   */
  signInCustom = async (token) =>
    firebase.auth().signInWithCustomToken(String(token));

  /**
   * Get custom Firebase auth token
   * @param {String} accountId Metamask ID
   * @returns {Promise<firebase.functions.HttpsCallableResult>}
   */
  getCustomToken = async (accountId) =>
    firebase.functions().httpsCallable('customAuthToken')({ accountId });

  /**
   * Fetch the stored genes from the database
   * @param {String} generation
   * @returns {Promise<Object[]>} traits
   */
  fetchStoredTraits = async (generation) => {
    const { data: traits } = await firebase
      .functions()
      .httpsCallable('getStoredTraits')({ generation });

    return Object.values(traits);
  };

  /**
   * Fetch the control info
   * @returns {Promise<firebase.functions.HttpsCallableResult>}
   */
  fetchControlInfo = async () =>
    firebase.functions().httpsCallable('getControlInfo')();

  /**
   * Check if an Avastar is unique (not on-chain)
   * @param {Object} avastar
   * @returns {Promise<firebase.functions.HttpsCallableResult>}
   */
  isUnique = async (avastar) => {
    const request = {
      generation: avastar.generation,
      hash: avastar.getHashString(),
    };

    return firebase.functions().httpsCallable('isUnique')(request);
  };

  /**
   * Get the desired Ethereum network
   * @returns {Promise<firebase.functions.HttpsCallableResult>}
   */
  getNetwork = async () => firebase.functions().httpsCallable('getNetwork')();

  /**
   * Queue an Avastar for the selected wallet address
   * @param {String} id
   * @param {Object} avastar
   * @returns {Promise<firebase.functions.HttpsCallableResult>}
   */
  queueAvastar = async (id, avastar) =>
    firebase.functions().httpsCallable('queueAvastar')({ id, avastar });

  /**
   * Monitor queued Avastar for status updates
   * @param {String} id
   * @param {Function} callback
   * @returns {Unknown} callback value
   */
  monitorQueue = (id, callback) => {
    firebase
      .database()
      .ref('queue')
      .child(id)
      .on('value', (snapshot) => callback(snapshot.val()));
  };

  /**
   * Remove queued Avastar and stop listening for status updates
   * @param {String} id
   * @returns {Promise<firebase.functions.HttpsCallableResult>}
   */
  removeQueue = async (id) => {
    firebase.database().ref('queue').child(id).off();

    return firebase.functions().httpsCallable('removeQueue')({ id });
  };

  /**
   * Get a list of the user's Token Ids and their deposit balance
   * @param {String} owner
   * @returns {Promise<Object|Void>} profile data
   */
  fetchProfile = async (owner) => {
    try {
      const { data } = await firebase.functions().httpsCallable('getProfile')({
        owner,
      });

      return data;
    } catch (e) {
      console.error(e.message);
    }
  };

  /**
   * Monitor deposit balance changes
   * @param {String} address
   * @param {Function} callback
   * @returns {Unknown} callback value
   */
  monitorDepositorBalance = (address, callback) => {
    firebase
      .database()
      .ref('depositors')
      .child(address)
      .on('value', (snapshot) => callback(snapshot.val()));
  };

  /**
   * Stop monitoring deposit balance changes for a current wallet address
   * @param {String} address
   * @returns {Void}
   */
  unMonitorDepositorBalance = (address) => {
    firebase.database().ref('depositors').child(address).off();
  };

  /**
   * Monitor wishlist for updates (keeps multiple browser tabs in sync)
   * @param {String} address
   * @param {Function} callback
   * @returns {Unknown} callback value
   */
  monitorWishlist = (address, callback) => {
    firebase
      .database()
      .ref('lists/user/wishlist')
      .child(address)
      .on('value', (snapshot) => {
        let items = [];
        snapshot.forEach((item) => {
          const listed = ListedAvastar.fromObject(item.val());
          items.push(listed);
        });
        return callback(items);
      });
  };

  /**
   * Stop monitoring wishlist for the current wallet address
   * @param {String} address
   * @returns {Void}
   */
  unMonitorWishlist = (address) => {
    firebase.database().ref('lists/user/wishlist').child(address).off();
  };

  /**
   * Add an Avastar to the wish list for the current wallet address
   * @param {String} address
   * @param {Object} listed
   * @returns {Promise<firebase.functions.HttpsCallableResult>}
   */
  addToWishlist = async (address, listed) =>
    firebase.functions().httpsCallable('addToWishlist')({
      address,
      listed,
    });

  /**
   * Remove Avastar from wish list for the current wallet address
   * @param {String} address
   * @param {Object} listed
   * @returns {Promise<firebase.functions.HttpsCallableResult>}
   */
  removeFromWishlist = async (address, listed) =>
    firebase.functions().httpsCallable('removeFromWishlist')({
      address,
      listed,
    });

  /**
   * Monitor consigned list for updates (keeps multiple browser tabs in sync)
   * @param {String} address
   * @param {Function} callback
   * @returns {Unknown} callback value
   */
  monitorConsigned = (address, callback) => {
    firebase
      .database()
      .ref('lists/user/consigned')
      .child(address)
      .on('value', (snapshot) => {
        let items = [];
        snapshot.forEach((item) => {
          const listed = ListedAvastar.fromObject(item.val());
          items.push(listed);
        });
        return callback(items);
      });
  };

  /**
   * Stop monitoring consigned list for a current wallet address
   * @param {String} address
   * @returns {Void}
   */
  unMonitorConsigned = (address) => {
    firebase.database().ref('lists/user/consigned').child(address).off();
  };

  /**
   * Add an Avastar to the consigned list for the current wallet address
   * @param {String} address
   * @param {Object} listed
   * @returns {Promise<firebase.functions.HttpsCallableResult>}
   */
  addToConsigned = async (address, listed) =>
    firebase.functions().httpsCallable('addToConsigned')({
      address,
      listed,
    });

  /**
   * Remove Avastar from consigned list for the current wallet address
   * @param {String} address
   * @param {Object} listed
   * @returns {Promise<firebase.functions.HttpsCallableResult>}
   */
  removeFromConsigned = async (address, listed) =>
    firebase.functions().httpsCallable('removeFromConsigned')({
      address,
      listed,
    });

  /**
   * Get a page of the global consignment feed
   * @param {Number} page
   * @param {Number} pageSize
   * @returns {Promise<firebase.functions.HttpsCallableResult|Void>} with modified value for listedAvastars
   */
  fetchGlobalFeedPage = async (page = 1, pageSize = 64) => {
    try {
      // Get the next page of the global consignments list
      const { data } = await firebase.functions().httpsCallable('getConsigned')(
        { page, pageSize }
      );

      // Marshal ListedAvastars
      const items = [];
      data.listedAvastars.length &&
        data.listedAvastars.forEach((item, index) => {
          const listed = ListedAvastar.fromObject(item);
          listed.avastar.serial = (page - 1) * pageSize + index;
          items.push(listed);
        });
      data.listedAvastars = items;

      return data;
    } catch (e) {
      console.error(e.message);
    }
  };

  /**
   * Get all the open consignments for the given finder
   * @param {String} finder Metamask ID
   * @returns {Promise<firebase.functions.HttpsCallableResult|Void>} with modified value for listedAvastars
   */
  fetchGlobalFeedFiltered = async (finder = null) => {
    try {
      // Get the finder's open items on the global consignments list
      const { data } = await firebase.functions().httpsCallable('getConsigned')(
        { finder }
      );

      // Marshal ListedAvastars
      const items = [];
      data.listedAvastars.length &&
        data.listedAvastars.forEach((item, index) => {
          const listed = ListedAvastar.fromObject(item);
          listed.avastar.serial = index;
          items.push(listed);
        });
      data.listedAvastars = items;

      return data;
    } catch (e) {
      console.error(e.message);
    }
  };

  /**
   * Settle Consignments for Connected Wallet
   * @param {String} finder Metamask ID
   * @param {String|Number} expected expected cost
   * @returns {Promise<firebase.functions.HttpsCallableResult>}
   */
  settleConsignments = async (finder, expected) => {
    const status = SETTLEMENT_STATUS.SETTLING;
    return firebase.functions().httpsCallable('settleConsignment')({
      finder,
      expected,
      status,
    });
  };

  /**
   * Monitor settlement request for status updates
   * @param {String} finder Metamask ID
   * @param {Function} callback
   * @returns {Unknown} callback value
   */
  monitorSettlement = (finder, callback) => {
    firebase
      .database()
      .ref('settle')
      .child(finder)
      .on('value', (snapshot) => callback(snapshot.val()));
  };

  /**
   * Stop monitoring settlement request for status updates
   * @param {String} finder Metamask ID
   * @returns {Void}
   */
  unMonitorSettlement = (finder) => {
    firebase.database().ref('settle').child(finder).off();
  };

  /**
   * Monitor maintenance mode
   * @param {Function} callback
   * @returns {Unknown} callback value
   */
  monitorMaintenanceMode = (callback) => {
    firebase
      .database()
      .ref('control/maintenance/active')
      .on('value', (snapshot) => callback(snapshot.val()));
  };
}

export default Firebase;
