import React from "react";
import styled from "styled-components";
import {DARK, LIGHT} from "../../theme/overrides";
import {Box, Button, ThemeContext} from "grommet";
import {MoonIcon, SunIcon} from "../../theme/kit";
import {KitButtonGroup, VerticalKitButtonGroup} from "../../theme/kit";

/**
 * Uses first/last child selectors to handle border overrides, dependant on selected button. 
 */
const switchStyles = `
    position: absolute;
    button{
      padding: 0.125rem;
      outline: none;
      border: 2px solid rgba(255,255,255,.4);
      box-shadow: none;
      &:first-child{
        border-color: rgba(0,0,0,.2);
      }
      &:last-child{
        border-color: rgba(255,255,255,.3);
      }
    }
  `;

const Switch = props => {
  const { vertical=false, setThemeStyle } = props;
  return (
      <ThemeContext.Consumer>
      {(theme) => {
          const backgroundColor =
              theme.dark
                  ? theme.global.colors["background-back"].dark
                  : theme.global.colors["background-back"].light;

          const textColor =
              theme.dark
                  ? theme.global.colors["secondary"].dark
                  : theme.global.colors["secondary"].light;

          const normalStyles = {backgroundColor: textColor, borderColor:textColor, color: backgroundColor, cursor: "pointer"};
          const selectedStyles = {backgroundColor: backgroundColor, borderColor:textColor,  color: textColor, cursor: "auto"};

          return <Box justify="center" align="center" direction="column" basis="100%" {...props}>
              {vertical
                  ? <VerticalKitButtonGroup size="small">
                      <Button
                          style={theme.dark ? selectedStyles : normalStyles}
                          onClick={() => setThemeStyle(DARK)}
                          size="small"
                          icon={<MoonIcon  style={theme.dark ? selectedStyles : normalStyles}/>}
                      />
                      <Button
                          onClick={() => setThemeStyle(LIGHT)}
                          style={theme.dark ? normalStyles : selectedStyles}
                          size="small"
                          icon={<SunIcon style={theme.dark ? normalStyles : selectedStyles}/>}
                      />
                    </VerticalKitButtonGroup>
                  : <KitButtonGroup size="small">
                      <Button
                          style={theme.dark ? selectedStyles : normalStyles}
                          onClick={() => setThemeStyle(DARK)}
                          size="small"
                          icon={<MoonIcon  style={theme.dark ? selectedStyles : normalStyles}/>}
                      />
                      <Button
                          onClick={() => setThemeStyle(LIGHT)}
                          style={theme.dark ? normalStyles : selectedStyles}
                          size="small"
                          icon={<SunIcon style={theme.dark ? normalStyles : selectedStyles}/>}
                      />
                    </KitButtonGroup>}
          </Box>
      }}
      </ThemeContext.Consumer>
  );
};

export const ThemeSwitch = styled(Switch)`
  ${switchStyles}
`;
