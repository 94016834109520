export const DARK = "themeDark";
export const LIGHT = "themeLight";
export const OVERRIDES =
    {
        [DARK]: {
            "global": {
                "hover": {
                    "background": "#0D8FFB",
                    "color": "#160539"
                }
            },
            "anchor": {
                "extend": {
                    ":hover": {
                        "color": "#f9f9f9"
                    }
                }
            }
        },
        [LIGHT]: {
            "global": {
                "hover": {
                    "background": "#5B19E5",
                    "color": "#f9f9f9"
                }
            },
            "anchor": {
                "extend": {
                    ":hover": {
                        "color": "#160539"
                    }
                }
            }
        }
    };