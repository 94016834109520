import React, { useRef, useState } from 'react';
import withQueryParams from 'react-router-query-params';
import isMobile from 'ismobilejs';
import { usePrevious } from '../../../hooks';
import eip55 from 'eip55';
import { ThemeContext } from 'grommet';
import { Animated } from 'react-animated-css';
import InfiniteScroll from 'react-infinite-scroller';
import { marketplace as styles } from '../../../theme/styles';
import { DISCOVER_FEEDS } from '../../../constants/marketplace';
import { QUERY_CONFIG } from '../../../constants/query';
import { MEDIA } from '../../../constants/media';
import Generator from './Generator';
import Consignments from './Consignments';
import AvastarCard from '../Avastar/AvastarCard';
import ListedAvastar from '../../../domain/entity/ListedAvastar';
import {
  KitBox,
  KitHeading,
  KitParagraph,
  KitSmallButton,
  KitLoading,
  StarIcon,
  ShowFilterIcon,
  HideFilterIcon,
  KitIconButton,
  KitSeparator,
} from '../../../theme/kit';
import * as deepfreeze from 'deepfreeze';

const Discover = (props) => {
  // State from props
  const {
    viewedAvastar,
    setViewedAvastar,
    setSelectedApplicant,
    onCriticalEvent,
    mediaSize,
    isInTeleporter,
    isInConsigned,
    getPrice,
    consigned,
    account,
    queryParams,
    setQueryParams,
    allowGenerate,
    setAllowGenerate,
  } = props;

  // Local state
  const [tab, setTab] = useState(
    queryParams && queryParams.finder
      ? DISCOVER_FEEDS.GLOBAL
      : DISCOVER_FEEDS.LOCAL
  );
  const [localPageBuffer, setLocalPageBuffer] = useState([]);
  const [globalPageBuffer, setGlobalPageBuffer] = useState([]);
  const [serialBase, setSerialBase] = useState(0);
  const [feedHasMore, setFeedHasMore] = useState(true);
  const [nextPage, setNextPage] = useState(1);
  const [allFinders, setAllFinders] = useState([]);
  const [showFilter, setShowFilter] = useState(
    queryParams && queryParams.finder
  );
  const [globalFilter, setGlobalFilter] = useState(
    queryParams && queryParams.finder ? eip55.encode(queryParams.finder) : null
  );

  const previousTab = usePrevious(tab);

  // check if device is a mobile device - this is used to improve performance
  let isMobileDevice;
  try {
    isMobileDevice =
      typeof window !== 'undefined' ? isMobile(window.navigator).any : false;
  } catch {
    isMobileDevice = false;
  }
  const divider = isMobileDevice ? 2 : 1;

  // only allow generation of a page of avastars every minute
  const generationThrottle = deepfreeze({ value: 30000 / divider });

  // Refs for accessing functions exposed on child components
  const generatorRef = useRef();
  const consignmentsRef = useRef();

  // Number of Avastars per page
  const pageSize = 64 / divider;

  // # of pixels before bottom that triggers batch creation
  const threshold = 0;

  // (Used by feeds)
  // Add a set of ListedAvastars to the given page buffer, enforcing buffer size
  function bufferAvastars(listedAvastars, pageBuffer, bufferSize = 4) {
    // Build pages
    const newCards = renderCards(listedAvastars);
    const newPages = renderPages(newCards, pageBuffer);

    // Truncate scroll history by turning the first {bufferSize} number of cards
    // into zombie cards with no avastars in them.
    let population = [...pageBuffer, ...newPages];
    if (population.length > bufferSize) {
      let zombies = gutPages(
        population.slice(0, population.length - bufferSize)
      );
      let living = population.splice(population.length - bufferSize);
      population = [...zombies, ...living];
    }
    return population;

    // (Used by feeds)
    // Render an array of Avastar components
    function renderCards(avastars) {
      return avastars.map((listed) => (
        <div key={listed.avastar.serial} style={styles.cardWrapper}>
          <AvastarCard
            inTeleporter={isInTeleporter(listed)}
            inConsigned={isInConsigned(listed)}
            onCriticalEvent={onCriticalEvent}
            setSelectedApplicant={setSelectedApplicant}
            viewedAvastar={viewedAvastar}
            setViewedAvastar={setViewedAvastar}
            listed={listed}
            getPrice={getPrice}
            mediaSize={mediaSize}
          />
        </div>
      ));
    }

    // (Used by feeds)
    // Render pages of Avastar cards
    function renderPages(cards, buffer) {
      const renderPage = (key, cards) => (
        <div key={key} style={styles.scrollerPage}>
          {cards}
        </div>
      );

      let newPages = [];
      let max = cards.length / pageSize;
      for (let i = 0; i < max; i++) {
        const start = i * pageSize;
        const end = start + pageSize;
        const key = String(buffer.length + i);
        newPages.push(renderPage(key, cards.slice(start, end)));
      }
      return newPages;
    }

    // (Used by feeds)
    // Remove Avastars from pages outside the scrollback buffer
    function gutPages(zombies) {
      let emptyPages = zombies.map((page) => <div key={page.key}></div>);
      return emptyPages;
    }
  }

  // Render the the header copy
  function renderCopy() {
    return (
      <>
        <KitHeading level="1" color="secondary">
          Applicant Feeds
        </KitHeading>
        <KitBox>
          <KitParagraph fill={true}>
            Time to get scrolling, teleporter engineer. If any Applicants catch
            your eye, add them to your queue -- you may lose great prospects
            forever otherwise!
          </KitParagraph>
          <KitParagraph fill={true}>
            In the <b>Local Feed</b>, you'll discover one-of-a-kind Avastars
            that are randomly generated in your browser.
          </KitParagraph>
          <KitParagraph fill={true}>
            In the <b>Global Feed</b>, you'll find Avastars that have been
            generated and consigned to the feed by other teleporter engineers.
            These hand-picked, quality Applicants come with a finder's fee, so
            they are a tad more expensive.
          </KitParagraph>
        </KitBox>
      </>
    );
  }

  // Render the tabs
  function renderTabs() {
    return (
      <ThemeContext.Consumer>
        {(theme) => {
          const backgroundColor = theme.dark
            ? theme.global.colors['background-back'].dark
            : theme.global.colors['background-back'].light;

          const selectedColor = theme.dark
            ? theme.global.colors['secondary'].dark
            : theme.global.colors['secondary'].light;

          const divColor = theme.dark
            ? theme.global.colors['highlight'].dark
            : theme.global.colors['highlight'].light;

          const selectedTabStyles = {
            ...styles.tabSelected,
            backgroundColor: backgroundColor,
            color: selectedColor,
          };

          let localFeedStyles = { ...styles.tabs, ...styles.tabLeft };
          if (tab === DISCOVER_FEEDS.LOCAL)
            localFeedStyles = { ...localFeedStyles, ...selectedTabStyles };

          let globalFeedStyles = { ...styles.tabs, ...styles.tabRight };
          if (tab === DISCOVER_FEEDS.GLOBAL)
            globalFeedStyles = { ...globalFeedStyles, ...selectedTabStyles };

          const tabBarStyles = {
            ...styles.tabBar,
            backgroundColor,
            borderBottom: `2px solid ${divColor}`,
          };

          const hasConsignments =
            account &&
            consigned.filter((c) => c.status === ListedAvastar.STATUS.CONSIGNED)
              .length > 0 &&
            tab === DISCOVER_FEEDS.GLOBAL;

          return (
            <>
              <div style={tabBarStyles}>
                <div style={styles.tabBarGroup}>
                  <KitSmallButton
                    style={localFeedStyles}
                    onClick={() => setTab(DISCOVER_FEEDS.LOCAL)}
                  >
                    {DISCOVER_FEEDS.LOCAL}
                  </KitSmallButton>
                  <KitSmallButton
                    style={globalFeedStyles}
                    onClick={() => setTab(DISCOVER_FEEDS.GLOBAL)}
                  >
                    {DISCOVER_FEEDS.GLOBAL}
                  </KitSmallButton>
                </div>
                <div style={styles.tabBarGroup}>
                  {hasConsignments && mediaSize !== MEDIA.MOBILE.SIZE ? (
                    <Animated
                      animationIn="fadeInLeft"
                      animateOnMount={true}
                      animationInDuration={325}
                      animationInDelay={162}
                    >
                      <KitIconButton
                        fill
                        full
                        color="secondary"
                        enabled={false}
                      >
                        <StarIcon style={styles.avastarQueue} /> = yours{' '}
                        {globalFilter || allFinders.length > 0 ? (
                          <KitSeparator />
                        ) : null}
                      </KitIconButton>
                    </Animated>
                  ) : null}
                  {tab === DISCOVER_FEEDS.GLOBAL &&
                  (globalFilter || allFinders.length > 0) ? (
                    showFilter ? (
                      <Animated
                        animationIn="bounceIn"
                        animateOnMount={true}
                        animationInDuration={325}
                        animationInDelay={325}
                      >
                        <KitIconButton
                          onClick={() => setShowFilter(false)}
                          fill
                          full
                          color="secondary"
                        >
                          <HideFilterIcon style={styles.avastarQueue} />
                        </KitIconButton>
                      </Animated>
                    ) : (
                      <Animated
                        animationIn="bounceIn"
                        animateOnMount={true}
                        animationInDuration={325}
                        animationInDelay={325}
                      >
                        <KitIconButton
                          onClick={() => setShowFilter(true)}
                          fill
                          full
                          color="secondary"
                        >
                          <ShowFilterIcon style={styles.avastarQueue} />
                        </KitIconButton>
                      </Animated>
                    )
                  ) : null}
                </div>
              </div>
            </>
          );
        }}
      </ThemeContext.Consumer>
    );
  }

  // Render the Feed
  function renderFeed() {
    let feed;

    switch (tab) {
      case DISCOVER_FEEDS.LOCAL:
        feed = (
          <Generator
            {...props}
            pageSize={pageSize}
            bufferAvastars={bufferAvastars}
            pageBuffer={localPageBuffer}
            setPageBuffer={setLocalPageBuffer}
            serialBase={serialBase}
            setSerialBase={setSerialBase}
            transition={!previousTab ? 'fadeIn' : 'slideInLeft'}
            ref={generatorRef}
            allowGenerate={allowGenerate}
            setAllowGenerate={setAllowGenerate}
            generationThrottle={generationThrottle}
            getMoreAvastars={getMoreAvastars}
            startTimer={startTimer}
          />
        );

        break;

      case DISCOVER_FEEDS.GLOBAL:
        feed = (
          <Consignments
            {...props}
            pageSize={pageSize}
            bufferAvastars={bufferAvastars}
            nextPage={nextPage}
            setNextPage={setNextPage}
            setFeedHasMore={setFeedHasMore}
            pageBuffer={globalPageBuffer}
            setPageBuffer={setGlobalPageBuffer}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            globalFilter={globalFilter}
            allFinders={allFinders}
            setAllFinders={setAllFinders}
            setFilter={setFilter}
            clearFilter={clearFilter}
            transition={!previousTab ? 'fadeIn' : 'slideInRight'}
            ref={consignmentsRef}
          />
        );
        break;

      default:
        feed = null;
    }

    return feed;
  }

  // Render the loading spinner
  function renderLoader() {
    const text =
      tab === DISCOVER_FEEDS.LOCAL
        ? allowGenerate
          ? 'Generating Avastars...'
          : 'Generation Throttle...'
        : 'Accessing Global Feed...';
    return <KitLoading text={text} key={0}></KitLoading>;
  }

  // Get more Avastars from the selected feed
  function getMoreAvastars() {
    tab === DISCOVER_FEEDS.LOCAL
      ? generatorRef.current.generateMoreAvastars()
      : !!globalFilter
      ? consignmentsRef.current['loadFilteredConsignments']()
      : hasMore() && consignmentsRef.current['loadMoreConsignments']();
  }

  // start the timer after the first generation call, only allowing it to run again once the time completes
  function startTimer() {
    setAllowGenerate(false);
    timeout.run();
  }

  const timeout = deepfreeze({
    run: () =>
      setTimeout(() => {
        setAllowGenerate(true);
        generatorRef.current ? getMoreAvastars() : renderFeed();
      }, generationThrottle.value),
  });

  // Are there more Avastars in the selected feed?
  function hasMore() {
    return tab === DISCOVER_FEEDS.LOCAL ? true : feedHasMore;
  }

  // Should the scroller request an initial load from the selected feed?
  function performInitialLoad() {
    return tab === DISCOVER_FEEDS.LOCAL
      ? localPageBuffer.length === 0
      : globalPageBuffer.length === 0 || hasMore();
  }

  function clearFilter() {
    setGlobalPageBuffer([]);
    setGlobalFilter(null);
    setFeedHasMore(true);
    setShowFilter(false);
    setQueryParams({ finder: undefined });
  }

  function setFilter(finder) {
    setGlobalPageBuffer([]);
    setGlobalFilter(finder);
    setFeedHasMore(true);
    setShowFilter(true);
    setQueryParams({ finder });
  }

  // Render Feed
  return (
    <Animated
      animationIn="fadeIn"
      animateOnMount={true}
      animationInDuration={325}
      animationInDelay={0}
    >
      <InfiniteScroll
        style={styles.page}
        pageStart={0}
        initialLoad={performInitialLoad()}
        loadMore={getMoreAvastars}
        hasMore={hasMore()}
        loader={renderLoader()}
        threshold={threshold}
        getScrollParent={() => document.getElementById('app')}
        useCapture={false}
        useWindow={false}
      >
        {renderCopy()}
        {renderTabs()}
        {renderFeed()}
      </InfiniteScroll>
    </Animated>
  );
};

export default withQueryParams(QUERY_CONFIG)(Discover);
