import {useState, useCallback, forwardRef, useImperativeHandle} from "react";
import {useEventListener} from "../../hooks";

// Component used to detect bots
// Tracks authentic user activity between critical events
const BladeRunner = forwardRef(
    (props, ref) => {

    // State from props
    const {
        onRobotDetected
    } = props;

    const [activitySinceLastCritical, setActivitySinceLastCritical] = useState([]);

    // Expose onCriticalEvent function to parent
    useImperativeHandle(ref, () => ({

        handleCriticalEvent() {
            const actions = activitySinceLastCritical.length;
            if (actions === 0) {
                onRobotDetected();
            } else {
                setActivitySinceLastCritical([]);
            }
        }
    }));

    // Event handler utilizing useCallback ...
    // ... so that reference never changes.
    const handlePointerOver = useCallback(
        ({ clientX, clientY, timeStamp, isTrusted }) => {
            if (isTrusted) {
                const activity = {x: clientX, y: clientY, time: timeStamp};
                const activities = activitySinceLastCritical.concat([activity]);
                setActivitySinceLastCritical(activities);
            } else {
                onRobotDetected();
            }
        },
        [activitySinceLastCritical, setActivitySinceLastCritical, onRobotDetected]
    );

    // Add event listener using our hook
    useEventListener('pointerover', handlePointerOver);

    return null;
});

export default BladeRunner;