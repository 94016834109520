import React, { useEffect, useState } from 'react';
import { Animated } from 'react-animated-css';
import { usePrevious } from '../../../hooks';
import { marketplace as styles } from '../../../theme/styles';
import { WALLET_STATUS } from '../../../constants/wallet';
import { MEDIA } from '../../../constants/media';
import {
  QUEUE_LISTS,
  CONSIGNED_LISTS,
  SETTLEMENT_STATUS,
} from '../../../constants/marketplace';
import ListedAvastar from '../../../domain/entity/ListedAvastar';
import AvastarRow from '../Avastar/AvastarRow';
import {
  KitHeaderField,
  KitList,
  KitRow,
  KitColumn,
  KitHeader,
  KitHeading,
  KitMenu,
  KitLargeButton,
  KitLoading,
} from '../../../theme/kit';

export default function Consigned(props) {
  // State from props
  const {
    firebase,
    account,
    network,
    desiredNetName,
    desiredNetwork,
    walletStatus,
    consigned,
    truncatedAccount,
    smallDisplay,
    calculateTotalFees,
    unsoldCount,
    soldCount,
    settledCount,
    expiredCount,
    mediaSize,
    gasPrice,
  } = props;

  // Local state
  const [filter, setFilter] = useState(CONSIGNED_LISTS.UNSOLD);
  const [filteredList, setFilteredList] = useState([]);
  const [settling, setSettling] = useState(false);
  const [settleResult, setSettledResult] = useState(null);
  const showFilter = consigned.length > unsoldCount;
  const prevAccount = usePrevious(account);

  // Set tab to the first tab with content when any list changes
  useEffect(() => {
    if (!currentFilterHasContent()) setFilter(getFirstFilterWithContent());
    // eslint-disable-next-line
  }, [consigned, setFilter]);

  useEffect(() => {
    setFilteredList(
      consigned.filter((listed) => listed.status === filter.STATUS)
    );
    // eslint-disable-next-line
  }, [filter, consigned, setFilteredList]);

  // When settling changes to true, settle consignments
  useEffect(() => {
    if (settling)
      firebase
        .settleConsignments(account, calculateTotalFees(filteredList))
        .then(() =>
          firebase.monitorSettlement(account, (result) => {
            if (result.status !== SETTLEMENT_STATUS.SETTLING) {
              firebase.unMonitorSettlement(account);
              setSettling(false);
            }
            setSettledResult(result);
          })
        );
    // eslint-disable-next-line
  }, [settling]);

  // When account changes, reset settling and setSettledResult
  useEffect(() => {
    if (!!prevAccount) {
      setSettling(false);
      setSettledResult(null);
      setFilter(CONSIGNED_LISTS.UNSOLD);
    }
    // eslint-disable-next-line
  }, [account]);

  // Does the current filter have content?
  function currentFilterHasContent() {
    let result;
    switch (filter) {
      case CONSIGNED_LISTS.UNSOLD.STATUS:
        result = unsoldCount;
        break;
      case CONSIGNED_LISTS.SOLD.STATUS:
        result = soldCount;
        break;
      case CONSIGNED_LISTS.SETTLED.STATUS:
        result = settledCount;
        break;
      case CONSIGNED_LISTS.SETTLED.EXPIRED:
        result = expiredCount;
        break;
      default:
    }
    return result;
  }

  // Find the first tab with content
  function getFirstFilterWithContent() {
    return !!settleResult && settleResult.status === SETTLEMENT_STATUS.SETTLED
      ? CONSIGNED_LISTS.SETTLED
      : !!unsoldCount
      ? CONSIGNED_LISTS.UNSOLD
      : !!expiredCount
      ? CONSIGNED_LISTS.EXPIRED
      : !!soldCount
      ? CONSIGNED_LISTS.SOLD
      : !!settledCount
      ? CONSIGNED_LISTS.SETTLED
      : null;
  }

  // Render the filter menu
  const renderFilter = () => {
    let items = [];
    if (unsoldCount)
      items.push({
        label: `${CONSIGNED_LISTS.UNSOLD.NAME} (${unsoldCount})`,
        onClick: () => setFilter(CONSIGNED_LISTS.UNSOLD),
      });
    if (expiredCount)
      items.push({
        label: `${CONSIGNED_LISTS.EXPIRED.NAME} (${expiredCount})`,
        onClick: () => setFilter(CONSIGNED_LISTS.EXPIRED),
      });
    if (soldCount)
      items.push({
        label: `${CONSIGNED_LISTS.SOLD.NAME} (${soldCount})`,
        onClick: () => setFilter(CONSIGNED_LISTS.SOLD),
      });
    if (settledCount)
      items.push({
        label: `${CONSIGNED_LISTS.SETTLED.NAME} (${settledCount})`,
        onClick: () => setFilter(CONSIGNED_LISTS.SETTLED),
      });

    // Special case in teleporter, show remove button instead of actions if wallet not connected
    return showFilter ? (
      <KitColumn justify="start">
        <KitHeading level="3" color="secondary">
          &nbsp;
          <KitMenu label={filter.NAME} items={items} />
        </KitHeading>
      </KitColumn>
    ) : null;
  };

  // Render the consigned list
  function renderList() {
    return (
      <KitList>
        <KitRow displayMode="header">
          <KitColumn justify={showFilter ? 'end' : 'center'}>
            <KitHeading level="3" color="secondary">
              {QUEUE_LISTS.CONSIGNED}
            </KitHeading>
          </KitColumn>
          {renderFilter()}
        </KitRow>
        <KitRow displayMode="header" background="background-front">
          <KitColumn>
            <span style={smallDisplay ? styles.columnHeader : null}>
              &nbsp;Avastar
            </span>
          </KitColumn>
          {smallDisplay ? null : (
            <>
              <KitColumn justify="center">
                <span style={smallDisplay ? styles.columnHeader : null}>
                  Gen/Series
                </span>
              </KitColumn>
              <KitColumn justify="center">
                <span style={smallDisplay ? styles.columnHeader : null}>
                  Rarity
                </span>
              </KitColumn>
            </>
          )}
          <KitColumn justify="center">
            <span style={smallDisplay ? styles.columnHeader : null}>
              Finder's Fee
            </span>
          </KitColumn>
          {gasPrice === 0 ? null : (
            <KitColumn justify="center">
              <span style={smallDisplay ? styles.columnHeader : null}>
                Gas Cost
              </span>
            </KitColumn>
          )}
          <KitColumn justify="end" pad={{ right: 'medium' }}>
            <span style={smallDisplay ? styles.columnHeader : null}>
              {filter.STATUS === ListedAvastar.STATUS.CONSIGNED ||
              filter.STATUS === ListedAvastar.STATUS.EXPIRED ? (
                <span>Actions</span>
              ) : (
                <span>Status</span>
              )}
            </span>
          </KitColumn>
        </KitRow>
        {renderAvastars()}
      </KitList>
    );
  }

  // Render the Avastars in the consigned queue as rows
  function renderAvastars() {
    return filteredList.map((listed) => {
      return (
        <AvastarRow
          {...props}
          unsoldCount={unsoldCount}
          key={listed.id}
          listed={listed}
          inConsigned={true}
          showActions={true}
        />
      );
    });
  }

  // Render the total of the current filter
  function renderHeaderLeft() {
    return !!settleResult ? (
      !!settleResult.paid ? (
        <KitHeaderField>
          Settled:{' '}
          <span style={styles.checkoutNumeric}>Ξ&nbsp;{settleResult.paid}</span>
        </KitHeaderField>
      ) : settleResult.status === SETTLEMENT_STATUS.FAILED ? (
        <KitHeaderField>Failed!</KitHeaderField>
      ) : (
        <KitHeaderField>
          Settling:
          <span style={styles.checkoutNumeric}>
            Ξ&nbsp;{settleResult.expected}
          </span>
        </KitHeaderField>
      )
    ) : (
      <KitHeaderField>
        {filter.NAME}
        <span style={styles.checkoutNumeric}>
          Ξ&nbsp;{calculateTotalFees(filteredList)}
        </span>
      </KitHeaderField>
    );
  }

  // Render the user's network and optionally wallet connection status
  function renderHeaderCenter() {
    return !!account ? (
      filter === CONSIGNED_LISTS.SOLD || !!settleResult ? (
        <span>
          {network !== '' ? (
            <span>
              {network} {smallDisplay ? <br /> : <span>/</span>}{' '}
            </span>
          ) : null}
          <span style={styles.checkoutNumeric}>{truncatedAccount}</span>
        </span>
      ) : (
        <span>{network !== '' ? <span>{network}</span> : null}</span>
      )
    ) : (
      walletStatus
    );
  }

  // Render the wallet or a message asking to connect to the desired network or a cash out button
  function renderHeaderRight() {
    return walletStatus === WALLET_STATUS.wrong ? (
      <KitHeaderField>
        <span>Please connect to {desiredNetName}&nbsp;&nbsp;</span>
      </KitHeaderField>
    ) : filter.STATUS === ListedAvastar.STATUS.SOLD || settleResult ? (
      settling ? (
        <KitLoading text={''} pad="none" margin="none" size={50} key={0} />
      ) : !!settleResult ? (
        !!settleResult.status &&
        settleResult.status === SETTLEMENT_STATUS.SETTLED ? (
          <span>
            <KitLargeButton onClick={viewTransaction}>View Tx</KitLargeButton>
            &nbsp;|&nbsp;
            <KitLargeButton onClick={finishSettlement}>Dismiss</KitLargeButton>
          </span>
        ) : (
          <KitLargeButton onClick={() => setSettling(true)}>
            Try Again
          </KitLargeButton>
        )
      ) : (
        <KitLargeButton onClick={() => setSettling(true)}>
          Cash Out
        </KitLargeButton>
      )
    ) : (
      <span style={styles.checkoutNumeric}>{truncatedAccount}</span>
    );
  }

  // Open a new tab to view the payment transaction on Etherescan
  function viewTransaction() {
    let network =
      desiredNetwork.network === 'goerli' ? `${desiredNetwork.network}.` : '';
    let url = `https://${network}etherscan.io/tx/${settleResult.txHash}`;
    window.open(url, '_blank');
  }

  // Remove the settlement result
  function finishSettlement() {
    setSettling(false);
    setSettledResult(null);
  }

  // Render consigned list
  return (
    <Animated
      animationIn="slideInRight"
      animateOnMount={true}
      animationInDuration={350}
      animationInDelay={0}
    >
      <div style={styles.queueList}>
        <KitHeader
          withTabs={true}
          smallTabs={mediaSize !== MEDIA.MOBILE.SIZE}
          displayMode={mediaSize === MEDIA.MOBILE.SIZE ? 'mobile' : 'desktop'}
        >
          {renderHeaderLeft()}
          {renderHeaderCenter()}
          {renderHeaderRight()}
        </KitHeader>
        {renderList()}
      </div>
    </Animated>
  );
}
