import React from 'react';
import styled from 'styled-components';
import { Card } from 'react-bootstrap';

import { ThemeContext, Box, Heading } from 'grommet';
import { KitBox } from './';
import { avastar as styles } from '../../theme/styles';

/**
 * KitCard
 * Base for card.
 * border is controlled by KitCardBorder in most cases
 * TODO: refactor out border styles
 */

/** In the context of styled-components, 'className' is the generated class name passed as a prop */
const KitCardStyles = `
  @media(max-width: 768px){
    min-width: unset !important;
  }
`;
const KitCardBox = (props) => {
  const {
    className,
    inverted,
    displayMode,
    // cardStyle,
    inset,
    pad,
    borderColor,
    direction,
    customStyle,

    ...otherProps
  } = props;
  return (
    <Box
      {...otherProps}
      className={className}
      pad={props.pad || (inset && '2px') || '0px'}
      direction={props.direction}
      background={inverted ? 'background-inverted' : 'background-back'}
      style={{
        minWidth: displayMode === 'ui' ? '16rem' : '',
        ...customStyle,
      }}
    />
  );
};
export const KitCard = styled(KitCardBox)`
  ${KitCardStyles}
`;

/**
 * KitCardBody
 * Content.
 */

export const KitCardBody = (props) => {
  const {
    displayMode = 'ui', // MANAGES DISPLAY .ie padding.
    //className,   // USED WHEN STYLED-COMPONENT IS IN ACTIONS
    ...otherProps
  } = props;
  return (
    <Box
      {...otherProps}
      pad={props.pad || displayMode === 'ui' ? 'small' : 'none'}
      background={props.background || 'highlight'}
      margin={props.margin || '0'}
      style={{ minWidth: '5rem' }}
    />
  );
};

/**
 * KitCardImage
 * wrapper for card image in order to handle hover
 */
const CardImageStyles = `
    cursor: pointer;
    transition: border .1s ease-out;

    /* theme color is set using grommet variables, then unset here until hovered */
    &:not(:hover){
      border-color: transparent !important;
    }
    &:focus, img:focus{
      outline: none;
      border-style: dotted;

    }
    /* Enable hover only when device isn't touch */
    @media (hover: hover) {
      &:hover{
        outline: 0;
        box-shadow: none;
        border-style: dotted;
      }
    }
`;
/** In the context of styled-components, 'className' is the generated class name passed as a prop */
const CardImage = (props) => {
  const { className, angled, ...otherProps } = props;
  return (
    <Box
      {...otherProps}
      className={className}
      border={{
        color: 'background-inverted',
        size: '2px',
        style: 'solid',
        side: 'all',
      }}
      style={angled ? styles.angledImageWrap : null}
    />
  );
};

export const KitCardImage = styled(CardImage)`
  ${CardImageStyles}
`;

/**
 * KitImageWrap
 * For abstracting ui styles away from the avastar Image.
 * This makes the image contrained to the available height/width if necessary
 */
const imageWrapStyles = `
>img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  vertical-align: top;
}`;
const CardImageWrap = (props) => {
  const { className, ...otherProps } = props;
  return (
    <Box
      {...otherProps}
      direction="column"
      pad={props.pad || 'none'}
      margin={props.margin || '0'}
      align="start"
      justify="stretch"
      className={className}
    />
  );
};
export const KitCardImageWrap = styled(CardImageWrap)`
  ${imageWrapStyles}
`;

/**
 * KitCardHeader
 * Wraps the text for when situations require more the background
 */
export const KitCardHeader = (props) => {
  const headerStyles = {
    textTransform: 'uppercase',
    flexShrink: '0',
    minHeight: 'initial',
  };
  return (
    <Box
      {...props}
      pad={props.size === 'small' ? 'xxsmall' : 'xsmall'}
      background="highlight"
      fill="horizontal"
      direction="row"
      align="center"
      justify="between"
      margin={'none'}
      basis={props.size === 'small' ? '2rem' : '4rem'}
      style={{
        ...headerStyles,
        ...(props.angled && styles.angledCardHeader),
      }}
    />
  );
};

/**
 * KitCardTitle
 * Text Heading
 */

const cardTitleStyles = ``;
const CardTitle = (props) => <Heading level="5" margin="xsmall" {...props} />;
export const KitCardTitle = styled(CardTitle)`
  ${cardTitleStyles}
`;

/**
 * KitCardSubtitle
 * Text Subheading
 */

const cardSubtitleStyles = `maxWidth: "100%"`;
const CardSubtitle = (props) => (
  <Heading level="6" margin={{ vertical: 'xsmall' }} {...props} />
);
export const KitCardSubtitle = styled(CardSubtitle)`
  ${cardSubtitleStyles}
`;

/**
 * KitCardFooter
 * Footer section.
 */
const footerStyles = `
/* conditionally rendered on displayMode prop in order to match mockup */
`;

const CardFooter = (props) => {
  const { className, angled, displayMode, ...otherProps } = props;
  const cardFooterStyles =
    displayMode === 'avastarCard'
      ? {
          padding: '0px',
          fontSize: '0.875rem',
          fontWeight: '700',
          background: '#fff',
          alignItems: 'stretch',
          margin: '0 2px 2px 2px',
          marginTop: displayMode === 'ui' ? '2px' : '0px',
        }
      : null;

  return (
    <Box
      {...otherProps}
      className={className}
      direction="row"
      background={displayMode === 'avastarCard' ? '#fff' : 'highlight'}
      margin="none"
      pad={props.size === 'small' ? 'xxsmall' : 'xsmall'}
      align={props.align || 'stretch'}
      justify={props.justify || 'between'}
      style={{
        ...cardFooterStyles,
        ...(angled && styles.angledCardFooter),
      }}
    />
  );
};
export const KitCardFooter = styled(CardFooter)`
  ${footerStyles}
`;

export const AvatarCard = (props) => {
  return <Box {...props} />;
};

export const KitCardDivider = (props) => {
  const { direction = 'row', forTabs = false, ...otherProps } = props;
  return (
    <Box
      {...otherProps}
      height="2px"
      pad="none"
      margin={direction === 'row' ? { vertical: '0px' } : { horizontal: '0px' }}
      background={props.color ? props.color : 'highlight'}
      style={{
        width: direction === 'row' ? 'calc(100% + 8px)' : '2px',
        height: direction === 'row' ? '2px' : 'calc(100% + 4px)',
        position: 'relative',
        marginLeft: props.indent ? '2px' : '0px',
        marginRight: props.indent ? '2px' : '0px',
        marginTop: direction === 'row' ? (forTabs ? '0' : '2px') : '0',
        marginBottom: '0',
        flexShrink: 0,
      }}
    />
  );
};

export const KitCardImg = styled(Card.Img)``;
export const KitCardText = styled(Card.Text)``;

/**
 * CARD BORDER
 * Creates the illusion of an angled border around ui content. In order to do this, clip-path is used to crop overflow
 * of two divs, inset by 2px.
 *
 */

const borderStyles = `
  max-width: 1112px;
  margin-left: auto;
  margin-right: auto;
  flex-basis: 100%;
  `;

const contentStyles = `
  max-width: 1112px;
  margin-left: auto;
  margin-right: auto;
  flex-basis: 85%;
  `;

const CardBorder = (props) => {
  const {
    children,
    className, // this is the styled-component class
    inset = false,
    angled = false,
    style,
    ...otherProps
  } = props;
  return (
    <ThemeContext.Consumer>
      {(theme) => {
        // CONDITIONAL OVERRIDE WHERE GROMMET DOESNT PICK UP CORRECT COLOR
        const borderColor =
          theme.dark === true
            ? theme.global.colors['highlight'].dark
            : theme.global.colors['highlight'].light; // was 'secondary' for more contrast
        const backgroundColor =
          theme.dark === true
            ? theme.global.colors['background-back'].dark
            : theme.global.colors['background-back'].light;

        const borderStyles = {
          boxShadow: '0 0 0 2px',
          border: 'none',
          position: 'relative',
          transition: 'border-color .2s ease-in-out',
          display: 'inline-block',
          backgroundColor: borderColor,
          color: borderColor,
        };

        // Resets the background to look like the page background...
        const innerStyles = {
          backgroundColor: backgroundColor,
        };
        return (
          <KitBox
            className={className}
            style={{ ...borderStyles, ...styles.angled, ...style }}
          >
            <KitBox
              fill
              pad={inset ? '2px' : '0px'}
              {...otherProps}
              style={{
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                ...innerStyles,
                ...(angled && styles.borderInner),
                ...(angled && styles.angledInner),
              }}
            >
              {children}
            </KitBox>
          </KitBox>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export const KitCardBorder = styled(CardBorder)`
  ${borderStyles}
`;

export const KitContentBorder = styled(CardBorder)`
  ${contentStyles}
`;
