import React from 'react';
import { MEDIA } from '../../constants/media';

import {
  KitNavbarBrand,
  KitLogo,
  ThemeSwitch,
  KitNavActions,
} from '../../theme/kit/index';

const Header = ({ setThemeStyle, mediaSize, children = null }) => (
  <>
    <KitNavbarBrand>
      <KitLogo />
    </KitNavbarBrand>
    {children}
    <KitNavActions>
      <ThemeSwitch
        setThemeStyle={setThemeStyle}
        vertical={mediaSize === MEDIA.MOBILE.SIZE}
      />
    </KitNavActions>
  </>
);

export default Header;
