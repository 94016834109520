import React, { useState, useEffect, useCallback } from 'react';
import withQueryParams from 'react-router-query-params';
import eip55 from 'eip55';
import { usePrevious } from '../../hooks';
import { marketplace as styles } from '../../theme/styles';
import * as SECTIONS from '../../constants/sections';
import { WALLET_STATUS } from '../../constants/wallet';
import { QUERY_CONFIG } from '../../constants/query';
import Gender from '../../domain/enum/Gender';
import Rarity from '../../domain/enum/Rarity';
import Series from '../../domain/enum/Series';
import Gene from '../../domain/enum/Gene';
import ListedAvastar from '../../domain/entity/ListedAvastar';
import Intro from './Intro';
import Discover from './Discover';
import Queue from './Queue';
import Profile from './Profile';
import AvastarModal from './Avastar/AvastarModal';
import { KitContainer } from '../../theme/kit';

const Marketplace = (props) => {
  // State from props
  const {
    web3,
    firebase,
    network,
    account,
    setWishlist,
    setConsigned,
    loading,
    section,
    traits,
    series,
    teleporter,
    setTeleporter,
    consigned,
    fees,
    prices,
    queryParams,
    setSection,
    allowGenerate,
    setAllowGenerate,
    maintenanceMode,
  } = props;

  // Local State
  const [filteredTraits, setFilteredTraits] = useState(traits);
  const [variations, setVariations] = useState({
    [Gender.MALE]: [],
    [Gender.FEMALE]: [],
  });
  const [filteredGenes, setFilteredGenes] = useState({
    [Gender.MALE]: [],
    [Gender.FEMALE]: [],
  });
  const [genesByGenderAndRarity, setGenesByGenderAndRarity] = useState(null);
  const [viewedAvastar, setViewedAvastar] = useState(null);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [walletStatus, setWalletStatus] = useState(WALLET_STATUS.disconnected);
  const [profile, setProfile] = useState();
  const [walletBalance, setWalletBalance] = useState(0);
  const [truncatedAccount, setTruncatedAccount] = useState('');
  const [gasPrice, setGasPrice] = useState(0);

  const previousAccount = usePrevious(!!account ? account : undefined);
  const previousQuery = usePrevious(queryParams);

  // Get the user profile
  // TODO: Have service return info on number of open and sold consignments
  const getProfile = useCallback(async () => {
    account &&
      (await firebase.fetchProfile(account).then((response) => {
        setProfile(response);
      }));
  }, [account, firebase, setProfile]);

  // When selectedApplicant (a ListedAvastar instance) is set,
  // add or remove it from the teleporter
  useEffect(() => {
    if (selectedApplicant) {
      let tmp,
        location = teleporter.findIndex(
          (saved) => saved.id === selectedApplicant.id
        );
      if (location >= 0) {
        // In teleporter, remove
        tmp = [...teleporter];
        tmp.splice(location, 1);
      } else {
        // Not in teleporter, add
        tmp = [...teleporter, selectedApplicant];
      }
      setTeleporter(tmp);
      setSelectedApplicant(null);
    }
  }, [selectedApplicant, setSelectedApplicant, teleporter, setTeleporter]);

  // Compute truncated account for display
  useEffect(() => {
    if (!!account) setTruncatedAccount(truncateAccount(account));
  }, [account]);

  // Set filteredTraits when traits changes
  useEffect(() => {
    setFilteredTraits(traits);
  }, [traits, setFilteredTraits]);

  // Filter traits when series changes
  useEffect(() => {
    const count = filteredTraits.length;
    const filtered = filteredTraits.filter(
      (trait) => series === Series.ANY || trait.series.includes(series)
    );
    if (filtered.length !== count) {
      setFilteredTraits(filtered);
    }
  }, [series, filteredTraits, setFilteredTraits]);

  // Create arrays of traits for male and female avastars
  useEffect(() => {
    setVariations({
      [Gender.MALE]: Gene.TYPES.map((gene) =>
        filteredTraits.filter(
          (trait) =>
            trait.gene === gene &&
            (trait.gender === Gender.MALE || trait.gender === Gender.ANY)
        )
      ).filter((arr) => !!arr.length),
      [Gender.FEMALE]: Gene.TYPES.map((gene) =>
        filteredTraits.filter(
          (trait) =>
            trait.gene === gene &&
            (trait.gender === Gender.FEMALE || trait.gender === Gender.ANY)
        )
      ).filter((arr) => !!arr.length),
    });
  }, [filteredTraits, setVariations]);

  // Create arrays of genes for male and female avastars
  useEffect(() => {
    setFilteredGenes({
      [Gender.MALE]: variations[Gender.MALE].map((gene) => gene[0].gene),
      [Gender.FEMALE]: variations[Gender.FEMALE].map((gene) => gene[0].gene),
    });
  }, [variations, setFilteredGenes]);

  // Separate genes by gender and rarity when variations change
  useEffect(() => {
    const calc = (gender) =>
      Rarity.LEVELS.map((level) =>
        variations[gender].map((gene) =>
          gene.filter((trait) => trait.rarity === level)
        )
      );
    let possibleTraits = {
      [Gender.MALE]: calc(Gender.MALE),
      [Gender.FEMALE]: calc(Gender.FEMALE),
    };
    setGenesByGenderAndRarity(possibleTraits);
  }, [variations, setGenesByGenderAndRarity]);

  // Monitor profile and wishlist and check wallet balance when account changes
  useEffect(() => {
    const address = !!account ? eip55.encode(account) : undefined;

    if (previousAccount !== undefined && previousAccount !== address) {
      firebase.unMonitorDepositorBalance(previousAccount);
      firebase.unMonitorWishlist(previousAccount);
      firebase.unMonitorConsigned(previousAccount);
    }

    if (!!account && address !== previousAccount) {
      firebase.monitorDepositorBalance(address, getProfile);
      firebase.monitorWishlist(address, (list) => {
        setWishlist(list);
      });
      firebase.monitorConsigned(address, (list) => {
        setConsigned(list);
      });
      getWalletBalance();
    }
    // eslint-disable-next-line
  }, [account, firebase, getProfile, network, setWishlist]);

  // Switch to the DISCOVER section when the finder query param changes
  useEffect(() => {
    if (
      queryParams &&
      queryParams.finder &&
      (!previousQuery || previousQuery['finder'] !== queryParams.finder)
    ) {
      setSection(SECTIONS.DISCOVER);
    }
  }, [queryParams, previousQuery, setSection]);

  // (Used by lists)
  // Calculate the total fees for the given list
  function calculateTotalFees(list) {
    const total = list.reduce((accumulator, listed) => {
      const fee = getFee(listed);
      return accumulator + fee;
    }, 0);
    const amount = Number.parseFloat(total).toFixed(2);
    return Number(amount);
  }

  // (Used by lists)
  // Get the price of a given Avastar based on rarity and possibly finder's fee
  function getPrice(listed) {
    let price = 0;
    if (listed) {
      let rarity = Rarity.LEVELS[listed.avastar.level - 1];
      let fee =
        listed.status === ListedAvastar.STATUS.CONSIGNED ? getFee(listed) : 0;
      price = prices[rarity] + fee;
    }
    return price;
  }

  // (Used by lists)
  // Get the finder's fee for a given Avastar
  function getFee(listed) {
    let fee = 0;
    if (listed) {
      let rarity = Rarity.LEVELS[listed.avastar.level - 1];
      fee = fees[rarity];
    }
    return fee;
  }

  // (Used by lists)
  // Is the given ListedAvastar in the user's Teleporter list?
  function isInTeleporter(listed) {
    return !!listed && !!teleporter.find((item) => listed.id === item.id);
  }

  // (Used by lists)
  // Is the given ListedAvastar in the user's Consigned list?
  function isInConsigned(listed) {
    return !!listed && !!consigned.find((item) => listed.id === item.id);
  }

  const getWalletBalance = React.useCallback(async () => {
    // Check wallet account balance for sufficient funds
    let balance = 0;
    if (web3) {
      try {
        const wei = await web3.eth.getBalance(account);
        balance = Number(web3.utils.fromWei(wei, 'ether'));
      } catch (e) {
        console.error(e);
      }
    }

    let fixed = Number(balance.toFixed(4));
    setWalletBalance(fixed);
    return fixed;
  }, [web3, account]);

  // when the web3 instance changes, we need to fetch the balance for the network the user has selected
  useEffect(() => {
    if (web3) {
      getWalletBalance();
    }
  }, [web3, getWalletBalance]);

  function truncateAccount(account) {
    return `${account.slice(0, 6)}...${account.slice(account.length - 4)}`;
  }

  // Render the Intro section
  const renderIntro = () => (
    <KitContainer>
      <Intro maintenanceMode={maintenanceMode} />
    </KitContainer>
  );

  // Render the Discover section
  const renderDiscover = () => (
    <KitContainer style={styles.container}>
      <Discover
        {...props}
        getFee={getFee}
        getPrice={getPrice}
        isInTeleporter={isInTeleporter}
        isInConsigned={isInConsigned}
        filteredGenes={filteredGenes}
        viewedAvastar={viewedAvastar}
        truncateAccount={truncateAccount}
        setSelectedApplicant={setSelectedApplicant}
        setViewedAvastar={setViewedAvastar}
        genesByGenderAndRarity={genesByGenderAndRarity}
        previousQuery={previousQuery}
        allowGenerate={allowGenerate}
        setAllowGenerate={setAllowGenerate}
      />
      {renderModal()}
    </KitContainer>
  );

  // Render the Queue section
  const renderQueue = () => (
    <KitContainer style={styles.container}>
      <Queue
        {...props}
        web3={web3}
        profile={profile}
        getFee={getFee}
        getPrice={getPrice}
        calculateTotalFees={calculateTotalFees}
        truncatedAccount={truncatedAccount}
        walletStatus={walletStatus}
        setWalletStatus={setWalletStatus}
        walletBalance={walletBalance}
        setWalletBalance={setWalletBalance}
        getWalletBalance={getWalletBalance}
        setViewedAvastar={setViewedAvastar}
        viewedAvastar={viewedAvastar}
        gasPrice={gasPrice}
        setGasPrice={setGasPrice}
        series={series}
        maintenanceMode={maintenanceMode}
      />
      {renderModal()}
    </KitContainer>
  );

  // Render the Profile section
  const renderProfile = () => (
    <KitContainer style={styles.container}>
      <Profile
        {...props}
        profile={profile}
        setProfile={setProfile}
        getProfile={getProfile}
        walletStatus={walletStatus}
        setWalletStatus={setWalletStatus}
        walletBalance={walletBalance}
        setWalletBalance={setWalletBalance}
        getWalletBalance={getWalletBalance}
        truncatedAccount={truncatedAccount}
        setGasPrice={setGasPrice}
        maintenanceMode={maintenanceMode}
      />
    </KitContainer>
  );

  const renderModal = () =>
    !!viewedAvastar ? (
      <AvastarModal
        {...props}
        onClose={() => setViewedAvastar(null)}
        truncatedAccount={truncatedAccount}
        truncateAccount={truncateAccount}
        key={viewedAvastar.serial}
        show={true}
        listed={viewedAvastar}
        setSelectedApplicant={setSelectedApplicant}
      />
    ) : null;

  // Determine what the marketplace display should contain
  let marketplace;
  if (!loading) {
    switch (section) {
      case SECTIONS.INTRO:
        marketplace = renderIntro();
        break;

      case SECTIONS.DISCOVER:
        marketplace = renderDiscover();
        break;

      case SECTIONS.QUEUE:
        marketplace = renderQueue();
        break;

      case SECTIONS.PROFILE:
        marketplace = renderProfile();
        break;

      default:
        marketplace = undefined;
    }
  } else {
    marketplace = renderIntro();
  }

  return marketplace;
};

export default withQueryParams(QUERY_CONFIG)(Marketplace);
