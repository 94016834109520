import React from 'react';
import { Box, Layer, ThemeContext, Text } from 'grommet';

/**
 * KIT Notification
 */
const NotificationLayer = (props) => {
  const {
    className,
    border,
    onEsc,
    onClickOutside,
    children,
    ...otherProps
  } = props;

  return (
    <ThemeContext.Extend
      value={{
        layer: { zIndex: 9999, padding: 'none' },
        overlay: {
          zIndex: 999999,
        },
      }}
    >
      <ThemeContext.Consumer>
        {(theme) => {
          const notificationBgColor =
            theme.dark === true
              ? theme.global.colors['secondary'].dark
              : theme.global.colors['secondary'].light;

          const notificationColor =
            theme.dark === true
              ? theme.global.colors['highlight'].dark
              : theme.global.colors['highlight'].light;

          return (
            <Layer
              modal={false}
              position="top"
              onEsc={onEsc}
              zIndex="1002"
              margin={{ vertical: 'xlarge', horizontal: 'medium' }}
              background="background-back"
              className={className}
              style={{ background: notificationBgColor }}
              {...otherProps}
            >
              <Box
                align="center"
                direction="row"
                gap="medium"
                justify="between"
                elevation="medium"
                pad={{ vertical: 'small', horizontal: 'medium' }}
              >
                <Box align="center" direction="row" gap="small">
                  <Text color={notificationColor} textAlign="center">
                    {children}
                  </Text>
                </Box>
              </Box>
            </Layer>
          );
        }}
      </ThemeContext.Consumer>
    </ThemeContext.Extend>
  );
};

export const KitNotification = NotificationLayer;
