/**
 * Schema Entity: Avastar
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
import JSBI from 'jsbi/dist/jsbi.mjs';

import Trait from "./Trait";
import Gender from "../enum/Gender";
import Gene from "../enum/Gene";
import Rarity from "../enum/Rarity";
import Series from "../enum/Series";

class Avastar {

    constructor (generation, series, serial, gender, traits, breaks) {
        this.generation = generation;
        this.series = series;
        this.serial = serial;
        this.gender = gender;
        this.traits = traits || [];
        this.breaks = breaks || Avastar.defaultBreaks;
        this.isPromo = (series === Series.PROMOS);
        this.hash = this.computeHash();
        this.score = this.computeScore();
        this.level = this.computeLevel();
    }

    static defaultBreaks = [33, 41, 50, 60]; // the dialed in values

    /**
     * Get a new Avastar instance from a database representation
     * @param o
     * @returns {Avastar}
     */
    static fromObject(o) {
        let traits = o.traits ? o.traits.map(trait => Trait.fromObject(trait)) : undefined;
        return new Avastar(o.generation, o.series, o.serial, o.gender, traits, o.breaks);
    }

    /**
     * Get a database representation of this Avastar instance
     * @returns {object}
     */
    toObject() {
        return JSON.parse(JSON.stringify(this, (key, value) =>
            value instanceof JSBI
                ? value.toString()
                : value // return everything else unchanged
        ));
    }

    /**
     * Get a string representation of this Avastar instance
     * @returns {boolean}
     */
    toString() {
        return [
            this.generation,
            this.series,
            this.serial,
            this.gender,
            this.traits.map(trait => trait.toString()).join(', '),
            this.hash,
            this.score,
            this.level,
            this.breaks.map(breakPoint => breakPoint.toString()).join(', ')
        ].join(', ');
    }

    /**
     * Is this Avastar instance's generation field valid?
     * @returns {boolean}
     */
    generationIsValid() {
        let valid = false;
        try {
            valid = (
                typeof this.generation === 'number' &&
                this.generation > 0 &&
                this.generation < 256
            );
        } catch (e) {
        }
        return valid;
    }

    /**
     * Is this Avastar instance's series field valid?
     * @returns {boolean}
     */
    seriesIsValid() {
        let valid = false;
        try {
            valid = (
                typeof this.series === 'number' &&
                this.series > 0 &&
                this.series < 10
            );
        } catch (e) {
        }
        return valid;
    }

    /**
     * Is this Avastar instance's serial field valid?
     * @returns {boolean}
     */
    serialIsValid() {
        let valid = false;
        try {
            valid = (
                typeof this.serial === 'number'
            );
        } catch (e) {}
        return valid;
    }

    /**
     * Is this Avastar instance's gender field valid?
     * @returns {boolean}
     */
    genderIsValid() {
        let valid = false;
        try {
            valid = (
                Gender.TYPES.includes(this.gender) ||
                this.gender === Gender.ANY
            );
        } catch (e) {}
        return valid;
    };

    /**
     * Is this Avastar instance's traits field valid?
     * @returns {boolean}
     */
    traitsIsValid() {
        let valid = false;
        try {
            valid = (
                Array.isArray(this.traits) &&
                (this.traits.length === 0 ||
                    this.traits.reduce( (prev, trait) => prev && trait.isValid(), true ))
            );
        } catch (e) {
        }
        return valid;
    }

    /**
     * Is this Avastar instance's hash field valid?
     * @returns {boolean}
     */
    hashIsValid() {
        let valid = false;
        try {
            valid = (
                this.hash === undefined || this.verifyHash()
            );
        } catch (e) {
        }
        return valid;
    }

    /**
     * Is this Avastar instance's score field valid?
     * @returns {boolean}
     */
    scoreIsValid() {
        let valid = false;
        try {
            valid = (
                typeof this.score === 'number' &&
                this.score >= 1 &&
                this.score <= 100
            );
        } catch (e) {
        }
        return valid;
    }

    /**
     * Is this Avastar instance's level field valid?
     * @returns {boolean}
     */
    levelIsValid() {
        let valid = false;
        try {
            valid = (
                typeof this.level === 'number' &&
                this.level >= 1 &&
                this.level <= 5
            );
        } catch (e) {
        }
        return valid;
    }

    /**
     * Is this Avastar instance valid?
     * @returns {boolean}
     */
    isValid() {
        return (
            this.generationIsValid() &&
            this.seriesIsValid() &&
            this.serialIsValid() &&
            this.genderIsValid() &&
            this.traitsIsValid() &&
            this.hashIsValid() &&
            this.scoreIsValid() &&
            this.levelIsValid()
        );
    };

    /**
     * Compute the hash that encodes this Avastar instance's traits
     */
    computeHash() {
        let hash = 0;
        if (this.traits.length === 0) {
            this.hash = 0
        } else {
            let multiplier = JSBI.BigInt('256');
            hash = this.traits.reduce( (acc, trait) => {
                let variation = JSBI.BigInt(trait.variation);
                let gene = JSBI.BigInt(Gene.TYPES.indexOf(trait.gene));
                let slot = JSBI.exponentiate(multiplier, gene);
                let value = JSBI.multiply(variation, slot);
                return JSBI.add(acc, value);
            }, JSBI.BigInt('0') );
        }
        return hash;
    }

    /**
     * Verify that this Avastar instance's hash encodes its traits traits
     */
    verifyHash() {
        let verified = true;
        let multiplier = JSBI.BigInt('256');
        Gene.TYPES.forEach( (gene, index) => {
            let trait = this.traits.find(trait => trait.gene === gene);
            let slot = JSBI.exponentiate(multiplier, JSBI.BigInt(index));
            let mask = JSBI.multiply(JSBI.BigInt('255'), slot);
            let value = JSBI.bitwiseAnd(this.hash, mask);
            let variation = JSBI.toNumber(JSBI.divide(value, slot));
            if (
                (trait && trait.variation !== variation) ||  // trait set for this gene but wrong variation
                (!trait && variation !== 0)                  // no trait for this gene but variation non-zero
            ) verified = false;
        });
        return verified;
    }

    getHashString() {
        return String(this.hash);
    }

    /**
     * Compute rarity score
     * 1-100 based upon rarity level of all traits and the number of traits
     * Higher scores are more rare
     * @returns {number}
     */
    computeScore() {
        let numTraits = (this.traits && this.traits.length) ? this.traits && this.traits.length : 0;
        let score = {min:0, max:0, actual: 0};
        if (numTraits > 0 ) {
            score = this.traits.reduce((acc, trait) => {
                const rarity_level = Rarity.LEVELS.indexOf(trait.rarity) + 1;
                const variations_per_level = Gene.VARIATIONS_PER_RARITY_LEVEL[trait.gene];
                acc.min += numTraits/(1 * variations_per_level);
                acc.max += numTraits/(5 * variations_per_level);
                acc.actual += numTraits/(rarity_level * variations_per_level);
                return acc;
            }, score);
        }
        return this.scaleScore(score.actual, 1, 100, score.min, score.max);
    }

    /**
     * Compute the rarity level (1-5) from the rarity score (1-100) based on rarity levels
     */
    computeLevel() {
        let level;
        if (this.score < this.breaks[0])      {level = 1}
        else if (this.score < this.breaks[1]) {level = 2}
        else if (this.score < this.breaks[2]) {level = 3}
        else if (this.score < this.breaks[3]) {level = 4}
        else {level = 5}
        return level;
    }

    /**
     * Scale the score to a range
     * @param score
     * @param rangeLow
     * @param rangeHigh
     * @param min
     * @param max
     * @returns {number}
     */
    scaleScore (score, rangeLow, rangeHigh, min, max) {
        return Math.round((rangeHigh - rangeLow) * (score - min) / (max - min) + rangeLow);
    };

    /**
     * Clone this Avastar
     * @returns {Avastar}
     */
    clone () {
       return  Avastar.fromObject(this.toObject());
    }

}

export default Avastar;