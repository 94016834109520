import eip55 from "eip55";
export const QUERY_CONFIG = {
    stripUnknownKeys: true,
    keys: {
        finder: {
            default: undefined,
            validate: value => {
                let valid = false;
                try {
                    eip55.verify(value);
                    valid = true;
                } catch (e) {
                }
                return valid;
            }
        }
    }
};