const theme = {

    // Custom theme
    // http://paletton.com/#uid=64b0H0ksCMPhlS8n9PbG2KgHFsp

    font: {
        logo: 'Satisfy',
        body: 'Inconsolata',
        mono: 'PT Mono',
    },

    shadow: "#5f5f5f",
    disabled: "#acacac",
    error: "#ff0000",

    background: {
        light: "#F5F5F6",
        dark: "#E1E2E1",
        header: "#71B8E6",
        Common: "#008ffb",
        Uncommon: "#00e396",
        Rare: "#feb019",
        Epic: "#775dd0",
        Legendary: "#ff4560"
    },

    secondary: {
        main: "#3811D6",
        light: "#6E52E2",
        dark: "#280B9C",
        text: "#FFD41B"
    },

    primary: {
        main: "#47A2DD",
        light: "#71B8E6",
        dark: "#228FD6",
        text: "#000000"
    },
};

export default theme;
