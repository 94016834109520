import React, {useState, useCallback} from "react";
import {avastar as styles} from "../../../theme/styles";
import {AVASTAR_SIZE} from "../../../constants/media";
import Rarity from "../../../domain/enum/Rarity";
import AvastarImage from "./AvastarImage";
import RarityIcon from "./RarityIcon";
import {AvastarBorder} from "./AvastarBorder";
import {
    QueueIcon,
    QueueSelectedIcon,
    StarIcon,
    KitCardFooter,
    KitCardImage,
    KitIconButton,
} from "../../../theme/kit";

export default function AvastarCard(props) {

    // State from props
    const {
        listed,
        viewedAvastar,
        setViewedAvastar,
        setSelectedApplicant,
        inTeleporter = false,
        inConsigned = false,
        mediaSize,
        onCriticalEvent,
        getPrice,
    } = props;

    // Local state
    const [added, setAdded] = useState(inTeleporter);

    // Toggle the local added state and set the selected applicant
    // when added is true call raise the critical event for the robot detector
    const toggleAdded = useCallback( () => {
            if (!added) onCriticalEvent();
            setAdded(!added);
            setSelectedApplicant(listed);
        // eslint-disable-next-line
    }, [added]);

    // Card label details
    const rank = Rarity.LEVELS[listed.avastar.level - 1];
    const price = getPrice(listed);

    // User clicked on the Avastar image
    const handleAvastarClick = (listed) => {
        if (!viewedAvastar) {
          onCriticalEvent();
          setViewedAvastar(listed);
        }
    };

    // Toggle state of being queued
    const toggleQueuedState = () => {
        toggleAdded();
    };

    // Queue button
    const renderQueueButton = () => {
        return added
            ? <QueueSelectedIcon style={styles.avastarQueue} />
            : <QueueIcon style={styles.avastarQueue} />;
    };

    // Avastar Image
    const renderAvastar = (listed) => {
        let size = AVASTAR_SIZE[mediaSize];
        return (
            <KitCardImage id={`Avastar-${listed.avastar.serial}`} onClick={() => handleAvastarClick(listed)}>
                    <AvastarImage avastar={listed.avastar} size={size} backdrop={true} />
            </KitCardImage>
        );
    };

    // Queue, progress, or minted icons
    const renderAction = () => {
      // enabled = whether the user can click it
      // in this case, consigned means it belongs to the user and the user cannot interact
      // note the active={true} prop could be used here with KitIconButton styled-component styles,
      // but is handled by the existence of queueState
      return inConsigned
            ? <KitIconButton fill full color="secondary" enabled={false}>
                <StarIcon style={styles.avastarQueue}/>
              </KitIconButton>
            : <KitIconButton fill full color="secondary" onClick={toggleQueuedState} rarity={rank} enabled={true}>
                {renderQueueButton()}
              </KitIconButton>;
    };

    return (
        <AvastarBorder rarity={rank} highlighted={added}>
            {renderAvastar(listed)}
            <KitCardFooter size="small" displayMode="avastarCard" style={styles.avastarCardLabel} align="center">
                <span style={styles.footerPrice}>
                    <span style={styles.currencySymbol}>Ξ</span> {price}
                </span>
                <span style={styles.footerRarity}>
                    <RarityIcon rarity={rank} size="medium" active={true} inline={true}/>
                </span>
                <span style={styles.footerActions}>
                    {renderAction()}
                </span>
            </KitCardFooter>
        </AvastarBorder>
    );
}
