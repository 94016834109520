import {MersenneTwister19937, integer, real} from "random-js";

class Stochastic {

    static _engine;
    static get engine() {
        if (!Stochastic._engine) {
            Stochastic._engine = Stochastic.getEngine();
        }
        return Stochastic._engine;
    };

    static getEngine() {
        return MersenneTwister19937.seedWithArray(
            [
                Date.now(),
                performance.now(),
                performance.now()/Date.now()*2,
                Date.now()/performance.now()/2
            ]
        );
    }
    static aim() {
        return real(0,1, true)(Stochastic.engine);
    }

    static fire(a,b) {
        return integer(a,b)(Stochastic.engine);
    }
}

export default Stochastic;