import React, { forwardRef, useImperativeHandle } from 'react';
import { Animated } from 'react-animated-css';
import Gender from '../../../domain/enum/Gender';
import Rarity from '../../../domain/enum/Rarity';
import Avastar from '../../../domain/entity/Avastar';
import Stochastic from '../../../domain/Stochastic';
import ListedAvastar from '../../../domain/entity/ListedAvastar';

const Generator = forwardRef((props, ref) => {
  // State from props
  const {
    series,
    generation,
    filteredGenes,
    genesByGenderAndRarity,
    pageBuffer,
    setPageBuffer,
    serialBase,
    setSerialBase,
    transition,
    bufferAvastars,
    pageSize,
    allowGenerate,
    startTimer
  } = props;

  // Expose onCriticalEvent function to parent
  useImperativeHandle(ref, () => ({
    // Generate a fresh batch of Avastars
    generateMoreAvastars() {
      if (allowGenerate) {
        startTimer();
        let avastar,
          avastarGender,
          entry = serialBase,
          fresh = [];
        while (fresh.length < pageSize) {
          avastarGender = generateRandomNumber(1) ? Gender.MALE : Gender.FEMALE;
          avastar = generateRandomAvastar(++entry, avastarGender);
          fresh.push(avastar);
        }

        const population = bufferAvastars(fresh, pageBuffer);
        setPageBuffer(population);
        setSerialBase(entry);
      }
    },
  }));



  // Generate a random integer between 0 and the given maximum
  function generateRandomNumber(max) {
    return Stochastic.fire(0, max);
  }

  // Generate a random Avastar
  function generateRandomAvastar(serial, gender) {
    const chooseWeightedRandom = () => {
      let sample = Stochastic.aim();
      return Rarity.distribution.find((element) => {
        const x = sample - element.chance;
        return !((sample = x) > Rarity.ZERO);
      }).name;
    };

    // Choose a rarity level for each gene with traits for this gender
    const geneRarity = filteredGenes[gender].map((gene, index) => {
      let rarityChosen = chooseWeightedRandom();
      return {
        geneIndex: index,
        rarityIndex: Rarity.LEVELS.findIndex(
          (rarity) => rarity === rarityChosen
        ),
      };
    });

    // Lookup possible traits based on gender and rarity level
    const possibleTraits = geneRarity.map(
      (level) =>
        genesByGenderAndRarity[gender][level.rarityIndex][level.geneIndex]
    );

    // Choose actual traits
    const traits = possibleTraits.map(
      (gene) => gene[generateRandomNumber(gene.length - 1)]
    );

    // Create and return the Avastar
    const avastar = new Avastar(generation, series, serial, gender, traits);
    const listedAvastar = new ListedAvastar(avastar.getHashString(), avastar);

    return listedAvastar;
  }

  // Render the Avastar browser
  // Longer animation delay and duration needed
  // because switching between feeds can take up time
  // that causes the animation to skip frames to land on time
  return (
    <Animated
      animationIn={transition}
      animateOnMount={true}
      animationInDuration={400}
      animationInDelay={100}
    >
      {pageBuffer}
    </Animated>
  );
});

export default Generator;
