/**
 * Schema Entity: ListedAvastar
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */

import JSBI from 'jsbi/dist/jsbi.mjs';
import eip55 from 'eip55';
import Avastar from "./Avastar";
import web3 from 'web3';
import { TEST } from "../../constants/test";

class ListedAvastar {

    static GENERATED = 'Anonymously generated';
    static STATUS = {
        BUYING: 'buying',
        WISHING: 'wishing',
        CONSIGNED: 'consigned',
        DUPLICATE: 'duplicate',
        EXPIRED: 'expired',
        SOLD: 'sold',
        SETTLED: 'settled'
    };

    constructor(
        id,
        avastar,
        finder = ListedAvastar.GENERATED,
        timestamp,
        status = ListedAvastar.STATUS.BUYING,
        tokenId = null
    ) {
        this.id = id;
        this.avastar = avastar;
        this.updateFinder(finder);
        this.timestamp = timestamp || Date.now();
        this.status = status;
        this.tokenId = tokenId;
        this.x = web3.utils.keccak256(`${id}-${TEST}`);
    }

    /**
     * Get a new ListedAvastar instance from a database representation
     * @param o
     * @returns {ListedAvastar}
     */
    static fromObject(o) {
        let avastar = o.avastar ? Avastar.fromObject(o.avastar) : undefined;
        return new ListedAvastar(o.id, avastar, o.finder, o.timestamp, o.status, o.tokenId);
    }

    /**
     * Get a database representation of this ListedAvastar instance
     * @returns {object}
     */
    toObject() {
        return JSON.parse(JSON.stringify(this, (key, value) =>
            value instanceof JSBI
                ? value.toString()
                : value // return everything else unchanged
        ));
    }

    /**
     * Get a string representation of this ListedAvastar instance
     * @returns {String}
     */
    toString() {
        return [
            this.id,
            this.finder,
            this.timestamp,
            this.status,
            this.tokenId ? this.tokenId : null,
            this.avastar ? this.avastar.toString() : null
        ].join(', ');
    }

    /**
     * Is this ListedAvastar instance's id field valid?
     * @returns {boolean}
     */
    idIsValid() {
        let valid = false;
        try {
            valid = (
                typeof this.id === 'string'
            );
        } catch (e) { }
        return valid;
    }

    /**
     * Is this ListedAvastar instance's finder field valid?
     * @returns {boolean}
     */
    finderIsValid() {
        let valid = false;
        try {
            valid = (
                (typeof this.finder === 'string') &&
                (this.finder === ListedAvastar.GENERATED || eip55.verify(this.finder))
            );
        } catch (e) { }
        return valid;
    }

    /**
     * Is this ListedAvastar instance's avastar field valid?
     * @returns {boolean}
     */
    avastarIsValid() {
        let valid = false;
        try {
            valid = (
                !!this.avastar && this.avastar.isValid()
            );
        } catch (e) { }
        return valid;
    }

    /**
     * Is this ListedAvastar instance's timestamp field valid?
     * @returns {boolean}
     */
    timestampIsValid() {
        let valid = false;
        try {
            valid = (
                typeof this.timestamp === 'number'
            );
        } catch (e) { }
        return valid;
    }

    /**
     * Is this ListedAvastar instance's status field valid?
     * @returns {boolean}
     */
    statusIsValid() {
        let valid = false;
        try {
            valid = (
                Object
                    .values(ListedAvastar.STATUS)
                    .find(item => item === this.status)
            );
        } catch (e) { }
        return valid;
    }

    /**
     * Is this ListedAvastar instance's tokenId field valid?
     * @returns {boolean}
     */
    tokenIdIsValid() {
        let valid = false;
        try {
            valid = (
                (this.tokenId === null || this.tokenId === undefined) ||
                typeof this.id === 'string'
            );
        } catch (e) { }
        return valid;
    }

    /**
     * Is this ListedAvastar instance valid?
     * @returns {boolean}
     */
    isValid() {
        return (
            this.idIsValid() &&
            this.finderIsValid() &&
            this.avastarIsValid &&
            this.timestampIsValid() &&
            this.statusIsValid() &&
            this.tokenIdIsValid()
        );
    };

    /**
     * Update the finder field, ensuring proper eip55 encoding
     * @param f
     */
    updateFinder(f) {
        this.finder = (f === ListedAvastar.GENERATED) ? f : eip55.encode(f)
    }

    /**
     * Compare a given address to the finder field, ensuring proper eip55 encoding
     * @param f
     * @returns {boolean}
     */
    compareFinder(f) {
        return eip55.encode(f) === this.finder;
    }

    /**
     * Is the ListedAvastar anonymously generated
     * @returns {boolean}
     */
    isGenerated() {
        return this.finder === ListedAvastar.GENERATED;
    }

    /**
     * Clone this ListedAvastar
     * @returns {ListedAvastar}
     */
    clone() {
        return ListedAvastar.fromObject(this.toObject());
    }

}

export default ListedAvastar;