import ListedAvastar from "../domain/entity/ListedAvastar";

export const PURCHASE_STATUS = {
    nascent:      'Process not yet begun.',
    verifying:    'Verifying genetic uniqueness...',
    duplicates:   'One or more previously teleported Avastars were removed.',
    unique:       'Genetic uniqueness verified.',
    balance:      'Checking your wallet balance...',
    insufficient: 'Add more ETH to your wallet or remove some Avastars.',
    depositing:   'Depositing funds to operate teleporter...',
    deposited:    'Deposit complete',
    rejected:     'Deposit rejected. Why you no buy Avastars?',
    queueing:     'Queuing Avastars for teleportation...',
    teleporting:  'Teleporting Avastars. Do not add gas to previous transaction.',
    complete:     'Teleportation complete. Check for Avastar tokens in your wallet.',
    partial:      'Some attempts failed. Check your profile to see your deposit balance.',
    preparing:    {
        refund: 'Preparing to refund',
        deposit: 'Preparing to deposit'
    },
    refunding:    'Refunding balance of deposited funds...',
    empty:        'No funds remaining on deposit.',
};

export const MINTING_STATUS = {
    pending: "pending",
    minted: "minted",
    failed: "failed"
};

export const EMPTY_QUEUE = "Your queue is empty. Go discover some amazing Avastars, or connect your wallet to see your wish list and consignments.";

export const QUEUE_LISTS = {
    TELEPORTER: 'Teleporter',
    WISH_LIST: 'Wish List',
    CONSIGNED: 'Consigned'
};

export const QUEUE_REMOVE = 'Remove';

export const CONSIGNED_LISTS = {
    UNSOLD: {NAME: 'Unsold', STATUS: ListedAvastar.STATUS.CONSIGNED},
    EXPIRED: {NAME: 'Expired', STATUS: ListedAvastar.STATUS.EXPIRED},
    SOLD: {NAME: 'Sold', STATUS: ListedAvastar.STATUS.SOLD},
    SETTLED: {NAME: 'Settled', STATUS: ListedAvastar.STATUS.SETTLED}
};

export const PROFILE_DESTINATIONS = {
    PROFILE: 'Profile',
    OPENSEA: 'OpenSea',
    ETHERSCAN: 'Etherscan',
    WALLET: 'Wallet'
};

export const MAX_CONSIGNED = 10;

export const DISCOVER_FEEDS = {
    LOCAL: 'Local Feed',
    GLOBAL: 'Global Feed',
};

export const SETTLEMENT_STATUS = {
    SETTLING: 'settling',
    SETTLED: 'settled',
    FAILED: 'failed',
};

