import React, { useState, useEffect } from 'react';
import { usePrevious } from '../../../hooks';
import { ThemeContext } from 'grommet';
import { Animated } from 'react-animated-css';
import { marketplace as styles } from '../../../theme/styles';
import { WALLET_STATUS } from '../../../constants/wallet';
import {
  PURCHASE_STATUS,
  EMPTY_QUEUE,
  QUEUE_LISTS,
  QUEUE_REMOVE,
} from '../../../constants/marketplace';
import ListedAvastar from '../../../domain/entity/ListedAvastar';
import Teleporter from './Teleporter';
import Wishlist from './Wishlist';
import Consigned from './Consigned';
import {
  KitBox,
  KitHeading,
  KitNotification,
  KitParagraph,
  KitSmallButton,
} from '../../../theme/kit';
import { validateAvastar } from '../../../helpers/validate';

export default function Queue(props) {
  // State from props
  const {
    web3,
    firebase,
    account,
    walletStatus,
    teleporter,
    setTeleporter,
    wishlist,
    setWishlist,
    consigned,
    setConsigned,
    purchaseStatus,
    getConsignedTypeCount,
    getPrice,
    gasPrice,
    setGasPrice,
    series,
    maintenanceMode,
  } = props;

  // Local state
  const [tab, setTab] = useState(getFirstTabWithContent());
  const previousTab = usePrevious(tab);

  const [unsoldCount, setUnsoldCount] = useState(
    getConsignedTypeCount(ListedAvastar.STATUS.CONSIGNED)
  );
  const [soldCount, setSoldCount] = useState(
    getConsignedTypeCount(ListedAvastar.STATUS.SOLD)
  );
  const [settledCount, setSettledCount] = useState(
    getConsignedTypeCount(ListedAvastar.STATUS.SETTLED)
  );
  const [expiredCount, setExpiredCount] = useState(
    getConsignedTypeCount(ListedAvastar.STATUS.EXPIRED)
  );
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    setUnsoldCount(getConsignedTypeCount(ListedAvastar.STATUS.CONSIGNED));
    setSoldCount(getConsignedTypeCount(ListedAvastar.STATUS.SOLD));
    setSettledCount(getConsignedTypeCount(ListedAvastar.STATUS.SETTLED));
    setExpiredCount(getConsignedTypeCount(ListedAvastar.STATUS.EXPIRED));
    // eslint-disable-next-line
  }, [
    consigned,
    setUnsoldCount,
    setSoldCount,
    setSettledCount,
    setExpiredCount,
    getConsignedTypeCount,
  ]);

  // Set tab to the first tab with content when any list changes
  useEffect(() => {
    if (!currentTabHasContent()) setTab(getFirstTabWithContent());

    // eslint-disable-next-line
  }, [teleporter, wishlist, consigned]);

  const notification = () => {
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 5000);
  };

  // Does the current tab have content?
  function currentTabHasContent() {
    let result;
    switch (tab) {
      case QUEUE_LISTS.TELEPORTER:
        result = !!teleporter.length;
        break;
      case QUEUE_LISTS.WISH_LIST:
        result = !!wishlist.length;
        break;
      case QUEUE_LISTS.CONSIGNED:
        result = !!consigned.length;
        break;
      default:
    }
    return result;
  }

  // Find the first tab with content
  function getFirstTabWithContent() {
    return !!teleporter.length
      ? QUEUE_LISTS.TELEPORTER
      : !!wishlist.length
      ? QUEUE_LISTS.WISH_LIST
      : !!consigned.length
      ? QUEUE_LISTS.CONSIGNED
      : null;
  }

  /**
   * Move an item from one queue list to another
   *  - item must be a ListedAvastar instance
   *  - from and to must be a constant from QUEUE_LISTS
   */
  const triageItem = (item, from, to) => {
    console.log(`${from} => ${to} ${item.id}`);
    let promises = [];

    // Moving item from...
    switch (from) {
      case QUEUE_LISTS.TELEPORTER:
        // Refresh timestamp when moving out of teleporter
        item.timestamp = Date.now();

        // Set finder to connected account if anonymously generated
        if (account && item.isGenerated) item.updateFinder(account);

        // New Teleporter
        const tp = teleporter.filter((i) => i.id !== item.id);

        // Move to Wishlist or Consigned
        if (to === QUEUE_LISTS.WISH_LIST) {
          // Teleporter => Wishlist
          item.status = ListedAvastar.STATUS.WISHING;

          // New Wishlist
          const wl = [...wishlist, item];

          // remove from teleporter
          setTeleporter(tp);

          // add to wishlist
          setWishlist(wl);
          promises.push(firebase.addToWishlist(account, item));
        } else if (to === QUEUE_LISTS.CONSIGNED) {
          if (!validateAvastar(item, series)) {
            notification();
            // remove from teleporter
            setTeleporter(tp);
            return;
          }

          // Teleporter => Consigned
          item.status = ListedAvastar.STATUS.CONSIGNED;

          // New Consigned
          const co = [...consigned, item];

          // remove from teleporter
          setTeleporter(tp);

          // add to consigned
          setConsigned(co);
          promises.push(firebase.addToConsigned(account, item));
        } else if (to === QUEUE_REMOVE) {
          // remove from teleporter
          setTeleporter(tp);
        }
        break;

      case QUEUE_LISTS.WISH_LIST:
        // Refresh timestamp when moving out of wishlist
        item.timestamp = Date.now();

        // New wishlist
        const wl = wishlist.filter((i) => i.id !== item.id);

        // Move to Teleporter or Consigned
        if (to === QUEUE_LISTS.TELEPORTER) {
          // Wishlist => Teleporter
          item.status = ListedAvastar.STATUS.BUYING;

          // New Teleporter
          const tp = [...teleporter, item];

          // remove from wishlist
          setWishlist(wl);
          promises.push(firebase.removeFromWishlist(account, item));

          // add to teleporter
          setTeleporter(tp);
        } else if (to === QUEUE_LISTS.CONSIGNED) {
          if (!validateAvastar(item, series)) {
            notification();
            // remove from wishlist
            setWishlist(wl);
            return;
          }

          // Wishlist => Consigned
          item.status = ListedAvastar.STATUS.CONSIGNED;

          // New Consigned
          const co = [...consigned, item];

          // remove from wishlist
          setWishlist(wl);
          promises.push(firebase.removeFromWishlist(account, item));

          // add to consigned
          setConsigned(co);
          promises.push(firebase.addToConsigned(account, item));
        }
        break;

      case QUEUE_LISTS.CONSIGNED:
        // New consigned
        const co = consigned.filter((i) => i.id !== item.id);

        // Move to Teleporter or Wishlist
        if (to === QUEUE_LISTS.TELEPORTER) {
          // Consigned => Teleporter
          item.status = ListedAvastar.STATUS.BUYING;

          // New Teleporter
          const tp = [...teleporter, item];

          // remove from consigned
          setConsigned(co);
          promises.push(firebase.removeFromConsigned(account, item));

          // add to teleporter
          setTeleporter(tp);
        } else if (to === QUEUE_LISTS.WISH_LIST) {
          // Consigned => Wishlist
          item.status = ListedAvastar.STATUS.WISHING;

          // New Wishlist
          const wl = [...wishlist, item];

          // remove from consigned
          setConsigned(co);
          promises.push(firebase.removeFromConsigned(account, item));

          // add to teleporter
          setWishlist(wl);
          promises.push(firebase.addToWishlist(account, item));
        }
        break;

      default:
    }
  };

  // (Used by lists)
  // Calculate the total for the given list
  function calculateTotal(list) {
    const total = list.reduce((accumulator, listed) => {
      const price = web3
        ? Number(
            web3.utils
              .toBN(web3.utils.toWei(String(getPrice(listed)), 'ether'))
              .add(web3.utils.toBN(gasPrice))
              .toString()
          )
        : getPrice(listed);
      return web3
        ? web3.utils.fromWei(
            web3.utils
              .toBN(web3.utils.toWei(String(accumulator), 'ether'))
              .add(web3.utils.toBN(price))
              .toString(),
            'ether'
          )
        : accumulator + price;
    }, 0);
    return total;
  }

  // Render the the header copy with tabs or divider below it
  function renderCopy() {
    return (
      <>
        <KitHeading level="1" color="secondary">
          Applicant Queue
        </KitHeading>
        <KitBox>
          <KitParagraph fill={true}>
            {account ? 'Manage ' : 'Connect your ETH wallet and manage '}your
            queued Applicants. Remove any you may be having second thoughts
            about and Teleport the ones that are too good to pass up! Once
            Teleported, Avastars will appear in your Profile and wallet.
            {walletStatus === WALLET_STATUS.connected ? null : (
              <>
                <br />
                <br />
                Not enough ETH in your wallet to operate the Teleporter right
                now? No problem! Once your wallet is connected, you'll have
                other free options.
              </>
            )}
            {walletStatus === WALLET_STATUS.connected ? (
              <>
                <br />
                <br />
                You may put Applicants on your <b>Wish List</b> and Teleport
                them later, but be warned; while it's unlikely, if someone
                Teleports a candidate with the same Traits first, you won't be
                able to.
                <br />
                <br />
                If your wallet <u>owns at least one Avastar</u>, you may{' '}
                <b>Consign</b> up to 10 Applicants to the Global Feed and
                receive a finder's fee when they're purchased.
              </>
            ) : null}
          </KitParagraph>
        </KitBox>
        {renderTabs()}
      </>
    );
  }

  // Render the tabs
  function renderTabs() {
    return (
      <ThemeContext.Consumer>
        {(theme) => {
          const backgroundColor = theme.dark
            ? theme.global.colors['background-back'].dark
            : theme.global.colors['background-back'].light;

          const selectedColor = theme.dark
            ? theme.global.colors['secondary'].dark
            : theme.global.colors['secondary'].light;

          const disabledBgColor = theme.dark
            ? theme.global.colors['dark-6']
            : theme.global.colors['light-6'];

          const divColor = theme.dark
            ? theme.global.colors['highlight'].dark
            : theme.global.colors['highlight'].light;

          const selectedTabStyles = {
            ...styles.tabSelected,
            backgroundColor: backgroundColor,
            color: selectedColor,
          };
          const disabledTabStyles = {
            opacity: 1,
            backgroundColor: disabledBgColor,
            borderColor: disabledBgColor,
          };

          // Teleporter styles
          let teleporterDisabled = false,
            teleporterStyles = { ...styles.tabs, ...styles.tabLeft };
          if (tab === QUEUE_LISTS.TELEPORTER)
            teleporterStyles = { ...teleporterStyles, ...selectedTabStyles };
          if (!teleporter.length || maintenanceMode) {
            teleporterDisabled = true;
            teleporterStyles = { ...teleporterStyles, ...disabledTabStyles };
          }

          // Wishlist Styles
          let wishlistDisabled = false,
            wishlistStyles = { ...styles.tabs, ...styles.tabMiddle };
          if (tab === QUEUE_LISTS.WISH_LIST)
            wishlistStyles = { ...wishlistStyles, ...selectedTabStyles };
          if (
            !wishlist.length ||
            purchaseStatus !== PURCHASE_STATUS.nascent ||
            maintenanceMode
          ) {
            wishlistDisabled = true;
            wishlistStyles = { ...wishlistStyles, ...disabledTabStyles };
          }

          // Consigned styles
          let consignedDisabled = false,
            consignedStyles = { ...styles.tabs, ...styles.tabRight };
          if (tab === QUEUE_LISTS.CONSIGNED)
            consignedStyles = { ...consignedStyles, ...selectedTabStyles };
          if (!consigned.length || purchaseStatus !== PURCHASE_STATUS.nascent) {
            consignedDisabled = true;
            consignedStyles = { ...consignedStyles, ...disabledTabStyles };
          }

          const tabBarStyles = {
            ...styles.tabBar,
            backgroundColor,
            borderBottom: `2px solid ${divColor}`,
          };

          return (
            <>
              <div style={tabBarStyles}>
                {walletStatus === WALLET_STATUS.connected ? (
                  <div style={styles.tabBarGroup}>
                    {!maintenanceMode && (
                      <KitSmallButton
                        style={teleporterStyles}
                        disabled={teleporterDisabled}
                        onClick={() => setTab(QUEUE_LISTS.TELEPORTER)}
                      >
                        Teleporter ({teleporter.length})
                      </KitSmallButton>
                    )}
                    <KitSmallButton
                      style={wishlistStyles}
                      disabled={wishlistDisabled}
                      onClick={() => setTab(QUEUE_LISTS.WISH_LIST)}
                    >
                      Wish&nbsp;List ({wishlist.length})
                    </KitSmallButton>
                    <KitSmallButton
                      style={consignedStyles}
                      disabled={consignedDisabled}
                      onClick={() => setTab(QUEUE_LISTS.CONSIGNED)}
                    >
                      Consigned ({unsoldCount})
                      {consigned.length > unsoldCount ? <>&nbsp;...</> : null}
                    </KitSmallButton>
                  </div>
                ) : (
                  <div style={styles.tabBarGroup}>
                    <KitSmallButton
                      style={{ ...teleporterStyles, visibility: 'hidden' }}
                    >
                      NOP
                    </KitSmallButton>
                  </div>
                )}
              </div>
            </>
          );
        }}
      </ThemeContext.Consumer>
    );
  }

  // Render the queue
  function renderQueue() {
    let queue;

    switch (tab) {
      case QUEUE_LISTS.TELEPORTER:
        queue = !maintenanceMode ? (
          <Teleporter
            calculateTotal={calculateTotal}
            unsoldCount={unsoldCount}
            transition={!previousTab ? 'fadeIn' : 'slideInLeft'}
            removeFromQueue={(item) =>
              triageItem(item, QUEUE_LISTS.TELEPORTER, QUEUE_REMOVE)
            }
            moveToWishlist={(item) =>
              triageItem(item, QUEUE_LISTS.TELEPORTER, QUEUE_LISTS.WISH_LIST)
            }
            moveToConsigned={(item) =>
              triageItem(item, QUEUE_LISTS.TELEPORTER, QUEUE_LISTS.CONSIGNED)
            }
            gasPrice={gasPrice}
            setGasPrice={setGasPrice}
            {...props}
          />
        ) : null;
        break;

      case QUEUE_LISTS.WISH_LIST:
        queue = (
          <Wishlist
            calculateTotal={calculateTotal}
            unsoldCount={unsoldCount}
            transition={
              previousTab === QUEUE_LISTS.TELEPORTER
                ? 'slideInRight'
                : 'slideInLeft'
            }
            moveToTeleporter={(item) =>
              triageItem(item, QUEUE_LISTS.WISH_LIST, QUEUE_LISTS.TELEPORTER)
            }
            moveToConsigned={(item) =>
              triageItem(item, QUEUE_LISTS.WISH_LIST, QUEUE_LISTS.CONSIGNED)
            }
            gasPrice={gasPrice}
            setGasPrice={setGasPrice}
            {...props}
          />
        );
        break;

      case QUEUE_LISTS.CONSIGNED:
        queue = (
          <Consigned
            unsoldCount={unsoldCount}
            soldCount={soldCount}
            settledCount={settledCount}
            expiredCount={expiredCount}
            moveToTeleporter={(item) =>
              triageItem(item, QUEUE_LISTS.CONSIGNED, QUEUE_LISTS.TELEPORTER)
            }
            moveToWishlist={(item) =>
              triageItem(item, QUEUE_LISTS.CONSIGNED, QUEUE_LISTS.WISH_LIST)
            }
            {...props}
          />
        );
        break;

      default:
        queue = null;
    }

    return queue;
  }

  // Render Queue
  return (
    <>
      <Animated
        animationIn="fadeIn"
        animateOnMount={true}
        animationInDuration={325}
        animationInDelay={0}
      >
        <div style={styles.page}>
          {teleporter.length + wishlist.length + consigned.length > 0 ? (
            <>
              {renderCopy()}
              {renderQueue()}
            </>
          ) : (
            <div style={styles.queueContainer}>{EMPTY_QUEUE}</div>
          )}
        </div>
      </Animated>
      {showNotification && (
        <KitNotification>
          Avastar has invalid traits, based on the current series. Avastar has
          been removed from the system.
        </KitNotification>
      )}
    </>
  );
}
