import PrimeMinterJSON from '../contracts/AvastarPrimeMinter.json';

const getHost = function () {
  return window.location.host.split('.')[0];
};

export const getFirebaseConfig = function () {
  const environments = {
    dev: {
      apiKey: 'AIzaSyCKSDl_nmm3Sr_jl9se_npVkXLdb5I3HJ0',
      authDomain: 'avastars-new-dev.firebaseapp.com',
      databaseURL: 'wss://avastars-new-dev.firebaseio.com',
      projectId: 'avastars-new-dev',
      storageBucket: 'avastars-new-dev.appspot.com',
      messagingSenderId: '858143624008',
      appId: '1:858143624008:web:8dc2805e8ef7c091b6017d',
    },

    test: {
      apiKey: 'AIzaSyAnZR_ThPOzYCkvHXy72M6FnAo1MDmkTJg',
      authDomain: 'avastars-new-test.firebaseapp.com',
      databaseURL: 'wss://avastars-new-test.firebaseio.com',
      projectId: 'avastars-new-test',
      storageBucket: 'avastars-new-test.appspot.com',
      messagingSenderId: '529721137667',
      appId: '1:529721137667:web:6d46a5a33e068ad4df0c36',
    },

    prod: {
      apiKey: 'AIzaSyBaftw2ukdpWEvQ343VjjP4P8Snw7JISc4',
      authDomain: 'avastars-prod.firebaseapp.com',
      databaseURL: 'wss://avastars-prod.firebaseio.com',
      projectId: 'avastars-prod',
      storageBucket: 'avastars-prod.appspot.com',
      messagingSenderId: '571735748739',
      appId: '1:571735748739:web:0eba8e0f94e956563aebb6',
    },
  };
  let config,
    host = getHost();
  switch (host) {
    case 'dev':
    case 'localhost:3000':
      config = environments['dev'];
      break;

    case 'avastars':
      config = environments['prod'];
      break;

    default:
      config = environments[host];
  }

  return config;
};

export const getBlockchainConfig = function () {
  const environments = {
    dev: {
      primeMinter: {
        abi: PrimeMinterJSON.abi,
        address: '0x6AcA8E0B2C39F807077667d1d788CCD4223FA1D7',
      },
      teleporter: {
        abi: null,
        address: '0x30E011460AB086a0daA117DF3c87Ec0c283A986E',
      },
    },

    test: {
      primeMinter: {
        abi: PrimeMinterJSON.abi,
        address: '0x6AcA8E0B2C39F807077667d1d788CCD4223FA1D7',
      },
      teleporter: {
        abi: null,
        address: '0x30E011460AB086a0daA117DF3c87Ec0c283A986E',
      },
    },

    prod: {
      primeMinter: {
        abi: PrimeMinterJSON.abi,
        address: '0xe31763aad9294f073ddf18b36503ed037ae5e737',
      },
      teleporter: {
        abi: null,
        address: '0xf3e778f839934fc819cfa1040aabacecba01e049',
      },
    },
  };

  let config,
    host = getHost();
  switch (host) {
    case 'dev':
    case 'localhost:3000':
      config = environments['dev'];
      break;

    case 'avastars':
      config = environments['prod'];
      break;

    default:
      config = environments[host];
  }

  return config;
};

export const getWebConfig = function () {
  const environments = {
    dev: {
      host: 'http://dev.avastars.io',
    },

    test: {
      host: 'http://test.avastars.io',
    },

    prod: {
      host: 'http://avastars.io',
    },
  };

  let config,
    host = getHost();
  switch (host) {
    case 'dev':
    case 'localhost:3000':
      config = environments['dev'];
      break;

    case 'avastars':
      config = environments['prod'];
      break;

    default:
      config = environments[host];
  }

  return config;
};

export const getInfuraIds = function () {
  const environments = {
    dev: {
      id: 'c9f53c0be8b2491cb7ca12e8aae46a6f',
    },
    test: {
      id: 'c9f53c0be8b2491cb7ca12e8aae46a6f',
    },
    prod: {
      id: 'c9f53c0be8b2491cb7ca12e8aae46a6f',
    },
  };

  let config,
    host = getHost();
  switch (host) {
    case 'dev':
    case 'localhost:3000':
      config = environments['dev'].id;
      break;

    case 'avastars':
      config = environments['prod'].id;
      break;

    default:
      config = environments[host].id;
  }

  return config;
};
