import React from 'react';
import { Animated } from 'react-animated-css';

import {
  KitBox,
  KitCardBorder,
  KitCardHeader,
  KitCardTitle,
  KitCardDivider,
  KitCardBody,
  KitParagraph,
  TwitterIcon,
  DiscordIcon,
} from '../../theme/kit/index';

const MaintenanceBanner = () => {
  return (
    <Animated
      animationIn="zoomIn"
      animateOnMount={true}
      animationInDuration={400}
      animationInDelay={200}
    >
      <KitBox>
        <KitCardBorder
          inset
          angled
          style={{ maxWidth: '100%', width: '100%', margin: 0 }}
        >
          <KitCardHeader size="small" angled>
            <KitCardTitle style={{ width: '100%', maxWidth: 'initial' }}>
              REPLICANTS: Coming Nov. 17th.{' '}
              <a
                href="https://twitter.com/AvastarsNFT"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon />
              </a>{' '}
              <a
                href="https://discord.gg/Jk3E3w33aY"
                target="_blank"
                rel="noopener noreferrer"
              >
                <DiscordIcon />
              </a>
            </KitCardTitle>
          </KitCardHeader>
          <KitCardDivider />
          <KitCardBody background="background-front">
            <KitBox>
              <KitParagraph
                fill={true}
                margin="none"
                style={{ marginTop: '10px' }}
              >
                Visit our socials via the links above! Scroll down to read all
                about Avastars!
              </KitParagraph>
            </KitBox>
          </KitCardBody>
        </KitCardBorder>
      </KitBox>
      <br />
      <KitBox>
        <KitCardBorder
          inset
          angled
          style={{ maxWidth: '100%', width: '100%', margin: 0 }}
        >
          <KitCardHeader size="small" angled>
            <KitCardTitle style={{ width: '100%', maxWidth: 'initial' }}>
              Click an Avastar to go to OpenSea & view all 25,200 Avastars, and
              purchase your own!
            </KitCardTitle>
          </KitCardHeader>
          <KitCardDivider />
          <KitCardBody background="background-front">
            <KitBox>
              <KitParagraph fill={true} style={{ margin: '10px' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexFlow: 'row',
                    height: '330px',
                  }}
                >
                  <a
                    href="https://opensea.io/collection/avastar"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/replicants-4bdc4.appspot.com/o/flamelink%2Fmedia%2Fsized%2F375_9999_100%2FScreen%20Shot%202021-10-28%20at%203.35.13%20PM.png?alt=media&token=5dfd795f-dba3-435b-8a62-1b059135a266"
                      height="100%"
                      alt="avastar"
                    />
                  </a>
                  <a
                    href="https://opensea.io/collection/avastar"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/replicants-4bdc4.appspot.com/o/flamelink%2Fmedia%2Fsized%2F375_9999_100%2FScreen%20Shot%202021-09-10%20at%201.17.20%20PM.png?alt=media&token=7616879b-335a-4198-abda-7ae947d04113"
                      height="100%"
                      alt="avastar"
                    />
                  </a>
                  <a
                    href="https://opensea.io/collection/avastar"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/replicants-4bdc4.appspot.com/o/flamelink%2Fmedia%2Fsized%2F375_9999_100%2FAvastar%20%23100.png?alt=media&token=f84262af-8f0a-41c0-95d1-660ef0ed4469"
                      height="100%"
                      alt="avastar"
                    />
                  </a>
                </div>
              </KitParagraph>
            </KitBox>
          </KitCardBody>
        </KitCardBorder>
      </KitBox>
      <br />
      <KitCardDivider />
      <br />
    </Animated>
  );
};

export default MaintenanceBanner;
