/**
 * Schema Enum: Rarity
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */

class Rarity {}

Rarity.ANY       = "Any";
Rarity.COMMON    = "Common";
Rarity.UNCOMMON  = "Uncommon";
Rarity.RARE      = "Rare";
Rarity.EPIC      = "Epic";
Rarity.LEGENDARY = "Legendary";

Rarity.LEVELS     = [
    Rarity.COMMON,
    Rarity.UNCOMMON,
    Rarity.RARE,
    Rarity.EPIC,
    Rarity.LEGENDARY
];

// Annoying hack such that we never allow specific rarity
// percentages to appear searchable in deployed code, even
// after obfuscation has been applied
Rarity.QUARTER = .25;
Rarity.HALF = Rarity.QUARTER + Rarity.QUARTER;
Rarity.HUNDRED = 100;
Rarity.HUNDREDTH = 1/Rarity.HUNDRED;
Rarity.TWELVE = 48 * Rarity.QUARTER;
Rarity.TWENTYTWO = (176 *  Rarity.HALF) * Rarity.QUARTER;
Rarity.FOUR = 400 * Rarity.HUNDREDTH;
Rarity.SIXTY = 240 * Rarity.QUARTER;
Rarity.ZERO = Rarity.SIXTY - Rarity.SIXTY;
Rarity.PERCENTAGES = {
    [Rarity.COMMON]: Rarity.SIXTY,
    [Rarity.UNCOMMON]: Rarity.QUARTER + Rarity.TWENTYTWO + Rarity.QUARTER, // 22.5
    [Rarity.RARE]: Rarity.TWELVE  + Rarity.HALF, // 12.5
    [Rarity.EPIC]: Rarity.HALF + Rarity.QUARTER + Rarity.FOUR, // 4.75
    [Rarity.LEGENDARY]: 1 - (Rarity.HALF + Rarity.QUARTER) // .25
};

Rarity.distribution = Rarity.LEVELS.map((rarity, index) => ({
    name: rarity,
    level: index + 1,
    percent: Rarity.PERCENTAGES[rarity],
    chance: Rarity.PERCENTAGES[rarity] * Rarity.HUNDREDTH,
    score: Rarity.HUNDRED - Rarity.PERCENTAGES[rarity]
}));

export default Rarity;