/**
 * Schema Enum: Generation
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
class Generation {}

Generation.ANY     = "Any";

Generation.ONE      = 1;
Generation.TWO      = 2;
Generation.THREE    = 3;
Generation.FOUR     = 4;
Generation.FIVE     = 5;

Generation.NUMBERS  = [Generation.ONE, Generation.TWO, Generation.THREE, Generation.FOUR, Generation.FIVE];


export default Generation;