import React from "react";
import {Animated} from "react-animated-css";
import {marketplace as styles} from "../../../theme/styles";
import {WALLET_STATUS} from "../../../constants/wallet";
import {QUEUE_LISTS} from "../../../constants/marketplace";
import {MEDIA} from "../../../constants/media";
import AvastarRow from "../Avastar/AvastarRow";
import {
  KitHeaderField,
  KitList,
  KitRow,
  KitColumn,
  KitHeader,
  KitHeading
} from "../../../theme/kit";

export default function Wishlist(props) {

  // State from props
  const {
    account,
    network,
    transition,
    desiredNetName,
    walletStatus,
    wishlist,
    truncatedAccount,
    smallDisplay,
    calculateTotal,
    mediaSize,
    gasPrice
  } = props;

  // Render the wish list
  function renderList() {
    return (
      <KitList>
        <KitRow displayMode="header">
          <KitColumn justify="center">
            <KitHeading level="3" color="secondary">
              {QUEUE_LISTS.WISH_LIST}
            </KitHeading>
          </KitColumn>
        </KitRow>
        <KitRow displayMode="header" background="background-front">
          <KitColumn>
            <span style={smallDisplay ? styles.columnHeader : null}>
              &nbsp;Avastar
            </span>
          </KitColumn>
          {smallDisplay ? null : (
            <>
              <KitColumn justify="center">
                <span style={smallDisplay ? styles.columnHeader : null}>
                  Gen/Series
                </span>
              </KitColumn>
              <KitColumn justify="center">
                <span style={smallDisplay ? styles.columnHeader : null}>
                  Rarity
                </span>
              </KitColumn>
            </>
          )}
          <KitColumn justify="center">
            <span style={smallDisplay ? styles.columnHeader : null}>Price</span>
          </KitColumn>
          {gasPrice === 0 ? null : (
            <KitColumn justify="center">
              <span style={smallDisplay ? styles.columnHeader : null}>
                Gas Cost
              </span>
            </KitColumn>
          )}
          <KitColumn justify="end" pad={{ right: 'medium' }}>
            <span style={smallDisplay ? styles.columnHeader : null}>
              <span>Actions</span>
            </span>
          </KitColumn>
        </KitRow>
        {renderAvastars()}
      </KitList>
    );
  }

  // Render the Avastars in the wishlist queue as rows
  function renderAvastars() {
    return wishlist.map(listed => (
        <AvastarRow
            {...props}
            key={listed.id}
            listed={listed}
            inWishlist={true}
            showActions={true}
        />
    ));
  }

  // Render the user's network connection status
  function renderConnectionStatus() {
    return (!!account)
        ? <span>
            {network !== "" ? <span>{network}</span> : null}
          </span>
        : walletStatus;
  }

  // Render the wallet or a message asking to connect to the desired network
  function renderWalletAddress() {
    return (walletStatus === WALLET_STATUS.wrong)
        ? <KitHeaderField><span>Please connect to {desiredNetName}&nbsp;&nbsp;</span></KitHeaderField>
        : <span style={styles.checkoutNumeric}>{truncatedAccount}</span>;
  }

  // Render wishlist
  return (
      <Animated
          animationIn={transition}
          animateOnMount={true}
          animationInDuration={350}
          animationInDelay={0}>
          <div style={styles.queueList}>
            <KitHeader withTabs={true} smallTabs={mediaSize!==MEDIA.MOBILE.SIZE} displayMode={mediaSize===MEDIA.MOBILE.SIZE ? "mobile" : "desktop"}>

              <KitHeaderField>
                Total:<span style={styles.checkoutNumeric}>Ξ&nbsp;{calculateTotal(wishlist)}</span>
              </KitHeaderField>

              {renderConnectionStatus()}

              {renderWalletAddress()}

            </KitHeader>
            {renderList()}
          </div>
      </Animated>
  );

}