import React, {forwardRef, useImperativeHandle} from "react";
import {Animated} from "react-animated-css";
import {marketplace as styles} from "../../../theme/styles";
import {KitHeader, KitHeaderField, KitHeading, KitLargeButton, KitMenu, KitSeparator} from "../../../theme/kit";

const Consignments = forwardRef(
    (props, ref) => {

    // State from props
    const {
        firebase,
        account,
        setFeedHasMore,
        pageBuffer,
        setPageBuffer,
        bufferAvastars,
        transition,
        nextPage,
        setNextPage,
        pageSize,
        smallDisplay,
        showFilter,
        globalFilter,
        allFinders,
        clearFilter,
        setFilter,
        setAllFinders,
        truncateAccount,
    } = props;

    // Expose loadMoreConsignments function to parent
    useImperativeHandle(ref, () => ({

        // Load more consigned Avastars from the feed
        loadMoreConsignments() {

            firebase
                .fetchGlobalFeedPage(nextPage, pageSize)
                .then(response => {
                    setAllFinders(response.allFinders);
                    const population = bufferAvastars(response.listedAvastars, pageBuffer);
                    setFeedHasMore(response.currentPage < response.lastPage);
                    setPageBuffer(population);
                    setNextPage(response.nextPage || 1);
                });
        },

        // Load the
        loadFilteredConsignments() {

            firebase
                .fetchGlobalFeedFiltered(globalFilter)
                .then(response => {
                    const population = bufferAvastars(response.listedAvastars, []);
                    setFeedHasMore(false);
                    setPageBuffer(population);
                    setNextPage(1);
                });
        }

    }));

    function renderAction() {
        let action;
        let clearButton = <KitLargeButton onClick={clearFilter}> Clear </KitLargeButton>;
        if  (allFinders.length) {

            let items = allFinders.map(finder => ({ label: truncateAccount(finder), onClick: () => setFilter(finder) }));
            action = (
                <span style={styles.clickable}>
                    <KitHeading level="3" color="secondary">
                        <KitMenu label="Finders" items={items}/>
                        {globalFilter ? <><KitSeparator/>{clearButton}</> : null}
                    </KitHeading>
                </span>
            );
        } else {
            action = <KitHeaderField>{clearButton}</KitHeaderField>;
        }
        return action;
    }

    function renderFilter() {
        return globalFilter
            ? <span style={styles.checkoutNumeric}><span>{truncateAccount(globalFilter)}</span>{globalFilter === account ? <b> (you)</b> : null}</span>
            : null;
    }

    // Render the Avastar browser
    // Longer animation delay and duration needed
    // because switching between feeds can take up time
    // that causes the animation to skip frames to land on time
    return <Animated
        animationIn={transition}
        animateOnMount={true}
        animationInDuration={400}
        animationInDelay={100}>
        {showFilter
            ? <KitHeader withTabs={true} shortTabs={true} displayMode={smallDisplay ? "mobile" : "desktop"}>
                <KitHeaderField>Filter by Finder</KitHeaderField>
                {renderFilter()}
                {renderAction()}
              </KitHeader>
            : null
        }
        {pageBuffer}
    </Animated>;

});

export default Consignments;
