/**
 * Schema Enum: Series
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
class Series {}

Series.ANY     = "Any";

Series.PROMOS   = 0;
Series.ONE      = 1;
Series.TWO      = 2;
Series.THREE    = 3;
Series.FOUR     = 4;
Series.FIVE     = 5;

Series.NUMBERS  = [Series.ONE, Series.TWO, Series.THREE, Series.FOUR, Series.FIVE];


export default Series;