import React, { useContext } from 'react';
import { Animated } from 'react-animated-css';
import { ResponsiveContext } from 'grommet';
import { staticPages as styles } from '../../../theme/styles';
import founder0 from '../../../theme/kit/assets/founder0.svg';
import founder1 from '../../../theme/kit/assets/founder1.svg';
import MaintenanceBanner from '../../Maintenance';
import RarityIcon from '../Avastar/RarityIcon';
import {
  KitBox,
  KitHeading,
  KitParagraph,
  KitCard,
  KitRow,
  KitCardBorder,
  KitCardHeader,
  KitCardFooter,
  KitCardTitle,
  KitCardBody,
  KitCardDivider,
  KitCardImageWrap,
  KitTraitRow,
  KitColumn,
} from '../../../theme/kit';

export default function Intro(props) {
  const responsiveSize = useContext(ResponsiveContext);
  const paraFill = responsiveSize === 'small';
  const ResponsiveRow = (props) => {
    const { align = 'start' } = props;
    return (
      <KitBox
        direction={responsiveSize === 'small' ? 'column' : 'row'}
        margin={
          responsiveSize === 'small'
            ? { vertical: 'medium' }
            : { top: 'medium' }
        }
        basis="100%"
        align={align}
        {...props}
      />
    );
  };
  const renderBackgroundSection1 = () => (
    <KitBox basis={responsiveSize === 'small' ? '3/3' : '3/3'}>
      <KitHeading level="3" color="secondary">
        Building a Brave New World...
      </KitHeading>
      <KitParagraph
        style={paraFill ? null : { marginRight: '2rem' }}
        fill={paraFill}
      >
        In the year 1984, Avastars Corp launched an advanced spaceship, Genesis
        721, from a secret location on Earth. Its mission: Find and populate a
        suitable planet in a nearby solar system.
      </KitParagraph>
      <KitParagraph
        style={paraFill ? null : { marginRight: '2rem' }}
        fill={paraFill}
      >
        Thirty-five years later, the crew of Genesis 721 are awoken from their
        cryogenic sleep by the onboard AI upon the discovery of Planet Avastar.
        The team has confirmed the AI’s readings of the planet and have sent a
        quantum message to Earth instructing them to turn on the teleporters.
      </KitParagraph>
      <KitParagraph
        style={paraFill ? null : { marginRight: '2rem' }}
        fill={paraFill}
      >
        You have been assigned the job of Teleporter Engineer. You must{' '}
        <b>discover and select</b> the best Applicants to be citizens of this
        new world. Avastars Corp has decided to call these humans Avastar
        Primes; the first citizens of a planet outside our solar system, and the
        future of the human race.
      </KitParagraph>
    </KitBox>
  );

  const renderBackgroundSection2 = () => (
    <KitBox
      basis={
        responsiveSize === 'small'
          ? '3/3'
          : responsiveSize === 'tablet'
          ? '2/2'
          : '1/2'
      }
    >
      <KitParagraph
        style={paraFill ? null : { maxWidth: 'unset', marginRight: '2rem' }}
        fill={paraFill}
      >
        After passing a battery of genetic tests, the Applicant can be
        teleported to Planet Avastar and begin their new life as an Avastar
        Prime.
      </KitParagraph>
      <KitParagraph
        style={paraFill ? null : { maxWidth: 'unset', marginRight: '2rem' }}
        fill={paraFill}
      >
        For the good of the colony, no two Primes can be alike; each one is
        genetically unique.
      </KitParagraph>
      <KitParagraph
        style={paraFill ? null : { maxWidth: 'unset', marginRight: '2rem' }}
        fill={paraFill}
      >
        Some Applicants are rarer than others, a difference which is determined
        by their genetic makeup.
      </KitParagraph>
    </KitBox>
  );
  const renderBackgroundSection3 = () => (
    <KitBox
      basis={
        responsiveSize === 'small'
          ? '3/3'
          : responsiveSize === 'tablet'
          ? '2/2'
          : '1/2'
      }
    >
      <KitParagraph
        style={paraFill ? null : { maxWidth: 'unset', marginRight: '2rem' }}
        fill={paraFill}
      >
        Each Gene has Traits, and traits have five distinct Rarity levels:
        Common, Uncommon, Rare, Epic, and Legendary.
      </KitParagraph>
      <KitParagraph
        style={paraFill ? null : { maxWidth: 'unset' }}
        fill={paraFill}
      >
        The cumulative score of these trait rarities in a given Applicant makes
        up the Rarity Score, which goes from 1-100.
      </KitParagraph>
      <KitParagraph
        style={paraFill ? null : { maxWidth: 'unset' }}
        fill={paraFill}
      >
        Within this range, there are five Rarity Ranks, which follow the same
        naming convention as the Trait rarities — the cost to teleport
        Applicants increases with the Rarity Rank.
      </KitParagraph>
    </KitBox>
  );

  const renderBackgroundSection4 = () => (
    <KitBox
      basis={
        responsiveSize === 'small'
          ? '3/3'
          : responsiveSize === 'tablet'
          ? '2/2'
          : '1/2'
      }
    >
      <KitParagraph
        style={paraFill ? null : { maxWidth: 'unset', marginRight: '2rem' }}
        fill={paraFill}
      >
        Due to the limited capabilities of teleporters from the 1980s, they can
        only work for a short period, so Avastars Corp has divided the migration
        to Planet Avastar into five Series. Each Series will be limited to 5000
        Avastar Primes. Once Series 5 is complete, the teleporters will be shut
        down.
      </KitParagraph>
      <KitParagraph
        style={paraFill ? null : { maxWidth: 'unset', marginRight: '2rem' }}
        fill={paraFill}
      >
        However, Genesis 721 also brought a Replication Factory in the cargo
        hold, and the crew will be busy assembling it during the teleportation
        period. Once assembly is complete, Engineers will be able to operate the
        Replication Factory and create Avastar Replicants using the Traits in
        the Primes they teleported. When an Engineer teleports a Prime, the
        teleporter saves one Copy of its traits. Each Trait Copy can be used
        once by the Replicant Factory in the assembly of an Avastar Replicant.
      </KitParagraph>
    </KitBox>
  );

  const renderBackgroundSection5 = () => (
    <KitBox
      basis={
        responsiveSize === 'small'
          ? '3/3'
          : responsiveSize === 'tablet'
          ? '2/2'
          : '1/2'
      }
    >
      <KitParagraph
        style={paraFill ? null : { maxWidth: 'unset', marginRight: '2rem' }}
        fill={paraFill}
      >
        Each Avastar Replicant must be genetically unique, which the Factory
        will verify before allowing for replication. The Factory runs on ETH to
        create Replicants. If an Engineer does not have enough ETH to create a
        Replicant, they can offer the use of any Trait Copies they own to the
        other Engineers for a fee. If another Engineer elects to apply the Copy
        in the creation of a Replicant, this will use the Trait Copy
        permanently.
      </KitParagraph>
      <KitParagraph
        style={paraFill ? null : { maxWidth: 'unset', marginRight: '2rem' }}
        fill={paraFill}
      >
        Because of the Trait Copy’s one-time use limitation, there can only ever
        be as many Replicants as there are Primes. Together, Primes and
        Replicants will be known as Generation 1 Avastars, or “Gen1”. If the
        Colony thrives, other Generations will follow, each as diverse as the
        first.
      </KitParagraph>
    </KitBox>
  );

  const renderRequirementsSection = () => (
    <KitBox basis={responsiveSize === 'small' ? '3/3' : '2/3'}>
      <KitHeading level="3" color="secondary">
        Requirements
      </KitHeading>
      <KitParagraph
        style={paraFill ? null : { maxWidth: 'unset', marginRight: '2rem' }}
        fill={paraFill}
      >
        Your teleporter runs on Ethereum. We highly recommend using{' '}
        <b>MetaMask wallet</b> with the <b>Chrome or Brave browser</b> on a
        <b> desktop or laptop computer</b>. Official support for other
        configurations is coming soon!
      </KitParagraph>
    </KitBox>
  );
  const renderOperationalSection = () => (
    <KitBox basis={responsiveSize === 'small' ? '3/3' : '2/3'} fill={paraFill}>
      <KitHeading level="3" color="secondary">
        Operational Notes
      </KitHeading>
      <KitParagraph
        style={paraFill ? null : { maxWidth: 'unset', marginRight: '2rem' }}
        fill={paraFill}
      >
        When teleporting Avastars, you only pay gas on the deposit of the funds
        required to teleport your queued applicants. You may wish to send that
        deposit with <b>the fastest gas fee setting</b>.
      </KitParagraph>
    </KitBox>
  );
  const renderSupportSection = () => (
    <KitBox basis={responsiveSize === 'small' ? '3/3' : '2/3'} fill={paraFill}>
      <KitCardBorder inset angled>
        <KitCardHeader size="small" angled>
          <KitCardTitle>Support</KitCardTitle>
        </KitCardHeader>
        <KitCardDivider />
        <KitCardBody background="background-front">
          <KitParagraph fill={true}>
            For support from Avastars Corp (aka&nbsp;
            <a
              href="https://www.nft42.com/"
              target="_blank"
              rel="noopener  noreferrer"
            >
              nft42
            </a>
            ) and to engage with the Avastars community, visit our{' '}
            <a
              href="https://discord.gg/avastars"
              rel="noopener noreferrer"
              target="_blank"
            >
              Discord server
            </a>{' '}
            or follow us on{' '}
            <a
              href="https://twitter.com/AvastarsNFT"
              rel="noopener noreferrer"
              target="_blank"
            >
              Twitter
            </a>
            .
          </KitParagraph>
        </KitCardBody>
      </KitCardBorder>
    </KitBox>
  );

  const renderSecuritySection = () => (
    <KitBox basis={responsiveSize === 'small' ? '3/3' : '2/3'}>
      <KitCardBorder inset angled>
        <KitCardHeader size="small" angled>
          <KitCardTitle>Security</KitCardTitle>
        </KitCardHeader>
        <KitCardDivider />
        <KitCardBody background="background-front">
          <KitBox
            basis={
              responsiveSize === 'small'
                ? '3/3'
                : responsiveSize === 'tablet'
                ? '2/2'
                : '1/3'
            }
          >
            <KitParagraph fill={true}>
              Ethereum smart contracts can be sneaky or downright insecure.
              Since ours accept your hard-earned ETH, we want you to be informed
              and confident when you interact with them.
            </KitParagraph>
            <ResponsiveRow>
              <KitBox basis="full">
                <KitHeading level="4" color="secondary">
                  Deployment Verified
                </KitHeading>
                <KitParagraph fill={paraFill}>
                  The source code for our live contracts has been verified and
                  is visible on Etherscan.
                </KitParagraph>
                <ul>
                  <li>
                    <a
                      href="https://etherscan.io/address/0x0ea3a9ffde8164bf680510e163f78150dc0274bd#code"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Avastar Metadata
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://etherscan.io/address/0xf3e778f839934fc819cfa1040aabacecba01e049#code"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Avastar Teleporter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://etherscan.io/address/0xe31763aad9294f073ddf18b36503ed037ae5e737#code"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Avastar Prime Minter
                    </a>
                  </li>
                </ul>
              </KitBox>
              <KitBox
                basis="full"
                style={paraFill ? null : { marginLeft: '2rem' }}
              >
                <KitHeading level="4" color="secondary">
                  Security Audited
                </KitHeading>
                <KitParagraph fill={paraFill}>
                  Our contracts have undergone a rigorous security audit. Read
                  the report:
                </KitParagraph>
                <ul>
                  <li>
                    <a
                      href="https://avastarsaudit.now.sh/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Avastars Audit Report
                    </a>
                  </li>
                </ul>
              </KitBox>
              <KitBox
                basis="full"
                style={paraFill ? null : { marginLeft: '2rem' }}
              >
                <KitHeading level="4" color="secondary">
                  Open Sourced
                </KitHeading>
                <KitParagraph fill={paraFill}>
                  Finally, our entire set of Ethereum contracts is open source
                  and includes unit tests and documentation.
                </KitParagraph>
                <ul>
                  <li>
                    <a
                      href="https://github.com/nft42/Avastars-Contracts"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Avastars Github Repository
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://nft42.github.io/Avastars-Contracts"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Avastars Contract Documentation
                    </a>
                  </li>
                </ul>
              </KitBox>
            </ResponsiveRow>
          </KitBox>
        </KitCardBody>
      </KitCardBorder>
    </KitBox>
  );

  const renderHeroCard1 = () => (
    <KitBox
      basis={
        responsiveSize === 'small'
          ? '3/3'
          : responsiveSize === 'tablet'
          ? '1/2'
          : '1/2'
      }
    >
      <KitCardBorder inset angled>
        <KitCardHeader size="small" angled>
          <KitCardTitle>Founder #0</KitCardTitle>
        </KitCardHeader>
        <KitCard>
          <KitCardDivider direction="row" style={styles.cardDivider} />
          <KitCardImageWrap style={styles.heroImage}>
            <img src={founder0} alt="" />
          </KitCardImageWrap>
          <KitCardDivider direction="row" style={styles.cardDivider} />
          <KitCardBody background="background-front">
            <KitTraitRow>
              <KitColumn>
                <RarityIcon rarity="Legendary" active size="medium" />
              </KitColumn>
              <KitColumn justify="end" style={styles.heroTrait}>
                Legendary
              </KitColumn>
            </KitTraitRow>
            <KitTraitRow>
              <KitColumn>
                <RarityIcon rarity="Male" size="medium" />
              </KitColumn>
              <KitColumn justify="end" style={styles.heroTrait}>
                Female
              </KitColumn>
            </KitTraitRow>
            <KitTraitRow>
              <KitColumn>
                <span style={styles.generationNumber}>
                  <b>1</b>
                </span>
              </KitColumn>
              <KitColumn justify="end" style={styles.heroTrait}>
                Generation
              </KitColumn>
            </KitTraitRow>
          </KitCardBody>
        </KitCard>
      </KitCardBorder>
    </KitBox>
  );
  const renderHeroCard2 = () => (
    <KitBox
      basis={
        responsiveSize === 'small'
          ? '3/3'
          : responsiveSize === 'tablet'
          ? '1/2'
          : '1/2'
      }
    >
      <KitCardBorder inset angled displayMode="ui">
        <KitCardHeader size="small" angled>
          <KitCardTitle>Founder #1</KitCardTitle>
        </KitCardHeader>
        <KitCard>
          <KitCardDivider direction="row" style={styles.cardDivider} />
          <KitCardImageWrap style={styles.heroImage}>
            <img src={founder1} alt="" />
          </KitCardImageWrap>
          <KitCardDivider direction="row" style={styles.cardDivider} />
          <KitCardBody background="background-front">
            <KitTraitRow>
              <KitColumn>
                <RarityIcon rarity="Legendary" active size="medium" />
              </KitColumn>
              <KitColumn justify="end" style={styles.heroTrait}>
                Legendary
              </KitColumn>
            </KitTraitRow>
            <KitTraitRow>
              <KitColumn>
                <RarityIcon rarity="Female" size="medium" />
              </KitColumn>
              <KitColumn justify="end" style={styles.heroTrait}>
                Male
              </KitColumn>
            </KitTraitRow>
            <KitTraitRow>
              <KitColumn>
                <span style={styles.generationNumber}>
                  <b>1</b>
                </span>
              </KitColumn>
              <KitColumn justify="end" style={styles.heroTrait}>
                Generation
              </KitColumn>
            </KitTraitRow>
          </KitCardBody>
        </KitCard>
      </KitCardBorder>
    </KitBox>
  );

  const renderHeroCards = () => (
    <KitBox
      basis={
        responsiveSize === 'small'
          ? '3/3'
          : responsiveSize === 'tablet'
          ? '1/2'
          : '2/3'
      }
      direction="row"
      gap="medium"
    >
      {renderHeroCard1()}
      {renderHeroCard2()}
    </KitBox>
  );
  const renderRarityIcons = () => (
    <KitBox
      basis={'2/2'}
      gap="small"
      overflow="scroll"
      direction="row"
      justify="stretch"
      fill="horizontal"
    >
      <KitCard basis="20%">
        <KitCardBorder angled inset>
          <KitCardBody background="background-front">
            <RarityIcon
              rarity="Legendary"
              active
              size={
                responsiveSize === 'tablet'
                  ? 'large'
                  : responsiveSize === 'small'
                  ? 'large'
                  : 'hero'
              }
            />
          </KitCardBody>
          <KitCardFooter level="2" angled>
            Legendary
          </KitCardFooter>
        </KitCardBorder>
      </KitCard>
      <KitCard basis="20%">
        <KitCardBorder angled inset>
          <KitCardBody background="background-front">
            <RarityIcon
              rarity="Epic"
              active
              size={
                responsiveSize === 'tablet'
                  ? 'large'
                  : responsiveSize === 'small'
                  ? 'large'
                  : 'hero'
              }
            />
          </KitCardBody>
          <KitCardFooter level="2" angled>
            Epic
          </KitCardFooter>
        </KitCardBorder>
      </KitCard>
      <KitCard basis="20%">
        <KitCardBorder angled inset>
          <KitCardBody background="background-front">
            <RarityIcon
              rarity="Rare"
              active
              size={
                responsiveSize === 'tablet'
                  ? 'large'
                  : responsiveSize === 'small'
                  ? 'large'
                  : 'hero'
              }
            />
          </KitCardBody>
          <KitCardFooter angled>Rare</KitCardFooter>
        </KitCardBorder>
      </KitCard>
      <KitCard basis="20%">
        <KitCardBorder angled inset>
          <KitCardBody background="background-front">
            <RarityIcon
              rarity="Uncommon"
              active
              size={
                responsiveSize === 'tablet'
                  ? 'large'
                  : responsiveSize === 'small'
                  ? 'large'
                  : 'hero'
              }
            />
          </KitCardBody>
          <KitCardFooter angled>Uncommon</KitCardFooter>
        </KitCardBorder>
      </KitCard>
      <KitCard basis="20%">
        <KitCardBorder angled inset>
          <KitCardBody background="background-front">
            <RarityIcon
              rarity="Common"
              active
              size={
                responsiveSize === 'tablet'
                  ? 'large'
                  : responsiveSize === 'small'
                  ? 'large'
                  : 'hero'
              }
            />
          </KitCardBody>
          <KitCardFooter angled>Common</KitCardFooter>
        </KitCardBorder>
      </KitCard>
    </KitBox>
  );

  return (
    <Animated
      animationIn="fadeIn"
      animationInDuration={325}
      animationInDelay={0}
    >
      <div style={styles.page}>
        {props.maintenanceMode && <MaintenanceBanner />}
        <KitHeading level="1" color="secondary">
          Greetings, Teleporter Engineer!
        </KitHeading>
        <br />
        <KitHeading level="2" color="secondary">
          Welcome to Avastars
        </KitHeading>
        <KitParagraph fill={true}>
          Avastars is the ultimate digital collectibles project for an
          increasingly booming metaverse. Generative, addictive, and open to
          being used in unprecedented ways, Avastars tokens are trailblazers in
          a new golden era of digital collecting.
        </KitParagraph>
        <ResponsiveRow>
          {renderRequirementsSection()}
          {renderOperationalSection()}
          {responsiveSize === 'small' ? (
            <>
              <br />
              <KitCardDivider />
              <br />
            </>
          ) : null}
          {renderSupportSection()}
          {responsiveSize === 'small' ? <br /> : null}
        </ResponsiveRow>
        <KitCardDivider />

        <ResponsiveRow gap="xsmall">
          {renderBackgroundSection1()}
          {renderHeroCards()}
        </ResponsiveRow>
        <KitCardDivider />
        <br />

        <KitHeading level="3" color="secondary">
          Genetic Rarity
        </KitHeading>
        <ResponsiveRow>
          {renderBackgroundSection2()}
          {renderBackgroundSection3()}
        </ResponsiveRow>
        <KitRow margin={{ top: 'none', bottom: 'small' }}>
          {renderRarityIcons()}
        </KitRow>
        <KitCardDivider />
        <br />

        <KitHeading level="3" color="secondary">
          Replicants
        </KitHeading>
        <ResponsiveRow>
          {renderBackgroundSection4()}
          {renderBackgroundSection5()}
        </ResponsiveRow>
        <KitCardDivider />
        <br />

        {renderSecuritySection()}
        <br />
        <KitCardDivider />

        <ResponsiveRow align="center" style={{ justifyContent: 'center' }}>
          <div>
            Made&nbsp;with&nbsp;
            <span role="img" aria-label="Love">
              ❤️
            </span>
            <br />
            by&nbsp;
            <a
              href="https://www.nft42.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              nft42
            </a>
          </div>
        </ResponsiveRow>
        <br />
      </div>
    </Animated>
  );
}
