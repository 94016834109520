import React, { useEffect, useState } from 'react';
import { Animated } from 'react-animated-css';
import { marketplace as styles } from '../../../theme/styles';
import { getBlockchainConfig, getWebConfig } from '../../../environment/config';
import {
  PURCHASE_STATUS,
  PROFILE_DESTINATIONS,
} from '../../../constants/marketplace';
import { WALLET_STATUS } from '../../../constants/wallet';
import { MEDIA } from '../../../constants/media';
import Wallet from '../Wallet';
import {
  KitColumn,
  KitHeader,
  KitHeaderField,
  KitLargeButton,
  KitSmallButton,
  KitList,
  KitLoading,
  KitRow,
  KitNumericInput,
  KitMenu,
  KitHeading,
  KitBox,
  KitParagraph,
  KitCardDivider,
  KitSeparator,
} from '../../../theme/kit';
import MaintenanceBanner from '../../Maintenance';

const blockchainConfig = getBlockchainConfig();
const webConfig = getWebConfig();

export default function Profile(props) {
  const {
    // user,
    web3,
    firebase,
    setWeb3,
    account,
    setAccount,
    network,
    setNetwork,
    desiredNetwork,
    walletStatus,
    setWalletStatus,
    purchaseStatus,
    setPurchaseStatus,
    profile,
    walletBalance,
    getWalletBalance,
    truncatedAccount,
    smallDisplay,
    mediaSize,
    getProfile,
    setProfile,
    setGasPrice,
    maintenanceMode,
  } = props;

  const depositQuantum = 0.07;
  const defaultMultiplier = 1;
  const maxAllowedDeposit = 100;
  const [depositMultiplier, setDepositMultiplier] = useState(defaultMultiplier);
  const [maxDeposit, setMaxDeposit] = useState(maxAllowedDeposit);
  const [depositAmount, setDepositAmount] = useState('0.00');

  // Compute the deposit
  const computeDeposit = () => {
    let amount = Number.parseFloat(
      String(depositMultiplier * depositQuantum)
    ).toFixed(2);
    setDepositAmount(amount);
  };

  // Set purchase status to nascent if it was preparing deposit when network changes
  useEffect(() => {
    if (purchaseStatus === PURCHASE_STATUS.preparing.deposit)
      setPurchaseStatus(PURCHASE_STATUS.nascent);
    // eslint-disable-next-line
  }, [network]);

  // Fetch profile when account changes and check balance
  useEffect(() => {
    if (!!profile) {
      async function getDepositBalance() {
        if (walletBalance < depositQuantum) {
          setMaxDeposit(0);
        } else if (walletBalance < maxAllowedDeposit) {
          const newMax = Math.floor(walletBalance / depositQuantum);
          setMaxDeposit(newMax);
        }
      }
      getDepositBalance();
    }
  }, [profile, walletBalance]);

  // Set the deposit multiplier
  useEffect(() => {
    setDepositMultiplier(maxDeposit >= depositQuantum ? 1 : 0);
  }, [maxDeposit]);

  // Compute deposit when maxDeposit or depositMultiplier are changed
  useEffect(() => {
    computeDeposit();
    // eslint-disable-next-line
  }, [maxDeposit, depositMultiplier]);

  const desiredNet = `${desiredNetwork.network[0].toUpperCase()}${desiredNetwork.network.substring(
    1
  )}`;

  const openTab = (destination) => {
    let url;
    let network =
      desiredNetwork.network === 'goerli' ? `${desiredNetwork.network}.` : '';
    switch (destination) {
      case PROFILE_DESTINATIONS.PROFILE:
        url = `${webConfig.host}/profile/${account}`;
        break;

      case PROFILE_DESTINATIONS.OPENSEA:
        url = `https://${network}opensea.io/accounts/${account}?category=avastar`;
        break;

      case PROFILE_DESTINATIONS.ETHERSCAN:
        url = `https://${network}etherscan.io/token/${blockchainConfig.teleporter.address}?a=${account}`;
        break;

      case PROFILE_DESTINATIONS.WALLET:
        url = `https://${network}etherscan.io/address/${account}`;
        break;

      default:
        url = destination;
        break;
    }
    window.open(url, '_blank');
  };

  const renderWallet = () => {
    return (
      <span>
        <span style={styles.checkoutNumeric}>{truncatedAccount}</span>
      </span>
    );
  };

  const renderNetwork = () => {
    return (
      <span>
        {network !== '' ? (
          <span>
            {network} {smallDisplay ? <br /> : <span>/</span>}{' '}
          </span>
        ) : null}
        {renderWallet()}
      </span>
    );
  };

  const renderDepositMultiplierInput = () => {
    return maxDeposit > 0 ? (
      mediaSize === MEDIA.MOBILE.SIZE ? (
        <div>
          <KitNumericInput
            mobile={true}
            style={styles.depositInput}
            min={1}
            max={maxDeposit}
            value={depositMultiplier}
            onChange={(amount) => {
              setDepositMultiplier(amount);
              computeDeposit();
            }}
          />
          <br />
          <span>x&nbsp;</span>
          <span style={styles.checkoutNumeric}>
            0.07&nbsp;=&nbsp;
            <b>{depositAmount}&nbsp;Ξ&nbsp;</b>
          </span>
        </div>
      ) : (
        <span>
          Total:&nbsp;
          <KitNumericInput
            style={styles.depositInput}
            min={1}
            max={maxDeposit}
            value={depositMultiplier}
            onChange={(amount) => {
              setDepositMultiplier(amount);
              computeDeposit();
            }}
          />
          <span>&nbsp;x&nbsp;</span>
          <span style={styles.checkoutNumeric}>
            0.07&nbsp;=&nbsp;
            <b>{depositAmount}&nbsp;Ξ&nbsp;</b>
          </span>
        </span>
      )
    ) : (
      `Insufficient funds in wallet.`
    );
  };

  const renderHeaderLeft = () =>
    smallDisplay ? null : purchaseStatus ===
        PURCHASE_STATUS.preparing.deposit ||
      purchaseStatus === PURCHASE_STATUS.depositing ? (
      <KitHeaderField>Deposit Funds</KitHeaderField>
    ) : purchaseStatus === PURCHASE_STATUS.preparing.refund ||
      purchaseStatus === PURCHASE_STATUS.refunding ? (
      <KitHeaderField>Withdraw Balance</KitHeaderField>
    ) : (
      <KitHeaderField>View Profile</KitHeaderField>
    );

  const renderHeaderCenter = () =>
    purchaseStatus === PURCHASE_STATUS.preparing.deposit ? (
      renderDepositMultiplierInput()
    ) : purchaseStatus === PURCHASE_STATUS.depositing ? (
      <span>
        Total:{' '}
        <span style={styles.checkoutNumeric}>
          &nbsp;<b>{depositAmount}&nbsp;Ξ</b>
        </span>
      </span>
    ) : purchaseStatus === PURCHASE_STATUS.preparing.refund ||
      purchaseStatus === PURCHASE_STATUS.refunding ? (
      <span>
        Total:{' '}
        <span style={styles.checkoutNumeric}>
          &nbsp;<b>{profile.depositBalance}&nbsp;Ξ</b>
        </span>
      </span>
    ) : !account ? (
      walletStatus
    ) : (
      renderNetwork()
    );

  const renderHeaderRight = () =>
    purchaseStatus === PURCHASE_STATUS.preparing.deposit ? (
      <span>
        {smallDisplay ? (
          <>
            <KitSmallButton onClick={deposit}>Deposit</KitSmallButton>
            <KitSeparator />
            <KitSmallButton
              onClick={() => {
                setPurchaseStatus(PURCHASE_STATUS.nascent);
              }}
            >
              X
            </KitSmallButton>
          </>
        ) : (
          <>
            <KitLargeButton onClick={deposit}>Deposit</KitLargeButton>
            <KitSeparator />
            <KitLargeButton
              onClick={() => {
                setPurchaseStatus(PURCHASE_STATUS.nascent);
              }}
            >
              Cancel
            </KitLargeButton>
          </>
        )}
      </span>
    ) : purchaseStatus === PURCHASE_STATUS.preparing.refund ? (
      <span>
        {smallDisplay ? (
          <>
            <KitSmallButton onClick={refund}>Withdraw</KitSmallButton>
            <KitSeparator />
            <KitSmallButton
              onClick={() => {
                setPurchaseStatus(PURCHASE_STATUS.nascent);
              }}
            >
              X
            </KitSmallButton>
          </>
        ) : (
          <>
            <KitLargeButton onClick={refund}>Withdraw</KitLargeButton>
            <KitSeparator />
            <KitLargeButton
              onClick={() => {
                setPurchaseStatus(PURCHASE_STATUS.nascent);
              }}
            >
              Cancel
            </KitLargeButton>
          </>
        )}
      </span>
    ) : purchaseStatus === PURCHASE_STATUS.refunding ? (
      'Refunding...'
    ) : purchaseStatus === PURCHASE_STATUS.depositing ? (
      'Depositing...'
    ) : !!account ? (
      !!profile ? (
        walletStatus === WALLET_STATUS.wrong ? (
          <KitHeaderField>
            <span>Please connect to {desiredNet}&nbsp;&nbsp;</span>
          </KitHeaderField>
        ) : (
          <KitLargeButton onClick={refreshProfile}> Refresh </KitLargeButton>
        )
      ) : (
        <KitLoading text={''} pad="none" margin="none" size={50} key={0} />
      )
    ) : (
      <Wallet
        firebase={firebase}
        setWeb3={setWeb3}
        setAccount={setAccount}
        setNetwork={setNetwork}
        desiredNetwork={desiredNetwork}
        setWalletStatus={setWalletStatus}
        setGasPrice={setGasPrice}
      />
    );

  const renderCollectionRow = () =>
    profile.total > 0 ? (
      <KitRow displayMode="ui" hasBackground>
        &nbsp;
        <KitColumn>Your Avastar Collection</KitColumn>
        <KitColumn justify="center">
          <span style={styles.checkoutNumeric}>{profile.total}</span>
        </KitColumn>
        <KitColumn justify="end" pad={{ right: 'small' }}>
          <KitSmallButton onClick={() => openTab(PROFILE_DESTINATIONS.PROFILE)}>
            View
          </KitSmallButton>
        </KitColumn>
      </KitRow>
    ) : null;

  const renderEtherscanRow = () =>
    profile.total > 0 ? (
      <KitRow displayMode="ui" hasBackground>
        &nbsp;
        <KitColumn>Your Avastars on {PROFILE_DESTINATIONS.ETHERSCAN}</KitColumn>
        <KitColumn justify="center">
          <span>&nbsp;</span>
        </KitColumn>
        <KitColumn justify="end" pad={{ right: 'small' }}>
          <KitSmallButton
            onClick={() => openTab(PROFILE_DESTINATIONS.ETHERSCAN)}
          >
            View
          </KitSmallButton>
        </KitColumn>
      </KitRow>
    ) : null;

  const renderOpenSeaRow = () =>
    profile.total > 0 ? (
      <KitRow displayMode="ui" hasBackground>
        &nbsp;
        <KitColumn>Your Avastars on {PROFILE_DESTINATIONS.OPENSEA}</KitColumn>
        <KitColumn justify="center">
          <span>&nbsp;</span>
        </KitColumn>
        <KitColumn justify="end" pad={{ right: 'small' }}>
          <KitSmallButton onClick={() => openTab(PROFILE_DESTINATIONS.OPENSEA)}>
            View
          </KitSmallButton>
        </KitColumn>
      </KitRow>
    ) : null;

  const renderWalletBalanceRow = () => {
    return (
      <KitRow displayMode="ui" hasBackground>
        &nbsp;
        <KitColumn>Your Wallet Balance</KitColumn>
        <KitColumn justify="center">
          <span style={styles.checkoutNumeric}>
            &nbsp;{walletBalance}&nbsp;Ξ
          </span>
        </KitColumn>
        <KitColumn justify="end" pad={{ right: 'small' }}>
          <KitSmallButton onClick={() => openTab(PROFILE_DESTINATIONS.WALLET)}>
            View
          </KitSmallButton>
        </KitColumn>
      </KitRow>
    );
  };

  const renderDepositBalanceRow = () => {
    const balanceMenuItems = [
      {
        label: 'Deposit',
        onClick: () => setPurchaseStatus(PURCHASE_STATUS.preparing.deposit),
      },
      {
        label: 'Withdraw',
        onClick: () => setPurchaseStatus(PURCHASE_STATUS.preparing.refund),
      },
    ];

    return (
      <KitRow displayMode="ui" hasBackground>
        &nbsp;
        <KitColumn>Your Funds on Deposit</KitColumn>
        <KitColumn justify="center">
          <span style={styles.checkoutNumeric}>
            &nbsp;{profile.depositBalance}&nbsp;Ξ
          </span>
        </KitColumn>
        <KitColumn justify="end" pad={{ right: 'small' }}>
          {profile.depositBalance > 0 ? (
            <span>
              {smallDisplay || mediaSize === MEDIA.TABLET.SIZE ? (
                <KitMenu label="Actions" items={balanceMenuItems} />
              ) : (
                <>
                  <KitSmallButton
                    onClick={() =>
                      setPurchaseStatus(PURCHASE_STATUS.preparing.refund)
                    }
                  >
                    Withdraw
                  </KitSmallButton>
                  <KitSeparator />
                  <KitSmallButton
                    onClick={() => {
                      setPurchaseStatus(PURCHASE_STATUS.preparing.deposit);
                    }}
                  >
                    Deposit
                  </KitSmallButton>
                </>
              )}
            </span>
          ) : (
            <KitSmallButton
              onClick={() => {
                setPurchaseStatus(PURCHASE_STATUS.preparing.deposit);
              }}
            >
              Deposit
            </KitSmallButton>
          )}
        </KitColumn>
      </KitRow>
    );
  };

  const renderContent = () => {
    return !!profile ? (
      <KitList>
        {purchaseStatus === PURCHASE_STATUS.nascent &&
        walletStatus === WALLET_STATUS.connected
          ? renderDepositBalanceRow()
          : null}
        {renderWalletBalanceRow()}
        {renderCollectionRow()}
        {renderOpenSeaRow()}
        {renderEtherscanRow()}
      </KitList>
    ) : null;
  };

  // Refund any unspent deposits
  async function refund() {
    // Withdraw depositor balance
    setPurchaseStatus(PURCHASE_STATUS.refunding);
    const contract = new web3.eth.Contract(
      blockchainConfig.primeMinter.abi,
      blockchainConfig.primeMinter.address
    );

    try {
      await contract.methods.withdrawDepositorBalance().send({ from: account });
    } catch (e) {
    } finally {
      setPurchaseStatus(PURCHASE_STATUS.nascent);
      refreshProfile();
    }
  }

  // Deposit funds to Minter contract
  async function deposit() {
    setPurchaseStatus(PURCHASE_STATUS.depositing);
    const contract = new web3.eth.Contract(
      blockchainConfig.primeMinter.abi,
      blockchainConfig.primeMinter.address
    );
    const amount = web3.utils.toWei(depositAmount);
    try {
      await contract.methods.deposit().send({ from: account, value: amount });
    } catch (e) {
    } finally {
      setPurchaseStatus(PURCHASE_STATUS.nascent);
      refreshProfile();
    }
  }

  // Render the the header copy
  function renderCopy() {
    return (
      <>
        <KitHeading level="1" color="secondary">
          Teleporter Engineer Profile
        </KitHeading>
        <KitBox>
          <KitParagraph fill={true}>
            Connect your wallet to keep track of your teleported Avastars here
            and on other popular sites.
          </KitParagraph>
          <KitParagraph fill={true}>
            You only have to pay gas when you deposit funds to operate the
            teleporter. But there's no need to do that every time you teleport a
            queue full of Applicants. To save on gas, you can make a single
            deposit here and then teleport Applicants until the funds on deposit
            run out.
          </KitParagraph>
          <KitParagraph fill={true}>
            At any time, you can withdraw the balance of your funds on deposit.
          </KitParagraph>
        </KitBox>
        <KitCardDivider />
      </>
    );
  }

  function refreshProfile() {
    getWalletBalance();
    setProfile(null);
    getProfile();
  }

  return (
    <Animated
      animationIn="fadeIn"
      animationInDuration={325}
      animationInDelay={0}
    >
      <div style={styles.page}>
        {maintenanceMode && <MaintenanceBanner />}
        {renderCopy()}
        <KitHeader>
          {renderHeaderLeft()}
          {renderHeaderCenter()}
          {renderHeaderRight()}
        </KitHeader>
        {renderContent()}
      </div>
    </Animated>
  );
}
